import { Component, Input, ViewChild } from '@angular/core';
import {
  DocumentTreeBranchDTO,
  DocumentTreeBranchResourceService
} from 'src/app/dmssdk';
import { map } from 'rxjs/operators';
import {
  ITreeOptions,
  TreeComponent,
  TreeNode,
  TREE_ACTIONS,
} from '@circlon/angular-tree-component';
import {
  TreeBranchWrapper,
  TreeEditorServiceService,
} from './tree-editor-service.service';
import { of } from 'rxjs';
/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */

@Component({
  selector: 'evo-tree-editor',
  templateUrl: './tree-editor.component.html',
  styleUrls: ['./tree-editor.component.scss'],
})
export class TreeEditorComponent {
  constructor(
    private treeBranchService: DocumentTreeBranchResourceService,
    private treeEditorService: TreeEditorServiceService
  ) { }

  @ViewChild(TreeComponent)
  private tree: TreeComponent;

  actionMappings = {
    mouse: {
      click: this.showDetails.bind(this),
    },
  };

  options: ITreeOptions = {
    getChildren: this.findChildren.bind(this),
    actionMapping: this.actionMappings,
    allowDrag: (node: TreeNode) => node.level != 1,
    allowDrop: (element, { parent, index }) => !parent.data.virtual,
  };

  treeData: TreeBranchWrapper[] = [];
  selectedTreeBranch: DocumentTreeBranchDTO;
  addedNode: TreeBranchWrapper;

  @Input()
  public set root(value: DocumentTreeBranchDTO) {
    if (value) {
      this.treeData = [this.createNewWrapper(value)];
      this.selectedTreeBranch = value;
    }
  }

  addSubnode(treeNode: TreeNode, event: any) {
    event.stopPropagation();
    var treeBranchWrapper: TreeBranchWrapper = treeNode.data;
    var toAdd: DocumentTreeBranchDTO = {
      parentId: treeBranchWrapper.treeBranch.id,
      name: 'Child of ' + treeBranchWrapper.name,
      mode: 'ATTRIBUTE',
      attributeValuesOrder: 'ASC'
    };
    this.treeBranchService
      .createDocumentTreeBranchUsingPOST(toAdd)
      .subscribe((result) => {
        this.addedNode = this.createNewWrapper(result);
        if (treeBranchWrapper.children) {
          treeBranchWrapper.children.push(this.addedNode);
        };
        TREE_ACTIONS.EXPAND(this.tree.treeModel, treeNode, event);
        this.tree.treeModel.update();

      });
  }

  removeNode(treeNode: TreeNode, event: any) {
    event.stopPropagation();
    var treeBranchWrapper: TreeBranchWrapper = treeNode.data;
    var parentTreeBranchWrapper: TreeBranchWrapper = treeNode.parent.data;
    if (treeNode.isActive) {
      this.selectNode(treeNode.parent, event);
    }
    this.treeBranchService
      .deleteDocumentTreeBranchUsingDELETE(treeBranchWrapper.treeBranch.id)
      .subscribe(() => {
        var position =
          parentTreeBranchWrapper.children.indexOf(treeBranchWrapper);
        parentTreeBranchWrapper.children.splice(position, 1);
        this.tree.treeModel.update();
      });
  }

  findChildren(node: TreeBranchWrapper): Promise<TreeBranchWrapper[]> {
    if (node.children) {
      return of(node.children).toPromise();
    }
    return this.treeBranchService
      .getChildrenOfDocumentTreeBranchUsingGET(node.id)
      .pipe(
        map((data) => {
          return data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        })
      )
      .pipe(map((elem) => elem.map((item) => this.createNewWrapper(item))))
      .toPromise();
  }

  showDetails(tree, node: TreeNode, $event) {
    node.setIsActive(true);
    this.selectedTreeBranch = node.data.treeBranch;
  }

  onMoveNode($event) {
    this.treeEditorService.onMoveNode($event);
  }

  onTreeUpdated(event) {
    this.selectAddedNode(event);
  }

  private selectAddedNode(event: any) {
    if (this.addedNode) {
      var newNode = this.tree.treeModel.getNodeById(this.addedNode.id);
      if (!newNode) {
        return;
      }
      this.addedNode = undefined;
      this.selectNode(newNode, event);
    }
  }

  private selectNode(node: any, event: any) {
    TREE_ACTIONS.TOGGLE_ACTIVE(this.tree.treeModel, node, event);
    this.showDetails(undefined, node, undefined);
  }

  onChildrenLoaded(event) {
    this.selectAddedNode(event);
  }

  onTreeBranchUpdated() {
    //at the moment the event has no application
  }

  private createNewWrapper(toWrap: DocumentTreeBranchDTO): TreeBranchWrapper {
    return {
      id: toWrap.id,
      name: toWrap.name,
      hasChildren: true,
      treeBranch: toWrap,
      parentId: toWrap.parentId
    };
  }
}
