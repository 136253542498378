<form [formGroup]="form" *ngIf="form">
  <div fxLayout="column" fxLayoutGap="12px" class="adv-panel">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="2em">
      <div fxFlex="grow">
        <div fxLayout="column" *ngIf="!showCode">
          <mat-label class="label" translate
            >filtering-panel.query-editor</mat-label
          >
          <evo-boolean-condition-element
            [allowEmpty]="true"
            [allowRemove]="false"
            formControlName="condition"
            [conditionAccess]="conditionAccess"
            [showHelpButton]="true"
            fxFlex="grow"
          ></evo-boolean-condition-element>
        </div>
        <div fxLayout="column" *ngIf="showCode">
          <mat-label class="label" translate
            >filtering-panel.query-code</mat-label
          >
          <div
            id="pseudocode"
            *ngIf="pseudocode != ''"
            [innerHtml]="pseudocode | async | conditionformat"
          ></div>
        </div>
      </div>

      <div fxFlex="auto">
        <div class="sort-box">
          <div
            fxLayout="column"
            fxLayoutAlign="space-around stretch"
            fxLayoutGap="2em"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
              <button
                color="accent"
                mat-icon-button
                class="search-button"
                (click)="searchClicked($event)"
                matTooltip="{{ 'filtering-panel.search-tooltip' | translate }}"
                [disabled]="form.invalid"
              >
                <mat-icon>search</mat-icon>
              </button>
              <mat-slide-toggle
                class="incl-hist"
                [checked]="querySearch.includeHistory"
                (change)="includeHistoryChange($event)"
                >{{
                  "filtering-panel.include-history" | translate
                }}</mat-slide-toggle
              >
            </div>
            <div fxLayout="row" fxLayoutAlign="start start" *ngIf="!showCode">
              <evo-sort
                [schemaAccess]="sortSchemaAccess"
                formControlName="sort"
                [sortAccess]="sortAccess"
              ></evo-sort>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
