/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BooleanExpressionDTO } from './booleanExpressionDTO';
import { ConditionDTO } from './conditionDTO';
import { ConditionFunctionDTO } from './conditionFunctionDTO';


export interface ConditionElementDTO { 
    condition?: ConditionDTO;
    functionDTO?: ConditionFunctionDTO;
    id?: number;
    root?: BooleanExpressionDTO;
}
