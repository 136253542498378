/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BatchJob } from '../model/batchJob';
import { BatchJobDescriptionDTO } from '../model/batchJobDescriptionDTO';
import { BatchJobDescriptionWithTriggerTypesDTO } from '../model/batchJobDescriptionWithTriggerTypesDTO';
import { BatchJobIdentifierDTO } from '../model/batchJobIdentifierDTO';
import { ComplexDataTypeDTO } from '../model/complexDataTypeDTO';
import { ComplexFieldWrapperDTO } from '../model/complexFieldWrapperDTO';
import { EnumValuesResponse } from '../model/enumValuesResponse';
import { TriggerConfigurationDTO } from '../model/triggerConfigurationDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BatchJobDescriptionResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createBatchJobDescription
     * 
     * @param batchJobDescriptionWithTypes batchJobDescriptionWithTypes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBatchJobDescriptionUsingPOST(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe?: 'body', reportProgress?: boolean): Observable<BatchJobDescriptionWithTriggerTypesDTO>;
    public createBatchJobDescriptionUsingPOST(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BatchJobDescriptionWithTriggerTypesDTO>>;
    public createBatchJobDescriptionUsingPOST(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BatchJobDescriptionWithTriggerTypesDTO>>;
    public createBatchJobDescriptionUsingPOST(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (batchJobDescriptionWithTypes === null || batchJobDescriptionWithTypes === undefined) {
            throw new Error('Required parameter batchJobDescriptionWithTypes was null or undefined when calling createBatchJobDescriptionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BatchJobDescriptionWithTriggerTypesDTO>(`${this.basePath}/api/batch-job-descriptions`,
            batchJobDescriptionWithTypes,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteBatchJobDescription
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBatchJobDescriptionUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBatchJobDescriptionUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBatchJobDescriptionUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBatchJobDescriptionUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBatchJobDescriptionUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * executeBatchJob
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public executeBatchJobUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public executeBatchJobUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public executeBatchJobUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public executeBatchJobUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling executeBatchJobUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(id))}/execute`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllBatchJobDescriptionsByType
     * 
     * @param type type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBatchJobDescriptionsByTypeUsingGET(type: 'NORMAL' | 'PROTOTYPE' | 'PROTOTYPE_INSTANCE', observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJobDescriptionDTO>>;
    public getAllBatchJobDescriptionsByTypeUsingGET(type: 'NORMAL' | 'PROTOTYPE' | 'PROTOTYPE_INSTANCE', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJobDescriptionDTO>>>;
    public getAllBatchJobDescriptionsByTypeUsingGET(type: 'NORMAL' | 'PROTOTYPE' | 'PROTOTYPE_INSTANCE', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJobDescriptionDTO>>>;
    public getAllBatchJobDescriptionsByTypeUsingGET(type: 'NORMAL' | 'PROTOTYPE' | 'PROTOTYPE_INSTANCE', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getAllBatchJobDescriptionsByTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJobDescriptionDTO>>(`${this.basePath}/api/batch-job-descriptions/types/${encodeURIComponent(String(type))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllBatchJobDescriptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBatchJobDescriptionsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJobDescriptionDTO>>;
    public getAllBatchJobDescriptionsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJobDescriptionDTO>>>;
    public getAllBatchJobDescriptionsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJobDescriptionDTO>>>;
    public getAllBatchJobDescriptionsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJobDescriptionDTO>>(`${this.basePath}/api/batch-job-descriptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllIdentifiedBatchJobs
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllIdentifiedBatchJobsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJobIdentifierDTO>>;
    public getAllIdentifiedBatchJobsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJobIdentifierDTO>>>;
    public getAllIdentifiedBatchJobsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJobIdentifierDTO>>>;
    public getAllIdentifiedBatchJobsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJobIdentifierDTO>>(`${this.basePath}/api/batch-job-descriptions/identified`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllImplementableBatchJobs
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllImplementableBatchJobsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJob>>;
    public getAllImplementableBatchJobsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJob>>>;
    public getAllImplementableBatchJobsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJob>>>;
    public getAllImplementableBatchJobsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJob>>(`${this.basePath}/api/batch-job-descriptions/implementable`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllNativeBatchJobs
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllNativeBatchJobsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJobIdentifierDTO>>;
    public getAllNativeBatchJobsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJobIdentifierDTO>>>;
    public getAllNativeBatchJobsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJobIdentifierDTO>>>;
    public getAllNativeBatchJobsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJobIdentifierDTO>>(`${this.basePath}/api/batch-job-descriptions/native`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobDescription
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobDescriptionUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BatchJobDescriptionWithTriggerTypesDTO>;
    public getBatchJobDescriptionUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BatchJobDescriptionWithTriggerTypesDTO>>;
    public getBatchJobDescriptionUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BatchJobDescriptionWithTriggerTypesDTO>>;
    public getBatchJobDescriptionUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBatchJobDescriptionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BatchJobDescriptionWithTriggerTypesDTO>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobParametersDescriptionByIdentifierAndClass
     * 
     * @param canonicalClass canonicalClass
     * @param identifier identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobParametersDescriptionByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeDTO>;
    public getBatchJobParametersDescriptionByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeDTO>>;
    public getBatchJobParametersDescriptionByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeDTO>>;
    public getBatchJobParametersDescriptionByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (canonicalClass === null || canonicalClass === undefined) {
            throw new Error('Required parameter canonicalClass was null or undefined when calling getBatchJobParametersDescriptionByIdentifierAndClassUsingGET.');
        }

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling getBatchJobParametersDescriptionByIdentifierAndClassUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ComplexDataTypeDTO>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(identifier))}/parameters-description/${encodeURIComponent(String(canonicalClass))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobParametersDescriptionByIdentifier
     * 
     * @param identifier identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobParametersDescriptionByIdentifierUsingGET(identifier: string, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeDTO>;
    public getBatchJobParametersDescriptionByIdentifierUsingGET(identifier: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeDTO>>;
    public getBatchJobParametersDescriptionByIdentifierUsingGET(identifier: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeDTO>>;
    public getBatchJobParametersDescriptionByIdentifierUsingGET(identifier: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling getBatchJobParametersDescriptionByIdentifierUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ComplexDataTypeDTO>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(identifier))}/parameters-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobParametersDescriptionEnumValuesByIdentifierAndEnumValuesSource
     * 
     * @param enumValuesSource enumValuesSource
     * @param identifier identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobParametersDescriptionEnumValuesByIdentifierAndEnumValuesSourceUsingGET(enumValuesSource: string, identifier: string, observe?: 'body', reportProgress?: boolean): Observable<EnumValuesResponse>;
    public getBatchJobParametersDescriptionEnumValuesByIdentifierAndEnumValuesSourceUsingGET(enumValuesSource: string, identifier: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnumValuesResponse>>;
    public getBatchJobParametersDescriptionEnumValuesByIdentifierAndEnumValuesSourceUsingGET(enumValuesSource: string, identifier: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnumValuesResponse>>;
    public getBatchJobParametersDescriptionEnumValuesByIdentifierAndEnumValuesSourceUsingGET(enumValuesSource: string, identifier: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enumValuesSource === null || enumValuesSource === undefined) {
            throw new Error('Required parameter enumValuesSource was null or undefined when calling getBatchJobParametersDescriptionEnumValuesByIdentifierAndEnumValuesSourceUsingGET.');
        }

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling getBatchJobParametersDescriptionEnumValuesByIdentifierAndEnumValuesSourceUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EnumValuesResponse>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(identifier))}/parameters-description-enum-values/${encodeURIComponent(String(enumValuesSource))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobParametersDescriptionFieldsByIdentifierAndClass
     * 
     * @param canonicalClass canonicalClass
     * @param identifier identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobParametersDescriptionFieldsByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplexFieldWrapperDTO>>;
    public getBatchJobParametersDescriptionFieldsByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplexFieldWrapperDTO>>>;
    public getBatchJobParametersDescriptionFieldsByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplexFieldWrapperDTO>>>;
    public getBatchJobParametersDescriptionFieldsByIdentifierAndClassUsingGET(canonicalClass: string, identifier: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (canonicalClass === null || canonicalClass === undefined) {
            throw new Error('Required parameter canonicalClass was null or undefined when calling getBatchJobParametersDescriptionFieldsByIdentifierAndClassUsingGET.');
        }

        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling getBatchJobParametersDescriptionFieldsByIdentifierAndClassUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ComplexFieldWrapperDTO>>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(identifier))}/parameters-description/${encodeURIComponent(String(canonicalClass))}/fields`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTriggerDescription
     * 
     * @param id id
     * @param triggerType triggerType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriggerDescriptionUsingGET(id: number, triggerType: 'SCHEDULE' | 'BEFORE_IMPORT' | 'AFTER_IMPORT' | 'AFTER_UPDATE', observe?: 'body', reportProgress?: boolean): Observable<TriggerConfigurationDTO>;
    public getTriggerDescriptionUsingGET(id: number, triggerType: 'SCHEDULE' | 'BEFORE_IMPORT' | 'AFTER_IMPORT' | 'AFTER_UPDATE', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TriggerConfigurationDTO>>;
    public getTriggerDescriptionUsingGET(id: number, triggerType: 'SCHEDULE' | 'BEFORE_IMPORT' | 'AFTER_IMPORT' | 'AFTER_UPDATE', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TriggerConfigurationDTO>>;
    public getTriggerDescriptionUsingGET(id: number, triggerType: 'SCHEDULE' | 'BEFORE_IMPORT' | 'AFTER_IMPORT' | 'AFTER_UPDATE', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTriggerDescriptionUsingGET.');
        }

        if (triggerType === null || triggerType === undefined) {
            throw new Error('Required parameter triggerType was null or undefined when calling getTriggerDescriptionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TriggerConfigurationDTO>(`${this.basePath}/api/batch-job-descriptions/${encodeURIComponent(String(id))}/trigger-descriptions/${encodeURIComponent(String(triggerType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBatchJobDescription
     * 
     * @param batchJobDescriptionWithTypes batchJobDescriptionWithTypes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBatchJobDescriptionUsingPUT(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe?: 'body', reportProgress?: boolean): Observable<BatchJobDescriptionWithTriggerTypesDTO>;
    public updateBatchJobDescriptionUsingPUT(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BatchJobDescriptionWithTriggerTypesDTO>>;
    public updateBatchJobDescriptionUsingPUT(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BatchJobDescriptionWithTriggerTypesDTO>>;
    public updateBatchJobDescriptionUsingPUT(batchJobDescriptionWithTypes: BatchJobDescriptionWithTriggerTypesDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (batchJobDescriptionWithTypes === null || batchJobDescriptionWithTypes === undefined) {
            throw new Error('Required parameter batchJobDescriptionWithTypes was null or undefined when calling updateBatchJobDescriptionUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BatchJobDescriptionWithTriggerTypesDTO>(`${this.basePath}/api/batch-job-descriptions`,
            batchJobDescriptionWithTypes,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
