<div fxLayout="row" style="min-height: 100%" fxFlex="grow" fxLayoutGap="15px">
  <div fxFlex="30%">
    <!--https://angular2-tree.readme.io/-->
    <tree-root
      [nodes]="treeData"
      [options]="options"
      (moveNode)="onMoveNode($event)"
      (updateData)="onTreeUpdated($event)"
      (loadNodeChildren)="onChildrenLoaded($event)"
    >
      <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
          <tree-node-expander
            [node]="node"
            [id]="'expander' + node.data.treeBranch.id"
          ></tree-node-expander>
          <div
            class="node-content-wrapper"
            [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-focused]="node.isFocused"
            (click)="node.mouseAction('click', $event)"
            (dblclick)="node.mouseAction('dblClick', $event)"
            (contextmenu)="node.mouseAction('contextMenu', $event)"
            (treeDrop)="node.onDrop($event)"
            [treeAllowDrop]="node.allowDrop"
            [treeDrag]="node"
            [treeDragEnabled]="node.allowDrag()"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              [id]="'id' + node.data.treeBranch.id"
            >
              <div>{{ node.data.treeBranch.name }}</div>
              <button mat-icon-button>
                <mat-icon
                  class="mat-icon-rtl-mirror add-icon"
                  (click)="addSubnode(node, $event)"
                  >add</mat-icon
                >
              </button>
              <button mat-icon-button *ngIf="node.data.treeBranch.parentId">
                <mat-icon
                  class="mat-icon-rtl-mirror remove-icon"
                  (click)="removeNode(node, $event)"
                  >remove</mat-icon
                >
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </tree-root>
  </div>
  <mat-divider [vertical]="true"></mat-divider>
  <evo-branch-editor
    fxFlex="grow"
    *ngIf="selectedTreeBranch"
    [documentTreeBranch]="selectedTreeBranch"
    (treeBranchUpdated)="onTreeBranchUpdated()"
  >
  </evo-branch-editor>
</div>
