import { Injectable } from '@angular/core';
import {
  DataTypeDesc
} from '@misc/angular-components';
import {
  SchemaAccess,
  TypeInModule,
  ComplexFieldWrapper,
  EnumValuesResponse,
} from '@misc/angular-components/lib/shared/model/data';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ComplexDataTypeDTO,
  ComplexDataTypeFieldResourceService,
  ComplexDataTypeResourceService,
} from 'src/app/dmssdk';

@Injectable({
  providedIn: 'root',
})
export class SchemaAccessService implements SchemaAccess<number, number[]> {
  public types: TypeInModule[] = undefined;
  public fieldsOfType: Map<number, ComplexFieldWrapper[]> = new Map();
  public fieldEnumValues: Map<string, EnumValuesResponse> = new Map();

  constructor(
    public complexTypeService: ComplexDataTypeResourceService,
    public complexTypeFieldService: ComplexDataTypeFieldResourceService
  ) { }

  invalidateCache() {
    this.types = undefined;
    this.fieldsOfType = new Map();
    this.fieldEnumValues = new Map();
  }

  getEnumValues(fieldIds: number[]): Observable<EnumValuesResponse> {
    if (fieldIds.length == 0) {
      return of({ enumValues: [], entryPermission: false });
    }
    let fieldPath = '';
    fieldIds.forEach((id) => (fieldPath += id + '.'));
    fieldPath = fieldPath.slice(0, -1);
    let valueFromCache: EnumValuesResponse =
      this.fieldEnumValues.get(fieldPath);
    if (valueFromCache) {
      return of(valueFromCache);
    }
    return this.callServerForEnumValues(fieldIds).pipe(
      map((res) => {
        this.fieldEnumValues.set(fieldPath, res);
        return res;
      })
    );
  }

  callServerForEnumValues(fieldIds): Observable<EnumValuesResponse> {
    return this.complexTypeFieldService.fetchEnumValuesUsingPOST(fieldIds);
  }

  getTypes(): Observable<TypeInModule[]> {
    if (this.types) {
      return of(this.types);
    }
    let typesInClassesRequest =
      this.complexTypeService.getAllActiveTypesAsClassTypesUsingGET();
    let commonTypeRequest =
      this.complexTypeService.getFirstLevelDocSystemFieldsTypeUsingGET();
    return forkJoin([typesInClassesRequest, commonTypeRequest]).pipe(
      map((types) => {
        let mappedCommonType = this.toTypeInModule(types[1]);
        let mappedTypes = types[0].map((type) => {
          return this.toTypeInModule(type);
        });
        mappedTypes.push(mappedCommonType);
        this.types = mappedTypes;
        return mappedTypes;
      })
    );
  }

  getFieldsByParams(typeId: number): Observable<ComplexFieldWrapper[]> {
    let valueFromCache: ComplexFieldWrapper[] = this.fieldsOfType.get(typeId);
    if (valueFromCache) {
      return of(valueFromCache);
    }
    return this.complexTypeService
      .getAllActiveAttributesOfComplexDataTypeUsingGET(typeId)
      .pipe(
        map((result) => {
          this.fieldsOfType.set(typeId, result);
          return result;
        })
      );
  }

  getTypeDescOfField(
    typeId: number,
    fieldIds: number[]
  ): Observable<DataTypeDesc> {
    return this.getFieldsByParams(typeId).pipe(
      map((fieldsOfType) =>
        this.getTypeDescOfFieldInternal(fieldsOfType, fieldIds)
      )
    );
  }

  getTypeDescOfFieldInternal(
    typeFields: ComplexFieldWrapper[],
    fieldIds: number[]
  ): DataTypeDesc {
    let result: DataTypeDesc = {};
    let fields = [...typeFields];
    const selectedFieldIdx = fieldIds[fieldIds.length - 1];
    for (let index = 0; index < fieldIds.length; index++) {
      const fieldWrapper = fields.find(
        (el: ComplexFieldWrapper) => el.field.id === fieldIds[index]
      );
      if (fieldWrapper?.field.id === selectedFieldIdx) {
        return fieldWrapper.field;
      }
      fields = fieldWrapper?.nestedFields ?? [];
    }
    return result;
  }

  toTypeInModule(type: ComplexDataTypeDTO): TypeInModule {
    return {
      id: +type.id,
      name: type.name,
      moduleId: type.moduleId,
      moduleName: type.moduleName,
    };
  }
}
