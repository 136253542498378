/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentTokenDTO } from './documentTokenDTO';


export interface SimpleDocumentDTO { 
    accessToken?: DocumentTokenDTO;
    creationDate?: number;
    documentId?: string;
    documentName?: string;
    editionDate?: number;
    editorUsername?: string;
    mainDocumentId?: string;
    originalFileCreationDate?: number;
    originalFilename?: string;
    processedFileCreationDate?: number;
    processedFilename?: string;
}
