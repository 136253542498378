/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserDTO { 
    activated?: boolean;
    authorities?: Array<string>;
    createdBy?: string;
    createdDate?: Date;
    email?: string;
    firstName?: string;
    id?: string;
    imageUrl?: string;
    langKey?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    lastName?: string;
    login?: string;
}
