import { UserProfileResourceService } from './../../dmssdk/api/userProfileResource.service';
import { UserGroupLinkResourceService } from 'src/app/dmssdk';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserRightsService {
  constructor(
    private linkService: UserGroupLinkResourceService,
    private profileService: UserProfileResourceService
  ) { }

  userHasAnyAdminRights(): Observable<boolean> {
    return this.linkService.getActiveUserGroupLinkOfUserUsingGET().pipe(
      mergeMap((link) =>
        this.profileService.getCurrentUserProfileUsingGET()
      ),
      map((profile) => {
        if (!profile) {
          return false;
        }

        return (
          profile.canManageCondition ||
          profile.canManageSubgroups ||
          profile.canManageTree ||
          profile.canManageUsers ||
          profile.canManageShares ||
          profile.canManageStamps
        );
      })
    );
  }
}
