/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DistinctValueDTO } from '../model/distinctValueDTO';
import { DocumentTreeBranchDTO } from '../model/documentTreeBranchDTO';
import { DocumentTreeBranchWithCountDTO } from '../model/documentTreeBranchWithCountDTO';
import { TreeBranchNamedValueDTO } from '../model/treeBranchNamedValueDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentTreeBranchResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createDocumentTreeBranch
     * 
     * @param documentTreeBranchDTO documentTreeBranchDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocumentTreeBranchUsingPOST(documentTreeBranchDTO: DocumentTreeBranchDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentTreeBranchDTO>;
    public createDocumentTreeBranchUsingPOST(documentTreeBranchDTO: DocumentTreeBranchDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTreeBranchDTO>>;
    public createDocumentTreeBranchUsingPOST(documentTreeBranchDTO: DocumentTreeBranchDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTreeBranchDTO>>;
    public createDocumentTreeBranchUsingPOST(documentTreeBranchDTO: DocumentTreeBranchDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentTreeBranchDTO === null || documentTreeBranchDTO === undefined) {
            throw new Error('Required parameter documentTreeBranchDTO was null or undefined when calling createDocumentTreeBranchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DocumentTreeBranchDTO>(`${this.basePath}/api/document-tree-branches`,
            documentTreeBranchDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteDocumentTreeBranch
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocumentTreeBranchUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDocumentTreeBranchUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDocumentTreeBranchUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDocumentTreeBranchUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDocumentTreeBranchUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/document-tree-branches/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportDocumentsFromBranch
     * 
     * @param attributeValues attributeValues
     * @param branchId branchId
     * @param mode mode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportDocumentsFromBranchUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, mode: 'FLAT' | 'TREE', observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportDocumentsFromBranchUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, mode: 'FLAT' | 'TREE', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportDocumentsFromBranchUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, mode: 'FLAT' | 'TREE', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportDocumentsFromBranchUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, mode: 'FLAT' | 'TREE', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attributeValues === null || attributeValues === undefined) {
            throw new Error('Required parameter attributeValues was null or undefined when calling exportDocumentsFromBranchUsingPOST.');
        }

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling exportDocumentsFromBranchUsingPOST.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling exportDocumentsFromBranchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/api/document-tree-branches/${encodeURIComponent(String(branchId))}/export-${encodeURIComponent(String(mode))}`,
            attributeValues,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getChildrenOfDocumentTreeBranch
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChildrenOfDocumentTreeBranchUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentTreeBranchDTO>>;
    public getChildrenOfDocumentTreeBranchUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentTreeBranchDTO>>>;
    public getChildrenOfDocumentTreeBranchUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentTreeBranchDTO>>>;
    public getChildrenOfDocumentTreeBranchUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getChildrenOfDocumentTreeBranchUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DocumentTreeBranchDTO>>(`${this.basePath}/api/document-tree-branches/${encodeURIComponent(String(id))}/children`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getChildrenOfDocumentTreeBranchWithCount
     * 
     * @param attributeValues attributeValues
     * @param branchId branchId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChildrenOfDocumentTreeBranchWithCountUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentTreeBranchWithCountDTO>>;
    public getChildrenOfDocumentTreeBranchWithCountUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentTreeBranchWithCountDTO>>>;
    public getChildrenOfDocumentTreeBranchWithCountUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentTreeBranchWithCountDTO>>>;
    public getChildrenOfDocumentTreeBranchWithCountUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attributeValues === null || attributeValues === undefined) {
            throw new Error('Required parameter attributeValues was null or undefined when calling getChildrenOfDocumentTreeBranchWithCountUsingPOST.');
        }

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getChildrenOfDocumentTreeBranchWithCountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<DocumentTreeBranchWithCountDTO>>(`${this.basePath}/api/document-tree-branches/${encodeURIComponent(String(branchId))}/children-with-count`,
            attributeValues,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDistinctAttributeValues
     * 
     * @param attributeValues attributeValues
     * @param branchId branchId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDistinctAttributeValuesUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DistinctValueDTO>>;
    public getDistinctAttributeValuesUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DistinctValueDTO>>>;
    public getDistinctAttributeValuesUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DistinctValueDTO>>>;
    public getDistinctAttributeValuesUsingPOST(attributeValues: Array<TreeBranchNamedValueDTO>, branchId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (attributeValues === null || attributeValues === undefined) {
            throw new Error('Required parameter attributeValues was null or undefined when calling getDistinctAttributeValuesUsingPOST.');
        }

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getDistinctAttributeValuesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<DistinctValueDTO>>(`${this.basePath}/api/document-tree-branches/${encodeURIComponent(String(branchId))}/distinct-attribute-values`,
            attributeValues,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentTreeBranch
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentTreeBranchUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentTreeBranchDTO>;
    public getDocumentTreeBranchUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTreeBranchDTO>>;
    public getDocumentTreeBranchUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTreeBranchDTO>>;
    public getDocumentTreeBranchUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentTreeBranchUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentTreeBranchDTO>(`${this.basePath}/api/document-tree-branches/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRootDocumentTreeBranchOfCurrentGroup
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRootDocumentTreeBranchOfCurrentGroupUsingGET(observe?: 'body', reportProgress?: boolean): Observable<DocumentTreeBranchDTO>;
    public getRootDocumentTreeBranchOfCurrentGroupUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTreeBranchDTO>>;
    public getRootDocumentTreeBranchOfCurrentGroupUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTreeBranchDTO>>;
    public getRootDocumentTreeBranchOfCurrentGroupUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentTreeBranchDTO>(`${this.basePath}/api/document-tree-branches/root`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRootDocumentTreeBranchWithCountOfCurrentGroup
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRootDocumentTreeBranchWithCountOfCurrentGroupUsingGET(observe?: 'body', reportProgress?: boolean): Observable<DocumentTreeBranchWithCountDTO>;
    public getRootDocumentTreeBranchWithCountOfCurrentGroupUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTreeBranchWithCountDTO>>;
    public getRootDocumentTreeBranchWithCountOfCurrentGroupUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTreeBranchWithCountDTO>>;
    public getRootDocumentTreeBranchWithCountOfCurrentGroupUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentTreeBranchWithCountDTO>(`${this.basePath}/api/document-tree-branches/root-with-count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateDocumentTreeBranch
     * 
     * @param documentTreeBranchDTO documentTreeBranchDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocumentTreeBranchUsingPUT(documentTreeBranchDTO: DocumentTreeBranchDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentTreeBranchDTO>;
    public updateDocumentTreeBranchUsingPUT(documentTreeBranchDTO: DocumentTreeBranchDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentTreeBranchDTO>>;
    public updateDocumentTreeBranchUsingPUT(documentTreeBranchDTO: DocumentTreeBranchDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentTreeBranchDTO>>;
    public updateDocumentTreeBranchUsingPUT(documentTreeBranchDTO: DocumentTreeBranchDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentTreeBranchDTO === null || documentTreeBranchDTO === undefined) {
            throw new Error('Required parameter documentTreeBranchDTO was null or undefined when calling updateDocumentTreeBranchUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DocumentTreeBranchDTO>(`${this.basePath}/api/document-tree-branches`,
            documentTreeBranchDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
