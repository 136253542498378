import { CurrentUserGroupsDTO } from './../../../dmssdk/model/currentUserGroupsDTO';
import { Component, Input, OnInit } from '@angular/core';
import { UsersGroupDTO, UsersGroupResourceService } from 'src/app/dmssdk';
import { UserGroupService } from 'src/app/services/user-group/user-group.service';

@Component({
  selector: 'evo-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
})
export class GroupSelectorComponent implements OnInit {
  @Input()
  public set data(result: CurrentUserGroupsDTO) {
    if (!result) {
      return;
    }
    this.groups = result.groups ? result.groups : [];
    if (result.currentGroup) {
      this.selectedGroup = result.currentGroup;
    }
  }

  groups: UsersGroupDTO[] = [];
  selectedGroup: UsersGroupDTO = { id: -1 };

  constructor(
    private usersGroupResource: UsersGroupResourceService,
    private usersGroupService: UserGroupService
  ) {}

  ngOnInit(): void {
    this.usersGroupResource
      .getAllEnabledGroupsOfCurrentUserAndDefaultGroupUsingGET()
      .subscribe((result) => {
        this.groups = result.groups ? result.groups : [];
        if (result.currentGroup) {
          this.selectedGroup = result.currentGroup;
        }
      });
    this.usersGroupService.addedSubgroupObservable.subscribe((subgroup) => {
      if (Object.keys(subgroup).length != 0) {
        this.groups.push(subgroup);
      }
    });
  }

  onGroupSelect() {
    this.usersGroupResource
      .updateCurrentUserGroupUsingPATCH({
        id: this.selectedGroup.id,
      })
      .subscribe((res) => {
        this.reloadPage();
      });
  }

  reloadPage() {
    window.location.reload();
  }
}
