<div fxLayout="column" fxLayoutAlign="center center" class="column-height">
  <div fxFlex="10%"></div>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="10%"
    class="dms-title-div"
  >
    <h1 class="dms-title">{{ "titles.app" | translate }}</h1>
  </div>
  <div fxFlex="1px"></div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex="70%"
    class="selector-div"
  >
    <h1 class="selector-title">
      {{ "group-selecting.no-group-set" | translate }}
    </h1>
    <h3 class="selector-message">
      {{ "group-selecting.please-select-group" | translate }}:
    </h3>

    <mat-form-field appearance="fill">
      <mat-label>{{ "single-words.group" | translate }}</mat-label>
      <mat-select (selectionChange)="groupChange($event)">
        <mat-option *ngFor="let group of groups" [value]="group">{{
          group.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxFlex="10%"></div>
</div>
