<div
  class="thin-frame-faded6"
  fxFlex="100%"
  [class.disabled]="isDisabled"
  [class.thin-frame-faded4]="isDisabled"
>
  <form>
    <mat-form-field *ngIf="type == 'DATE'" class="date-input" fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input
        [ngxMatDatetimePicker]="picker"
        required="true"
        matInput
        [formControl]="formCtrl"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker>
        <ng-template>
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-form-field *ngIf="type == 'LONG'" class="long-input" fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input
        matInput
        allowNegatives="true"
        required="true"
        digitOnly
        [formControl]="formCtrl"
      />
    </mat-form-field>
    <mat-form-field *ngIf="type == 'DOUBLE'" class="double-input" fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input
        matInput
        digitOnly
        allowNegatives="true"
        required="true"
        decimal="true"
        [decimalSeparator]="decimalPoint"
        [formControl]="formCtrl"
      />
    </mat-form-field>
    <mat-form-field *ngIf="type == 'STRING'" class="string-input" fxFlex>
      <mat-label>{{ label }}</mat-label>
      <input matInput [type]="text" [formControl]="formCtrl" />
    </mat-form-field>
  </form>
</div>
