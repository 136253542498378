import { Injectable } from '@angular/core';
import * as Keycloak from 'keycloak-js';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OrganisationResourceService } from 'src/app/dmssdk';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  notFoundMessage: string = '';
  keycloak: any;
  config: Keycloak.KeycloakConfig;
  private userinfoSubject$: ReplaySubject<UserInfo>;
  public userInfoObservable: Observable<UserInfo>;
  private checkSSOKeycloak: any;

  constructor(
    private organisationService: OrganisationResourceService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.userinfoSubject$ = new ReplaySubject<UserInfo>(1);
    this.userInfoObservable = this.userinfoSubject$.asObservable();
  }

  public getToken(): Promise<string> {
    var oid = this.getOid();
    return this.initializeKeycloakClient(oid).then(() => {
      return this.getTokenFromServer();
    });
  }

  public isLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.keycloak.authenticated) {
        resolve(true);
      } else {
        this.getToken().then((token) => resolve(this.keycloak.authenticated));
      }
    });
  }

  public logOut() {
    this.keycloak.logout();
    this.log(
      'authenticated ' +
        this.keycloak.authenticated +
        ', token ' +
        this.keycloak.token
    );
  }

  public getUserinfo(): Observable<UserInfo> {
    return this.userInfoObservable;
  }

  public getCurrentLocale(): Observable<string> {
    return this.getUserinfo().pipe(map((val) => val.locale));
  }

  private getOid(): string {
    const url = new URL(this.getCurrentUrl());
    var oidFromUrl = url.searchParams.get('oid');
    if (oidFromUrl) {
      localStorage.setItem('oid', oidFromUrl);
      return oidFromUrl;
    }
    return localStorage.getItem('oid');
  }

  public getCurrentUrl(): string {
    return window.location.href;
  }

  public getTokenFromServer(): Promise<string> {
    var that = this;
    return new Promise<string>((resolve, reject) => {
      if (this.keycloak.token && !this.keycloak.isTokenExpired()) {
        this.log('Token still valid.');
        resolve(this.keycloak.token);
        return;
      }
      if (this.keycloak.token && this.keycloak.isTokenExpired()) {
        this.log('Token expired. Updating token.');
        this.keycloak
          .updateToken()
          .then(function () {
            that.log('Expired token updated.');
            that.log(
              'Refresh token validity: ' + that.keycloak.refreshTokenParsed.exp
            );
            resolve(that.keycloak.token);
          })
          .catch(function (error) {
            that.logIn(that, resolve, reject);
          });
        return;
      }
      this.log('No token. Login.');
      this.logIn(that, resolve, reject);
    });
  }

  public initializeKeycloakClient(oid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.keycloak) {
        resolve();
        return;
      }
      if (!oid) {
        this.notFoundMessage = 'organisation-not-specified';
        this.router.navigate(['/not-found']);
        reject();
      }
      this.organisationService.getTokenIssuerUrlUsingGET(oid).subscribe(
        (res) => {
          this.config = this.config ?? {
            url: res.url,
            realm: res.realm,
            clientId: environment.keycloak.clientId,
          };
          this.keycloak = this.keycloak ?? Keycloak(this.config);
          resolve();
        },
        () => {
          this.notFoundMessage = 'unknown-organisation';
          this.router.navigate(['/not-found']);
          reject();
        }
      );
    });
  }

  private getUserinfoFromToken(tokenObj: any): UserInfo {
    return {
      username: tokenObj.preferred_username,
      userFullname: tokenObj.name ?? tokenObj.preferred_username,
      userMail: tokenObj.email,
      accountUrl: tokenObj.iss + '/account',
      locale: tokenObj.locale,
    };
  }

  private logIn(that: LoginService, resolve, reject) {
    var silentRedirectUrl =
      window.location.origin +
      environment.installationDirectory +
      '/assets/sso-callback.html';
    that.log('Init with check-sso. Silent redirect url: ' + silentRedirectUrl);
    that.keycloak = this.getCheckSSOKeycloak(that);
    that.keycloak
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: silentRedirectUrl,
        enableLogging: true,
        //checkLoginIframe: false
      })
      .then((authenticated) => {
        that.log('Init with check-sso done. Authenticated: ' + authenticated);
        that.processInitSuccess(authenticated, resolve, reject, () => {
          that.log('Init with login-required. Authenticated: ' + authenticated);
          that.keycloak = Keycloak(that.config);
          that.keycloak
            .init({
              onLoad: 'login-required',
              enableLogging: true,
              //checkLoginIframe: false
            })
            .then((isAuth) => {
              that.processInitSuccess(isAuth, resolve, reject);
            })
            .catch(() => {
              that.log('Check sso error');
              reject();
            });
        });
      })
      .catch(() => {
        that.log('Check sso error');
        reject();
      });
  }

  private processInitSuccess(authenticated, resolve, reject, callback?) {
    this.log('Authentication success: ' + authenticated);
    if (authenticated) {
      this.userinfoSubject$.next(
        this.getUserinfoFromToken(this.keycloak.tokenParsed)
      );
      resolve(this.keycloak.token);
    } else {
      if (callback) {
        callback();
      } else {
        reject();
      }
    }
  }

  private log(message) {
    //var date = new Date();
    //console.log(date.toLocaleString() + ': TOKEN-' + message);
  }

  private getCheckSSOKeycloak(that: LoginService) {
    if (that.checkSSOKeycloak) {
      return that.checkSSOKeycloak;
    }
    that.checkSSOKeycloak = Keycloak(that.config);
    return that.checkSSOKeycloak;
  }
}

export interface UserInfo {
  username?: string;
  userFullname?: string;
  userMail?: string;
  accountUrl?: string;
  locale?: string;
}
