import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { QuerySearchDTO, UserDefinedConditionsDTO } from 'src/app/dmssdk';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StateService } from 'src/app/shared/services/search-state/search-state.service';
import { SimplePanelState } from 'src/app/shared/services/search-state/model/simple-panel-state';

@Component({
  selector: 'evo-filtering-panel',
  templateUrl: './filtering-panel.component.html',
  styleUrls: ['./filtering-panel.component.scss'],
})
export class FilteringPanelComponent implements OnInit, OnDestroy {

  @Input()
  public set pageSize(value: number) {
    if (value) {
      this.querySearch.pageSize = value;
    }
  }

  public querySearch: QuerySearchDTO = {
    ensurePageConsistency: false,
    pageSize: null,
    searchAfter: null,
    includeHistory: false,
  };

  @Output('searchButtonClicked')
  public searchButtonClicked: EventEmitter<UserDefinedConditionsDTO> = new EventEmitter();

  public searchValue: string = '*';

  constructor(private stateService: StateService) { }

  ngOnInit() {
    let panelState = this.stateService.getSimplePanelState();
    if (panelState) {
      this.searchValue = panelState.simpleSearchPhrase;
      this.querySearch = panelState.querySearch;
    }
  }

  ngOnDestroy() {
    let state: SimplePanelState = { simpleSearchPhrase: this.searchValue, querySearch: this.querySearch }
    this.stateService.setSimplePanelState(state);
  }

  searchClicked(event: Event) {
    event.stopPropagation();
    if (this.searchValue === null || this.searchValue === undefined) {
      this.searchValue = '';
    }
    this.searchButtonClicked.emit({
      querySearch: this.querySearch,
      conditionMode: UserDefinedConditionsDTO.ConditionModeEnum.SIMPLE,
      simpleSearchPhrase: this.searchValue,
    });
  }

  public includeHistoryChange(event: MatSlideToggleChange): void {
    this.querySearch.includeHistory = event.checked;
  }
}
