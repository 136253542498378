import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'evo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {

  private _type;
  private _rawValue;
  public formCtrl: FormControl = new FormControl();
  public isDisabled: boolean = false;
  public decimalPoint: string = ",";
  private currentLocale: string;

  private _requiredValidator = undefined;
  private _patternValidator = undefined;

  constructor(private loginService: LoginService, private ngxDateAdapter: NgxMatDateAdapter<any>
  ) {
    this.loginService.getCurrentLocale().subscribe(res => {
      this.currentLocale = res;
      this.ngxDateAdapter.setLocale(this.currentLocale);
      this.decimalPoint = this.getCurrentLocaleDecimalPoint();
    })
  }

  ngOnInit(): void {
    this.formCtrl.valueChanges.subscribe(
      () => {
        this.validityEvent.emit(this.formCtrl.valid);
        if (this.formCtrl.invalid) {
          return;
        }
        if (this._type == "DATE") {
          this.changeEvent.emit(this.formCtrl.value.valueOf());
        } else if (this._type == "DOUBLE") {
          this.changeEvent.emit(this.formCtrl.value.replace(this.decimalPoint, "."));
        }
        else {
          this.changeEvent.emit(this.formCtrl.value);
        }
      }
    );
  }

  @Input('text')
  public set text(val: string) {
    this._rawValue = val;
    this.setValue();
  }

  @Input('type')
  public set type(val: string) {
    this._type = val;
    this._patternValidator = undefined;
    if (val == 'DOUBLE') {
      this._patternValidator = Validators.pattern("^(((\\+|\\-)?)((((0\\" + this.decimalPoint +
        ")|([1-9][0-9]*\\" + this.decimalPoint + "))([0-9]+))|([1-9][0-9]*)))$");
    }
    if (val == 'LONG') {
      this._patternValidator = Validators.pattern("^((\\+|\\-)?)([1-9][0-9]*)$");
    }
    this.assignValidators();
    this.setValue();
    this.formCtrl.updateValueAndValidity();
  }

  public get type(): string {
    return this._type;
  }

  @Input('label')
  public label: string = '';

  @Input('disabled')
  public set disabled(val: boolean) {
    this.isDisabled = val;
    if (val) {
      this.formCtrl.disable();
    } else {
      this.formCtrl.enable();
    }
  }

  @Input('required')
  public set required(val: boolean) {
    this._requiredValidator = undefined;
    if (val) {
      this._requiredValidator = Validators.required;
    }
    this.assignValidators();
  }


  @Output('textChange')
  public changeEvent: EventEmitter<string> = new EventEmitter();

  @Output('valueValid')
  public validityEvent: EventEmitter<boolean> = new EventEmitter();


  private setValue() {
    if (this._type && this._rawValue) {
      var valueToSet = this._rawValue;
      if (this._type == "DATE") {
        valueToSet = new Date(+this._rawValue);
      }
      if (this._type == "DOUBLE") {
        valueToSet = this._rawValue.replace(".", this.decimalPoint);
      }
      this.formCtrl.setValue(valueToSet);
    }
  }

  private assignValidators() {
    this.formCtrl.clearValidators();
    this.formCtrl.setValidators([this._patternValidator, this._requiredValidator].filter(elem => elem));
  }

  private getCurrentLocaleDecimalPoint(): string {
    return Number(1.1).toLocaleString(this.currentLocale).charAt(1);
  }
}
