/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AppMigrationStepExecDTO { 
    executionDate?: Date;
    id?: number;
    identifier?: string;
    indexName?: string;
    indexType?: AppMigrationStepExecDTO.IndexTypeEnum;
    name?: string;
    organisationId?: number;
    organisationName?: string;
}
export namespace AppMigrationStepExecDTO {
    export type IndexTypeEnum = 'MAIN' | 'HISTORICAL';
    export const IndexTypeEnum = {
        MAIN: 'MAIN' as IndexTypeEnum,
        HISTORICAL: 'HISTORICAL' as IndexTypeEnum
    };
}
