/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DocumentFieldMappingElementDTO { 
    id?: number;
    leftSide?: string;
    leftSideType?: DocumentFieldMappingElementDTO.LeftSideTypeEnum;
    organisationId?: number;
    organisationName?: string;
    ownerId?: number;
    rightSide?: string;
}
export namespace DocumentFieldMappingElementDTO {
    export type LeftSideTypeEnum = 'STRING' | 'DATE' | 'INTEGER' | 'FLOAT' | 'BOOLEAN';
    export const LeftSideTypeEnum = {
        STRING: 'STRING' as LeftSideTypeEnum,
        DATE: 'DATE' as LeftSideTypeEnum,
        INTEGER: 'INTEGER' as LeftSideTypeEnum,
        FLOAT: 'FLOAT' as LeftSideTypeEnum,
        BOOLEAN: 'BOOLEAN' as LeftSideTypeEnum
    };
}
