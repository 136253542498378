/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PathWrapper } from './pathWrapper';


export interface DistinctAttributeInfo { 
    path?: PathWrapper;
    sortingOrder?: DistinctAttributeInfo.SortingOrderEnum;
}
export namespace DistinctAttributeInfo {
    export type SortingOrderEnum = 'DESC' | 'ASC';
    export const SortingOrderEnum = {
        DESC: 'DESC' as SortingOrderEnum,
        ASC: 'ASC' as SortingOrderEnum
    };
}
