import { Injectable } from '@angular/core';
import { AbstractConditionAccess } from '@misc/angular-components'
import { ConditionElement, ConditionRoot } from '@misc/angular-components/lib/condition-editor/model/data'
import { Observable, of } from 'rxjs';
import { ConditionDTO, ConditionElementDTO } from 'src/app/dmssdk';


@Injectable({
  providedIn: 'root'
})
export class ConditionAccessAdhocService extends AbstractConditionAccess {

  constructor() {
    super();
  }

  loadData(root: ConditionRoot): Observable<ConditionElement[]> {
    var elements: ConditionElement[] = this.rootsMap[root.internalId];
    if (elements) {
      return of(elements)
    }
    return of([]);
  }

  getWholeCondition(): ConditionDTO {
    return this.createCondition(this.root);
  }

  private createCondition(conditionRoot: ConditionRoot): ConditionDTO {
    if (!conditionRoot) {
      return undefined;
    }
    var result: ConditionDTO = {};
    result.root = conditionRoot;
    var elements: ConditionElement[] = this.rootsMap[conditionRoot.internalId];
    result.elements = elements.map(elem => this.mapToConditionElementBack(elem));
    return result;
  }

  private mapToConditionElementBack(conditionElement: ConditionElement): ConditionElementDTO {
    let result: ConditionElementDTO = {
      id: conditionElement.id,
      root: { id: conditionElement.id },
      functionDTO: conditionElement.booleanFunction,
      condition: this.createCondition(conditionElement.booleanOperatorChild),
    }
    return result;
  }
}
