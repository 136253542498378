export * from './accountResource.service';
import { AccountResourceService } from './accountResource.service';
export * from './appMigrationStepExecResource.service';
import { AppMigrationStepExecResourceService } from './appMigrationStepExecResource.service';
export * from './authInfoResource.service';
import { AuthInfoResourceService } from './authInfoResource.service';
export * from './batchJobDescriptionResource.service';
import { BatchJobDescriptionResourceService } from './batchJobDescriptionResource.service';
export * from './batchJobMonitoringResource.service';
import { BatchJobMonitoringResourceService } from './batchJobMonitoringResource.service';
export * from './batchJobStepMonitoringResource.service';
import { BatchJobStepMonitoringResourceService } from './batchJobStepMonitoringResource.service';
export * from './booleanExpressionResource.service';
import { BooleanExpressionResourceService } from './booleanExpressionResource.service';
export * from './booleanFunctionResource.service';
import { BooleanFunctionResourceService } from './booleanFunctionResource.service';
export * from './booleanFunctionSideResource.service';
import { BooleanFunctionSideResourceService } from './booleanFunctionSideResource.service';
export * from './booleanOperatorResource.service';
import { BooleanOperatorResourceService } from './booleanOperatorResource.service';
export * from './complexDataTypeFieldResource.service';
import { ComplexDataTypeFieldResourceService } from './complexDataTypeFieldResource.service';
export * from './complexDataTypeResource.service';
import { ComplexDataTypeResourceService } from './complexDataTypeResource.service';
export * from './dataDefinitionModuleResource.service';
import { DataDefinitionModuleResourceService } from './dataDefinitionModuleResource.service';
export * from './documentClassMappingElementResource.service';
import { DocumentClassMappingElementResourceService } from './documentClassMappingElementResource.service';
export * from './documentClassResource.service';
import { DocumentClassResourceService } from './documentClassResource.service';
export * from './documentFieldMappingElementResource.service';
import { DocumentFieldMappingElementResourceService } from './documentFieldMappingElementResource.service';
export * from './documentFieldMappingResource.service';
import { DocumentFieldMappingResourceService } from './documentFieldMappingResource.service';
export * from './documentResource.service';
import { DocumentResourceService } from './documentResource.service';
export * from './documentShareResource.service';
import { DocumentShareResourceService } from './documentShareResource.service';
export * from './documentTreeBranchResource.service';
import { DocumentTreeBranchResourceService } from './documentTreeBranchResource.service';
export * from './documentsProcessingConfigurationResource.service';
import { DocumentsProcessingConfigurationResourceService } from './documentsProcessingConfigurationResource.service';
export * from './emailServerPasswordResource.service';
import { EmailServerPasswordResourceService } from './emailServerPasswordResource.service';
export * from './emailServerResource.service';
import { EmailServerResourceService } from './emailServerResource.service';
export * from './enumDataTypeResource.service';
import { EnumDataTypeResourceService } from './enumDataTypeResource.service';
export * from './enumDataTypeValueResource.service';
import { EnumDataTypeValueResourceService } from './enumDataTypeValueResource.service';
export * from './fieldValueAssignmentResource.service';
import { FieldValueAssignmentResourceService } from './fieldValueAssignmentResource.service';
export * from './keycloakUserResource.service';
import { KeycloakUserResourceService } from './keycloakUserResource.service';
export * from './logoImageResource.service';
import { LogoImageResourceService } from './logoImageResource.service';
export * from './logoutResource.service';
import { LogoutResourceService } from './logoutResource.service';
export * from './organisationResource.service';
import { OrganisationResourceService } from './organisationResource.service';
export * from './queryResource.service';
import { QueryResourceService } from './queryResource.service';
export * from './querySortResource.service';
import { QuerySortResourceService } from './querySortResource.service';
export * from './stampResource.service';
import { StampResourceService } from './stampResource.service';
export * from './triggerConfigurationResource.service';
import { TriggerConfigurationResourceService } from './triggerConfigurationResource.service';
export * from './userGroupLinkResource.service';
import { UserGroupLinkResourceService } from './userGroupLinkResource.service';
export * from './userProfileResource.service';
import { UserProfileResourceService } from './userProfileResource.service';
export * from './userResource.service';
import { UserResourceService } from './userResource.service';
export * from './usersGroupResource.service';
import { UsersGroupResourceService } from './usersGroupResource.service';
export * from './vantageDailyUsageResource.service';
import { VantageDailyUsageResourceService } from './vantageDailyUsageResource.service';
export const APIS = [AccountResourceService, AppMigrationStepExecResourceService, AuthInfoResourceService, BatchJobDescriptionResourceService, BatchJobMonitoringResourceService, BatchJobStepMonitoringResourceService, BooleanExpressionResourceService, BooleanFunctionResourceService, BooleanFunctionSideResourceService, BooleanOperatorResourceService, ComplexDataTypeFieldResourceService, ComplexDataTypeResourceService, DataDefinitionModuleResourceService, DocumentClassMappingElementResourceService, DocumentClassResourceService, DocumentFieldMappingElementResourceService, DocumentFieldMappingResourceService, DocumentResourceService, DocumentShareResourceService, DocumentTreeBranchResourceService, DocumentsProcessingConfigurationResourceService, EmailServerPasswordResourceService, EmailServerResourceService, EnumDataTypeResourceService, EnumDataTypeValueResourceService, FieldValueAssignmentResourceService, KeycloakUserResourceService, LogoImageResourceService, LogoutResourceService, OrganisationResourceService, QueryResourceService, QuerySortResourceService, StampResourceService, TriggerConfigurationResourceService, UserGroupLinkResourceService, UserProfileResourceService, UserResourceService, UsersGroupResourceService, VantageDailyUsageResourceService];
