<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <img
      *ngIf="i === currentSlide"
      [src]="slide.src"
      class="slide"
      alt="Generated pdf preview"
    />
  </ng-container>

  <button class="control prev" (click)="onPreviousClick($event)">
    <span class="arrow left"></span>
  </button>
  <button class="control next" (click)="onNextClick($event)">
    <span class="arrow right"></span>
  </button>
</div>
