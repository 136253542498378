<span
  class="circle"
  [ngStyle]="
    pointerCursor
      ? {
          'background-color': bgColor,
          border: 'solid #343434 1px',
          cursor: 'pointer'
        }
      : { 'background-color': bgColor, border: 'solid #343434 1px' }
  "
></span>
