/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConditionDTO } from './conditionDTO';
import { DistinctAttributeInfo } from './distinctAttributeInfo';
import { QuerySortDTO } from './querySortDTO';


export interface QuerySearchDTO { 
    conditionDTO?: ConditionDTO;
    distinctAttributeInfo?: DistinctAttributeInfo;
    ensurePageConsistency?: boolean;
    includeHistory?: boolean;
    pageSize?: number;
    pagingKey?: string;
    querySort?: Array<QuerySortDTO>;
    reverseSorting?: boolean;
    searchAfter?: Array<any>;
}
