<div fxLayout="column" fxLayoutAlign="center center" class="column-height">
  <div fxFlex="10%"></div>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="10%"
    class="dms-title-div"
  >
    <h1 class="dms-title">{{ "titles.app" | translate }}</h1>
  </div>
  <div fxFlex="1px"></div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex="70%"
    class="not-found-div"
  >
    <h1 class="not-found-title">{{ "not-found.message" | translate }}</h1>
    <h3 class="not-found-message">{{ "not-found." + message }}</h3>
  </div>
  <div fxFlex="10%"></div>
</div>
