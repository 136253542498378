import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { UserDefinedConditionsDTO } from 'src/app/dmssdk/model/userDefinedConditionsDTO';
import { SelectedTreeBranch } from 'src/app/pages/user/documents-tree/documents-tree.component';

@Injectable({
  providedIn: 'root',
})
export class UserSearchStateService {
  private userContext: UserDefinedConditionsDTO = { querySearch: {} };
  private queryValid: boolean = true;

  public contextSubject: Subject<UserDefinedConditionsDTO> =
    new Subject<UserDefinedConditionsDTO>();

  public conditionAndValiditySubject: Subject<
    [UserDefinedConditionsDTO, boolean]
  > = new Subject<[UserDefinedConditionsDTO, boolean]>();

  updateTreeContext(event: SelectedTreeBranch) {
    this.userContext.branchId = event.searchId;
    this.userContext.attributeBranches = event.attributeValues;
    this.contextSubject.next(this.userContext);
  }

  updateEditorContext(event: UserDefinedConditionsDTO, valid: boolean) {
    this.userContext.querySearch = event.querySearch;
    this.userContext.conditionMode = event.conditionMode;
    this.userContext.simpleSearchPhrase = event.simpleSearchPhrase;
    this.queryValid = valid;
  }

  emitContextSubject() {
    this.contextSubject.next(this.userContext);
  }

  emitConditionAndValiditySubject() {
    this.conditionAndValiditySubject.next([this.userContext, this.queryValid]);
  }
}
