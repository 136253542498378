/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BatchJobStepMonitoringDTO { 
    batchJobMonitoringId?: number;
    endTime?: Date;
    id?: number;
    message?: string;
    organisationId?: number;
    startTime?: Date;
    status?: BatchJobStepMonitoringDTO.StatusEnum;
    stepName?: string;
}
export namespace BatchJobStepMonitoringDTO {
    export type StatusEnum = 'IN_PROGRESS' | 'FINISHED' | 'FAILED';
    export const StatusEnum = {
        INPROGRESS: 'IN_PROGRESS' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum
    };
}
