import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'evo-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent {
  @Input()
  public message: string;

  @Output()
  public close = new EventEmitter();

  onClose() {
    this.close.emit();
  }
}
