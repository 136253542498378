/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DataDefinitionModuleDTO } from '../model/dataDefinitionModuleDTO';
import { ModuleDataTypesDTO } from '../model/moduleDataTypesDTO';
import { PageOfDataDefinitionModuleDTO } from '../model/pageOfDataDefinitionModuleDTO';
import { SchemaValidationResult } from '../model/schemaValidationResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DataDefinitionModuleResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createDataDefinitionModule
     * 
     * @param dataDefinitionModuleDTO dataDefinitionModuleDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDataDefinitionModuleUsingPOST(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe?: 'body', reportProgress?: boolean): Observable<DataDefinitionModuleDTO>;
    public createDataDefinitionModuleUsingPOST(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDefinitionModuleDTO>>;
    public createDataDefinitionModuleUsingPOST(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDefinitionModuleDTO>>;
    public createDataDefinitionModuleUsingPOST(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dataDefinitionModuleDTO === null || dataDefinitionModuleDTO === undefined) {
            throw new Error('Required parameter dataDefinitionModuleDTO was null or undefined when calling createDataDefinitionModuleUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DataDefinitionModuleDTO>(`${this.basePath}/api/data-definition-modules`,
            dataDefinitionModuleDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteDataDefinitionModule
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDataDefinitionModuleUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDataDefinitionModuleUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDataDefinitionModuleUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDataDefinitionModuleUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDataDefinitionModuleUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/data-definition-modules/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllDataDefinitionModules
     * 
     * @param pageIndex 
     * @param pageSize 
     * @param sortField 
     * @param sortingOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDataDefinitionModulesUsingGET(pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe?: 'body', reportProgress?: boolean): Observable<PageOfDataDefinitionModuleDTO>;
    public getAllDataDefinitionModulesUsingGET(pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfDataDefinitionModuleDTO>>;
    public getAllDataDefinitionModulesUsingGET(pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfDataDefinitionModuleDTO>>;
    public getAllDataDefinitionModulesUsingGET(pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('pageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (sortField !== undefined && sortField !== null) {
            queryParameters = queryParameters.set('sortField', <any>sortField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageOfDataDefinitionModuleDTO>(`${this.basePath}/api/data-definition-modules`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDataDefinitionModule
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDataDefinitionModuleUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<DataDefinitionModuleDTO>;
    public getDataDefinitionModuleUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDefinitionModuleDTO>>;
    public getDataDefinitionModuleUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDefinitionModuleDTO>>;
    public getDataDefinitionModuleUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDataDefinitionModuleUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DataDefinitionModuleDTO>(`${this.basePath}/api/data-definition-modules/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getModuleDataTypes
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModuleDataTypesUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ModuleDataTypesDTO>;
    public getModuleDataTypesUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModuleDataTypesDTO>>;
    public getModuleDataTypesUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModuleDataTypesDTO>>;
    public getModuleDataTypesUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getModuleDataTypesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ModuleDataTypesDTO>(`${this.basePath}/api/data-definition-modules/${encodeURIComponent(String(id))}/data-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * installDataDefinitionModule
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public installDataDefinitionModuleUsingPUT(id: number, observe?: 'body', reportProgress?: boolean): Observable<SchemaValidationResult>;
    public installDataDefinitionModuleUsingPUT(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemaValidationResult>>;
    public installDataDefinitionModuleUsingPUT(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemaValidationResult>>;
    public installDataDefinitionModuleUsingPUT(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling installDataDefinitionModuleUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<SchemaValidationResult>(`${this.basePath}/api/data-definition-modules/${encodeURIComponent(String(id))}/installation`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateDataDefinitionModule
     * 
     * @param dataDefinitionModuleDTO dataDefinitionModuleDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDataDefinitionModuleUsingPUT(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe?: 'body', reportProgress?: boolean): Observable<DataDefinitionModuleDTO>;
    public updateDataDefinitionModuleUsingPUT(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataDefinitionModuleDTO>>;
    public updateDataDefinitionModuleUsingPUT(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataDefinitionModuleDTO>>;
    public updateDataDefinitionModuleUsingPUT(dataDefinitionModuleDTO: DataDefinitionModuleDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dataDefinitionModuleDTO === null || dataDefinitionModuleDTO === undefined) {
            throw new Error('Required parameter dataDefinitionModuleDTO was null or undefined when calling updateDataDefinitionModuleUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DataDefinitionModuleDTO>(`${this.basePath}/api/data-definition-modules`,
            dataDefinitionModuleDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateDataDefinitionModule
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateDataDefinitionModuleUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<SchemaValidationResult>;
    public validateDataDefinitionModuleUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemaValidationResult>>;
    public validateDataDefinitionModuleUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemaValidationResult>>;
    public validateDataDefinitionModuleUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling validateDataDefinitionModuleUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SchemaValidationResult>(`${this.basePath}/api/data-definition-modules/${encodeURIComponent(String(id))}/validation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
