export const environment = {
  production: false,
  api_url: 'https://dmsdev.evorsorge.de/storage_api',
  keycloak: {
    clientId: 'dms-gui-dev',
  },
  installationDirectory: '',
  translate: {
    defaultLang: 'en',
  },
};
