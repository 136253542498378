import { UserProfileResourceService } from './../dmssdk/api/userProfileResource.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { UserGroupLinkResourceService, UserProfileDTO } from '../dmssdk';

@Injectable({
  providedIn: 'root',
})
export class UserRightsGuard implements CanActivate {
  constructor(
    private router: Router,
    private service: UserGroupLinkResourceService,
    private profileService: UserProfileResourceService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const urlTree: UrlTree = this.router.parseUrl('/user');
    return this.service.getActiveUserGroupLinkOfUserUsingGET().pipe(
      mergeMap((link) => {
        if (!link || !link.enabled) {
          return of(urlTree);
        }
        return this.profileService.getCurrentUserProfileUsingGET();
      }),
      map((result) => {
        if (result instanceof UrlTree) {
          return result;
        }
        if (this.hasNoAdminRights(result)) {
          return urlTree;
        }
        return true;
      })
    );
  }

  private hasNoAdminRights(profile: UserProfileDTO) {
    return !(
      profile.canManageCondition ||
      profile.canManageSubgroups ||
      profile.canManageTree ||
      profile.canManageUsers ||
      profile.canManageShares ||
      profile.canManageStamps
    );
  }
}
