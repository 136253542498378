/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ComplexDataTypeDTO } from './complexDataTypeDTO';
import { EnumDataTypeDTO } from './enumDataTypeDTO';


export interface ModuleDataTypesDTO { 
    complexDataTypes?: Array<ComplexDataTypeDTO>;
    enumDataTypes?: Array<EnumDataTypeDTO>;
}
