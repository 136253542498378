import { Injectable } from '@angular/core';
import { Sort } from '@misc/angular-components';
import { QuerySortDTO } from 'src/app/dmssdk';

@Injectable({
  providedIn: 'root'
})
export class SortMapperService {

  constructor() { }

  public mapToSorts(querySorts: QuerySortDTO[]): Sort[] {
    let result = querySorts.map((querySort) => this.mapToSort(querySort));
    return result;
  }

  public mapToQuerySorts(querySorts: Sort[]): QuerySortDTO[] {
    let result = querySorts.map((querySort) => this.mapToQuerySort(querySort));
    return result;
  }

  public mapToSort(
    querySort: QuerySortDTO
  ): Sort {
    return {
      queryId: querySort.queryId,
      relatedQuerySortId: querySort.id,
      sortingOrder: querySort.sortingOrder,
      applyingOrder: querySort.applyingOrder,
      fieldId: {
        complexDataTypeFieldIds: querySort.complexDataTypeFieldIds,
        complexDataTypeId: querySort.complexDataTypeId,
        //type: querySort.fieldType
      }
    };
  }

  public mapToQuerySort(sort: Sort): QuerySortDTO {
    return {
      sortingOrder: sort.sortingOrder,
      applyingOrder: sort.applyingOrder,
      id: sort.relatedQuerySortId,
      complexDataTypeFieldIds: sort.fieldId?.complexDataTypeFieldIds,
      complexDataTypeId: sort.fieldId?.complexDataTypeId,
      queryId: sort.queryId
    };
  }
}
