import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

const CUSTOM_MOMENT_FORMATS = {
  parse: {
    dateInput: "L, LTS"
  },
  display: {
    dateInput: "L, LTS",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AppSettingsModule {

  public static forRoot(): ModuleWithProviders<AppSettingsModule> {
    return {
      ngModule: AppSettingsModule,
      providers: [
        { provide: MAT_DATE_LOCALE, useValue: "de" },
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
        { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter, deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AppSettingsModule) {
    console.log("Loading AppSettingsModule");
    if (parentModule) {
      throw new Error('AppSettingsModule is already loaded. Please add it in AppModule only.');
    }
  }
}
