<div class="row evo-text-with-label-editable" fxFlex="100%" fxLayoutAlign="stretch center">
  <div id="not-editable" *ngIf="!editMode" fxLayout="row" fxFlex="100%" fxLayoutAlign="start center"
    [class.hide]="_showOverlay">
    <evo-text-with-label fxFlex [labels]="labels" [text]="text" [type]="type"></evo-text-with-label>
    <button mat-icon-button (click)="edit($event)">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div id="editable" *ngIf="editMode" fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
    <evo-input [label]="labels[0]" [text]="text" [type]="type" (textChange)="update($event)"
      (valueValid)="setValidity($event)" fxFlex></evo-input>
    <button mat-icon-button (click)="save($event)" *ngIf="valueValid">
      <mat-icon>done</mat-icon>
    </button>
    <button mat-icon-button (click)="cancelEdit($event)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div id="overlay" *ngIf="_showOverlay" class="task-complete" fxLayout="row" fxLayoutGap="5px"
    fxLayoutAlign="start center" [style.color]="isEditSuccess ? '#28c959' : 'rgb(219, 57, 84)'">
    <mat-icon *ngIf="isEditSuccess">task_alt</mat-icon>
    <span id="msg" *ngIf="isEditSuccess">Saved succesfully!</span>
    <mat-icon *ngIf="!isEditSuccess">highlight_off</mat-icon>
    <span id="msg" *ngIf="!isEditSuccess">Save error!</span>
  </div>
</div>
