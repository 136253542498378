import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'evo-ediatble-label',
  templateUrl: './ediatble-label.component.html',
  styleUrls: ['./ediatble-label.component.scss'],
})
export class EdiatbleLabelComponent {
  @Input()
  public text: string;

  @Output()
  public textChange: EventEmitter<string> = new EventEmitter();

  @Input()
  public readOnly: boolean = false;

  @Output()
  public changeValue: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatInput)
  set myfancyinput(myfancyinput: MatInput) {
    if (myfancyinput) {
      setTimeout(() => {
        myfancyinput.focus();
      }, 0);
    }
  }

  public editable: boolean = false;

  update() {
    this.editable = false;
    this.textChange.emit(this.text);
    this.changeValue.emit();
  }

  startEdit() {
    this.editable = true;
  }
}
