/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Specialisation of BooleanExpression Evaluates to boolean value Allows to compare two sides
 */
export interface BooleanFunctionDTO { 
    functionName?: BooleanFunctionDTO.FunctionNameEnum;
    id?: number;
    leftSideId?: number;
    leftSideName?: string;
    name?: string;
    organisationId?: number;
    rightSideId?: number;
    rightSideName?: string;
}
export namespace BooleanFunctionDTO {
    export type FunctionNameEnum = 'EQUALS' | 'ISEMPTY' | 'BEGINS' | 'ENDS' | 'CONTAINS' | 'ISBIGGER' | 'ISSMALLER' | 'ISBIGGERE' | 'ISSMALLERE' | 'FUZZY' | 'CONTAINSSIMILAR' | 'CONTAINSREGEXP' | 'MATCHPHRASE' | 'EXISTS' | 'WILDCARD';
    export const FunctionNameEnum = {
        EQUALS: 'EQUALS' as FunctionNameEnum,
        ISEMPTY: 'ISEMPTY' as FunctionNameEnum,
        BEGINS: 'BEGINS' as FunctionNameEnum,
        ENDS: 'ENDS' as FunctionNameEnum,
        CONTAINS: 'CONTAINS' as FunctionNameEnum,
        ISBIGGER: 'ISBIGGER' as FunctionNameEnum,
        ISSMALLER: 'ISSMALLER' as FunctionNameEnum,
        ISBIGGERE: 'ISBIGGERE' as FunctionNameEnum,
        ISSMALLERE: 'ISSMALLERE' as FunctionNameEnum,
        FUZZY: 'FUZZY' as FunctionNameEnum,
        CONTAINSSIMILAR: 'CONTAINSSIMILAR' as FunctionNameEnum,
        CONTAINSREGEXP: 'CONTAINSREGEXP' as FunctionNameEnum,
        MATCHPHRASE: 'MATCHPHRASE' as FunctionNameEnum,
        EXISTS: 'EXISTS' as FunctionNameEnum,
        WILDCARD: 'WILDCARD' as FunctionNameEnum
    };
}
