<div fxLayout="row" fxLayoutAlign="space-between">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="0.5em"
    class="margins"
  >
    <mat-icon color="warn">warning</mat-icon>
    <div class="warn-text" translate>{{ message }}</div>
  </div>
  <div fxLayoutAlign="end start">
    <button mat-icon-button (click)="onClose()">
      <mat-icon color="warn">close</mat-icon>
    </button>
  </div>
</div>
