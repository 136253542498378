import { Injectable } from '@angular/core';
import { AbstractSortAccess, Sort } from '@misc/angular-components';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SortAccessAdhocService extends AbstractSortAccess {
  constructor() {
    super();
  }

  loadData(): Observable<Sort[]> {
    return of(this.elements);
  }

}
