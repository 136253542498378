<form [formGroup]="form">
  <div fxFlex="grow" fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field class="full-width">
          <mat-label>{{ "branch-editor.name" | translate }}</mat-label>
          <input
            formControlName="name"
            matInput
            placeholder="{{ 'branch-editor.name' | translate }}"
            required
          />
        </mat-form-field>
        <mat-slide-toggle
          *ngIf="!documentTreeBranch.parentId"
          formControlName="showCount"
          >{{ "branch-editor.show-count" | translate }}</mat-slide-toggle
        >
      </div>

      <button
        id="submitButton"
        mat-icon-button
        color="accent"
        [disabled]="form.pristine || form.invalid"
        (click)="save()"
      >
        <mat-icon>save</mat-icon>
      </button>
    </div>

    <mat-divider></mat-divider>
    <div>
      <mat-button-toggle-group
        (change)="onModeChange($event)"
        name="branchType"
        formControlName="mode"
        aria-label="Font Style"
      >
        <mat-button-toggle value="ATTRIBUTE">{{
          "branch-editor.attribute" | translate
        }}</mat-button-toggle>
        <mat-button-toggle value="NONE" *ngIf="!documentTreeBranch?.parentId">{{
          "branch-editor.none" | translate
        }}</mat-button-toggle>
        <mat-button-toggle
          type="button"
          *ngIf="documentTreeBranch?.parentId"
          value="CONDITION"
          >{{ "branch-editor.condition" | translate }}</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="10px"
      *ngIf="
        documentTreeBranch?.mode == treeBranchMode.ATTRIBUTE && attributeSet
      "
      class="attribute-choice"
    >
      <evo-type-field-choice
        [formControl]="form.controls['attribute']"
      ></evo-type-field-choice>
      <evo-date-granularity-choice
        *ngIf="granVisible"
        formControlName="dateGranularity"
      ></evo-date-granularity-choice>
      <evo-order-choice formControlName="attributesOrder"></evo-order-choice>
    </div>
    <div
      *ngIf="
        documentTreeBranch?.mode == treeBranchMode.CONDITION && conditionSet
      "
    >
      <evo-boolean-condition-element
        [allowRemove]="false"
        formControlName="condition"
        [conditionAccess]="conditionAccess"
        [showHelpButton]="true"
        fxFlex="grow"
      ></evo-boolean-condition-element>
    </div>
    <evo-sort
      [schemaAccess]="sortSchemaAccess"
      formControlName="sort"
      [sortAccess]="sortAccess"
      [queryId]="documentTreeBranch.queryId"
    ></evo-sort>
  </div>
</form>
