import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BooleanFunctionSideDTO,
  BooleanFunctionSideResourceService,
  BooleanOperatorResourceService,
  ComplexDataTypeResourceService,
  DocumentTreeBranchDTO,
  DocumentTreeBranchResourceService,
  QueryDTO,
  QueryResourceService,
  QuerySortResourceService,
  BooleanOperatorDTO,
  ComplexDataTypeFieldResourceService,
} from 'src/app/dmssdk';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  conditionElementOkValidator,
  ConditionRoot,
  DataTypeDesc,
  FieldId,
} from '@misc/angular-components';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ConditionAccessDbService } from 'src/app/shared/services/condition-access-service/condition-access-db.service';
import { SortAccessDbService } from 'src/app/shared/services/sort-access-service/sort-access-db.service';
import { SortMapperService } from 'src/app/shared/services/sort-access-service/sort-mapper.service';
import { SortSchemaAccessService } from 'src/app/shared/services/schema-access/sort-schema-access.service';

export function attributeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: FieldId = control.value;
    var notOK =
      !value.complexDataTypeId ||
      !value.complexDataTypeFieldIds ||
      value.complexDataTypeFieldIds.length == 0;
    return notOK ? { wrong: { value: control.value } } : null;
  };
}
@Component({
  selector: 'evo-branch-editor',
  templateUrl: './branch-editor.component.html',
  styleUrls: ['./branch-editor.component.scss'],
})
export class BranchEditorComponent {
  form: FormGroup;
  treeBranchMode = DocumentTreeBranchDTO.ModeEnum;

  //data access
  public conditionAccess: ConditionAccessDbService;
  public sortSchemaAccess: SortSchemaAccessService;
  public sortAccess: SortAccessDbService;

  //tree branch - main object
  private _documentTreeBranch: DocumentTreeBranchDTO = {};

  //attribute
  public fieldId: FieldId = {};
  public booleanFunctionSide: BooleanFunctionSideDTO;

  //condition
  private query: QueryDTO;
  private booleanOperator: BooleanOperatorDTO = {};

  attributeSet: boolean = false;
  conditionSet: boolean = false;
  granVisible: boolean = false;

  constructor(
    private treeBranchService: DocumentTreeBranchResourceService,
    private booleanFunctionSideService: BooleanFunctionSideResourceService,
    private formBuilder: FormBuilder,
    private operatorService: BooleanOperatorResourceService,
    private queryService: QueryResourceService,
    private querySortService: QuerySortResourceService,
    private sortMapper: SortMapperService,
    private complexTypeService: ComplexDataTypeResourceService,
    private complexTypeFieldService: ComplexDataTypeFieldResourceService,
    private sortSchemaAccessService: SortSchemaAccessService
  ) {
    this.conditionAccess = new ConditionAccessDbService(operatorService);
    this.sortAccess = new SortAccessDbService(
      queryService,
      querySortService,
      sortMapper
    );
    this.sortSchemaAccess = sortSchemaAccessService;
    this.initEmptyForm();
  }

  @Output()
  public treeBranchUpdated: EventEmitter<DocumentTreeBranchDTO> =
    new EventEmitter();

  @Input()
  public set documentTreeBranch(value: DocumentTreeBranchDTO) {
    this.clearData();
    this._documentTreeBranch = value;
    if (value) {
      this.form.controls['name'].setValue(value.name);
      this.form.controls['showCount'].setValue(value.countEnabled);
      this.form.controls['mode'].setValue(value.mode);
      if (value.mode === this.treeBranchMode.CONDITION) {
        this.getQuery(value);
      }
      if (value.mode === this.treeBranchMode.ATTRIBUTE) {
        this.getAttribute(value);
      }
    }
  }

  initEmptyForm() {
    this.form = this.formBuilder.group({
      mode: [undefined],
      name: [undefined],
      sort: [undefined],
      showCount: [undefined],
    });
  }

  private getAttribute(value: DocumentTreeBranchDTO) {
    //attribute id is set on demend
    var getRequest: Observable<BooleanFunctionSideDTO>;
    if (value.attributeId) {
      getRequest =
        this.booleanFunctionSideService.getBooleanFunctionSideUsingGET(
          value.attributeId
        );
    } else {
      getRequest = of({});
    }
    getRequest.subscribe((side) => {
      this.setAttribute(side);
    });
  }

  private getQuery(value: DocumentTreeBranchDTO) {
    //query id is always set in document tree branch
    this.queryService
      .getQueryUsingGET(value.queryId)
      .pipe(
        tap((query) => (this.query = query)),
        switchMap((query) => {
          if (query.booleanOperatorId) {
            return this.operatorService.getBooleanOperatorUsingGET(
              query.booleanOperatorId
            );
          }
          return of({});
        })
      )
      .subscribe((operator) => {
        this.setCondition(operator);
      });
  }

  public get documentTreeBranch() {
    return this._documentTreeBranch;
  }

  save() {
    if (this.form.valid === true) {
      if (this.documentTreeBranch.mode === this.treeBranchMode.ATTRIBUTE) {
        this.documentTreeBranch.attributeValuesOrder =
          this.form.controls['attributesOrder'].value;
        this.saveAttribute().subscribe(() => this.updateDocumentTreeBranch());
      }
      if (this.documentTreeBranch.mode === this.treeBranchMode.CONDITION) {
        this.saveCondition().subscribe(() => this.updateDocumentTreeBranch());
      }
      if (this.documentTreeBranch.mode === this.treeBranchMode.NONE) {
        this.updateDocumentTreeBranch();
      }
      this.sortAccess.save();
    }
  }

  private updateDocumentTreeBranch() {
    let toSave: DocumentTreeBranchDTO = this.documentTreeBranch;
    toSave.name = this.form.controls['name'].value;
    toSave.countEnabled = this.form.controls['showCount'].value;
    this.treeBranchService
      .updateDocumentTreeBranchUsingPUT(this.documentTreeBranch)
      .subscribe(() => {
        this.form.markAsPristine();
        this.treeBranchUpdated.emit(this.documentTreeBranch);
      });
  }

  private updateQuery(): Observable<QueryDTO> {
    return this.queryService
      .updateQueryUsingPUT(this.query)
      .pipe(tap((res) => (this.query = res)));
  }

  saveCondition(): Observable<any> {
    var getQueryRequest: Observable<QueryDTO>;
    if (this.query) {
      getQueryRequest = of(this.query);
    } else {
      getQueryRequest = this.queryService
        .getQueryUsingGET(this.documentTreeBranch.queryId)
        .pipe(tap((result) => (this.query = result)));
    }
    return getQueryRequest
      .pipe(
        switchMap(() => {
          return this.conditionAccess.save();
        })
      )
      .pipe(
        tap((root) => this.setCondition(root)),
        mergeMap(() => {
          if (!this.query.booleanOperatorId) {
            return this.updateQuery();
          }
          return of(true);
        })
      );
  }

  saveAttribute(): Observable<BooleanFunctionSideDTO> {
    var createRequest: Observable<BooleanFunctionSideDTO>;
    this.booleanFunctionSide.dateGranularity =
      this.form.controls['dateGranularity'].value;
    if (this.booleanFunctionSide.id) {
      createRequest =
        this.booleanFunctionSideService.updateBooleanFunctionSideUsingPUT(
          this.booleanFunctionSide
        );
    } else {
      createRequest =
        this.booleanFunctionSideService.createBooleanFunctionSideUsingPOST(
          this.booleanFunctionSide
        );
    }
    return createRequest.pipe(
      tap((result) => {
        this.documentTreeBranch.attributeId = result.id;
        this.setAttribute(result);
      })
    );
  }

  onFieldSelected(fieldId: FieldId) {
    this.fieldId = fieldId;
    this.booleanFunctionSide.complexDataTypeId = fieldId.complexDataTypeId;
    this.booleanFunctionSide.complexDataTypeFieldIds =
      fieldId.complexDataTypeFieldIds;
    this._documentTreeBranch.attributeId = this.booleanFunctionSide.id;
    this.setDateGranularityVisibility();
  }

  mapToFieldId(side: BooleanFunctionSideDTO): FieldId {
    return {
      complexDataTypeFieldIds: side?.complexDataTypeFieldIds,
      complexDataTypeId: side?.complexDataTypeId,
    };
  }

  onModeChange(event: MatButtonToggleChange) {
    this.clearForm();
    this.documentTreeBranch.mode = event.value;
    if (
      this.documentTreeBranch.mode == DocumentTreeBranchDTO.ModeEnum.CONDITION
    ) {
      if (!this.query) {
        this.getQuery(this.documentTreeBranch);
      } else {
        this.setCondition(this.booleanOperator);
      }
    }
    if (
      this.documentTreeBranch.mode == DocumentTreeBranchDTO.ModeEnum.ATTRIBUTE
    ) {
      if (!this.booleanFunctionSide) {
        this.getAttribute(this.documentTreeBranch);
      } else {
        this.setAttribute(this.booleanFunctionSide);
      }
    }
  }

  private clearForm() {
    this.attributeSet = false;
    this.conditionSet = false;
    this.form.removeControl('condition');
    this.form.removeControl('attribute');
    this.form.removeControl('attributesOrder');
    this.form.removeControl('dateGranularity');
  }

  private clearData() {
    this.sortAccess.clearState();
    this.attributeSet = false;
    this.conditionSet = false;
    this.query = undefined;
    this.booleanOperator = {};
    this.fieldId = {};
    this.booleanFunctionSide = undefined;
    this.granVisible = false;
    this.initEmptyForm();
  }

  private setAttribute(side: BooleanFunctionSideDTO) {
    this.booleanFunctionSide = side;
    this.fieldId = this.mapToFieldId(side);
    this.form.addControl('attribute', this.formBuilder.control(this.fieldId));
    this.form.controls['attribute'].setValidators([attributeValidator()]);
    this.form.controls['attribute'].valueChanges.subscribe(val => this.onFieldSelected(val))
    this.form.addControl(
      'dateGranularity',
      this.formBuilder.control(side.dateGranularity)
    );
    this.setDateGranularityVisibility();
    this.form.addControl(
      'attributesOrder',
      this.formBuilder.control(
        this.documentTreeBranch.attributeValuesOrder ??
        DocumentTreeBranchDTO.AttributeValuesOrderEnum.ASC
      )
    );
    this.form.controls['attributesOrder'].setValidators(Validators.required);
    this.form.controls['dateGranularity'].setValidators(Validators.required);
    this.attributeSet = true;
    this.form.controls['attribute'].updateValueAndValidity();
    this.form.controls['attributesOrder'].updateValueAndValidity();
  }

  private setCondition(operator: BooleanOperatorDTO) {
    this.booleanOperator = operator;
    this.conditionAccess.clearState();
    this.form.addControl(
      'condition',
      this.formBuilder.control({ booleanOperatorChild: operator })
    );
    this.form.controls['condition'].setAsyncValidators([
      conditionElementOkValidator(this.conditionAccess),
    ]);
    this.conditionSet = true;
    this.form.controls['condition'].updateValueAndValidity();
  }

  private setDateGranularityVisibility() {
    if (
      !this.booleanFunctionSide ||
      !this.booleanFunctionSide.complexDataTypeId ||
      !this.booleanFunctionSide.complexDataTypeFieldIds
    ) {
      return;
    }
    this.sortSchemaAccess
      .getTypeDescOfField(
        this.booleanFunctionSide.complexDataTypeId,
        this.booleanFunctionSide.complexDataTypeFieldIds
      )
      .subscribe((dataType) => {
        var type = dataType.type ?? dataType.enumDataTypeType;
        this.granVisible = type == DataTypeDesc.SimpleDataTypeEnum.DATE;
        if (this.granVisible) {
          this.form.controls['dateGranularity'].addValidators(
            Validators.required
          );
        } else {
          this.form.controls['dateGranularity'].removeValidators(
            Validators.required
          );
        }
        this.form.controls['dateGranularity'].updateValueAndValidity();
      });
  }
}
