<div fxFlexFill fxLayout="column">
  <div class="progress-bar-wrapper">
    <evo-progress-bar></evo-progress-bar>
  </div>
  <mat-toolbar
    *ngIf="isLogged"
    fxFlex="none"
    class="mat-elevation-z5 main-navbar"
    color="primary"
  >
    <div
      style="height: 100%; width: 100%"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="image-placeholder"
          [style.width.px]="logoWidth"
        >
          <img
            *ngIf="logoBase64"
            class="image"
            [src]="logoBase64 | base64Image"
            alt="{{ 'titles.logo' | translate }}"
          />
        </div>
        <span>{{ organisationDisplayName }}</span>
      </div>

      <div fxLayoutAlign="center center">
        <a mat-button href="/" style="font-size: 20px"
          ><span class="link">{{ "titles.app" | translate }}</span></a
        >
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <div div fxLayout="row" fxLayoutAlign="space-between center">
          <a mat-button [href]="userInfo.accountUrl" target="_blank"
            >{{ userInfo.userFullname }} {{ "single-words.in" | translate }}
          </a>
          <evo-group-selector [data]="groupData"></evo-group-selector>
        </div>
        <button
          mat-icon-button
          matTooltip="{{ 'buttons.' + configLabel | translate }}"
          (click)="navigateAdminOrUser()"
          *ngIf="hasAdminRights | async"
        >
          <mat-icon>{{ configIcon }}</mat-icon>
        </button>
        <button
          mat-icon-button
          color="accent"
          (click)="logout()"
          matTooltip="{{ 'buttons.logout' | translate }}"
        >
          <mat-icon>power_settings_new</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div fxFlex class="router-outlet" *ngIf="routingActive">
    <router-outlet></router-outlet>
  </div>
  <div fxFlex class="router-outlet" *ngIf="!routingActive && showGroupSelector">
    <evo-group-selector-page
      (no-group)="noGroupError()"
      (group-selected)="groupSelected($event)"
    ></evo-group-selector-page>
  </div>
  <div fxFlex class="router-outlet" *ngIf="!routingActive && showNoGroupError">
    <evo-no-group-error></evo-no-group-error>
  </div>
</div>
