/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BatchJob { 
    allowedConditionOnSchedule?: boolean;
    allowedResultsProcessing?: boolean;
    beforeImportReadonly?: boolean;
    displayName?: string;
    forProduction?: boolean;
    identifier?: string;
    removeAfterCompletion?: boolean;
    supportedTriggerTypes?: Array<BatchJob.SupportedTriggerTypesEnum>;
}
export namespace BatchJob {
    export type SupportedTriggerTypesEnum = 'SCHEDULE' | 'BEFORE_IMPORT' | 'AFTER_IMPORT' | 'AFTER_UPDATE';
    export const SupportedTriggerTypesEnum = {
        SCHEDULE: 'SCHEDULE' as SupportedTriggerTypesEnum,
        BEFOREIMPORT: 'BEFORE_IMPORT' as SupportedTriggerTypesEnum,
        AFTERIMPORT: 'AFTER_IMPORT' as SupportedTriggerTypesEnum,
        AFTERUPDATE: 'AFTER_UPDATE' as SupportedTriggerTypesEnum
    };
}
