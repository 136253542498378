import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'evo-text-with-label-editable',
  templateUrl: './text-with-label-editable.component.html',
  styleUrls: ['./text-with-label-editable.component.scss'],
})
export class TextWithLabelEditableComponent {
  public editMode: boolean = false;
  public _showOverlay = false;
  public valueValid = false;

  @Input('text')
  public set text(val: any) {
    this._text = val;
    this.setup();
  }

  public get text() {
    return this._text;
  }

  private _text: any;

  @Input('labels')
  public labels: string[] = [];

  private _type: string = 'STRING';

  public get type(): string {
    return this._type;
  }
  @Input('type')
  public set type(value: string) {
    this._type = value;
  }

  @Output('edited')
  public editedEvent: EventEmitter<any> = new EventEmitter();

  @Input('showEditedStatus')
  public set showEditedStatus(val: boolean) {
    if (val) {
      this._showOverlay = true;
      setTimeout(() => {
        this._showOverlay = false;
        this.editStatusShownEvent.emit();
      }, 1000);
    }
  }

  @Output('editStatusShown')
  public editStatusShownEvent: EventEmitter<void> = new EventEmitter();

  public inputValue: any = '';

  private setup() {
    this._showOverlay = false;
    this.inputValue = this._text;
    //this.editMode = false; //setting edit mode here causes, that date can not be edited. Why?
  }

  public edit(event: Event): void {
    event.stopPropagation();
    this.editMode = true;
    this.inputValue = this._text;
  }

  public save(event: Event): void {
    event.stopPropagation();
    this._text = this.inputValue;
    this.editedEvent.emit(this._text);
    this.editMode = false;
  }

  public cancelEdit(event: Event): void {
    event.stopPropagation();
    this.inputValue = this._text;
    this.editMode = false;
  }

  public update(value: string): void {
    this.inputValue = value;
  }

  public setValidity(value: boolean): void {
    this.valueValid = value;
  }
}
