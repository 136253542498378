import { CurrentUserGroupsDTO } from './../../dmssdk/model/currentUserGroupsDTO';
import { UsersGroupResourceService } from '../../dmssdk/api/usersGroupResource.service';
import { UsersGroupDTO } from '../../dmssdk/model/usersGroupDTO';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'evo-group-selector-page',
  templateUrl: './group-selector-page.component.html',
  styleUrls: ['./group-selector-page.component.scss'],
})
export class GroupSelectorPageComponent implements OnInit {
  public groups: UsersGroupDTO[] = [];

  @Output('no-group')
  public noGroupEvent: EventEmitter<void> = new EventEmitter();

  @Output('group-selected')
  public groupSelectedEvent: EventEmitter<CurrentUserGroupsDTO> = new EventEmitter();

  constructor(private usersGroupService: UsersGroupResourceService) {}

  ngOnInit(): void {
    this.usersGroupService
      .getAllEnabledGroupsOfCurrentUserAndDefaultGroupUsingGET()
      .subscribe((groups) => {
        this.groups = groups.groups ? groups.groups : [];
        if (this.groups.length === 0) {
          this.noGroupEvent.emit();
        }
      });
  }

  groupChange(value: any): void {
    const data: CurrentUserGroupsDTO = { groups: this.groups };
    this.usersGroupService
      .updateCurrentUserGroupUsingPATCH(value.value)
      .subscribe((val) => {
        data.currentGroup = val;
        this.groupSelectedEvent.emit(data);
        window.location.reload();
      });
  }
}
