import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { QuerySearchDTO } from 'src/app/dmssdk';

import { SearchConsistencyService } from 'src/app/services/search-consistency/search-consistency.service';
import { StateService } from 'src/app/shared/services/search-state/search-state.service';

@Component({
  selector: 'evo-query-editor',
  templateUrl: './query-editor.component.html',
  styleUrls: ['./query-editor.component.scss'],
})
export class QueryEditorComponent implements OnInit, OnDestroy {
  public pageSize: number;

  constructor(
    private searchConsistencyService: SearchConsistencyService,
    private stateService: StateService
  ) { }

  ngOnInit() {
    var state = this.stateService.getSearchPanelState();
    if (state) {
      this.restoreFromState(state);
    }
  }

  ngOnDestroy() {
    this.saveCurrentState();
  }

  @Input('pageSize')
  public set _pageSize(val: number) {
    this.pageSize = val;
  }

  @Output('searchClicked')
  public searchEvent: EventEmitter<QuerySearchDTO> = new EventEmitter();

  State = SearchPanelState;
  public panelState: SearchPanelState = SearchPanelState.SIMPLE;

  public searchedBeforeModeChange: boolean = false;

  search(query: QuerySearchDTO) {
    this.searchedBeforeModeChange = true;
    this.searchEvent.emit(query);
  }

  public changeMode() {
    this.switchPanelState(
      SearchPanelState.SIMPLE,
      SearchPanelState.ADVANCED_QUERY
    );
    if (this.searchedBeforeModeChange) {
      this.searchConsistencyService.setWarning('searching.inconsistent-state');
      this.searchedBeforeModeChange = false;
    }
  }

  public changeAdvancedMode() {
    this.switchPanelState(
      SearchPanelState.ADVANCED_CODE,
      SearchPanelState.ADVANCED_QUERY
    );
  }

  switchPanelState(
    firstState: SearchPanelState,
    secondState: SearchPanelState
  ) {
    this.panelState = this.panelState == firstState ? secondState : firstState;
  }

  private restoreFromState(state: SearchPanelState) {
    this.panelState = state;
  }

  private saveCurrentState() {
    this.stateService.setSearchPanelState(this.panelState);
  }
}

export enum SearchPanelState {
  SIMPLE,
  ADVANCED_QUERY,
  ADVANCED_CODE,
}
