/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Evaluates to any value Is a part of boolean function
 */
export interface BooleanFunctionSideDTO { 
    complexDataTypeFieldIds?: Array<number>;
    complexDataTypeId?: number;
    complexDataTypeName?: string;
    dateGranularity?: BooleanFunctionSideDTO.DateGranularityEnum;
    expressionTemplate?: string;
    fieldChosen?: boolean;
    id?: number;
    name?: string;
    organisationId?: number;
}
export namespace BooleanFunctionSideDTO {
    export type DateGranularityEnum = 'YEAR' | 'MONTH' | 'DAY' | 'HOUR' | 'MINUTE' | 'SECOND';
    export const DateGranularityEnum = {
        YEAR: 'YEAR' as DateGranularityEnum,
        MONTH: 'MONTH' as DateGranularityEnum,
        DAY: 'DAY' as DateGranularityEnum,
        HOUR: 'HOUR' as DateGranularityEnum,
        MINUTE: 'MINUTE' as DateGranularityEnum,
        SECOND: 'SECOND' as DateGranularityEnum
    };
}
