import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountResourceService } from './api/accountResource.service';
import { AppMigrationStepExecResourceService } from './api/appMigrationStepExecResource.service';
import { AuthInfoResourceService } from './api/authInfoResource.service';
import { BatchJobDescriptionResourceService } from './api/batchJobDescriptionResource.service';
import { BatchJobMonitoringResourceService } from './api/batchJobMonitoringResource.service';
import { BatchJobStepMonitoringResourceService } from './api/batchJobStepMonitoringResource.service';
import { BooleanExpressionResourceService } from './api/booleanExpressionResource.service';
import { BooleanFunctionResourceService } from './api/booleanFunctionResource.service';
import { BooleanFunctionSideResourceService } from './api/booleanFunctionSideResource.service';
import { BooleanOperatorResourceService } from './api/booleanOperatorResource.service';
import { ComplexDataTypeFieldResourceService } from './api/complexDataTypeFieldResource.service';
import { ComplexDataTypeResourceService } from './api/complexDataTypeResource.service';
import { DataDefinitionModuleResourceService } from './api/dataDefinitionModuleResource.service';
import { DocumentClassMappingElementResourceService } from './api/documentClassMappingElementResource.service';
import { DocumentClassResourceService } from './api/documentClassResource.service';
import { DocumentFieldMappingElementResourceService } from './api/documentFieldMappingElementResource.service';
import { DocumentFieldMappingResourceService } from './api/documentFieldMappingResource.service';
import { DocumentResourceService } from './api/documentResource.service';
import { DocumentShareResourceService } from './api/documentShareResource.service';
import { DocumentTreeBranchResourceService } from './api/documentTreeBranchResource.service';
import { DocumentsProcessingConfigurationResourceService } from './api/documentsProcessingConfigurationResource.service';
import { EmailServerPasswordResourceService } from './api/emailServerPasswordResource.service';
import { EmailServerResourceService } from './api/emailServerResource.service';
import { EnumDataTypeResourceService } from './api/enumDataTypeResource.service';
import { EnumDataTypeValueResourceService } from './api/enumDataTypeValueResource.service';
import { FieldValueAssignmentResourceService } from './api/fieldValueAssignmentResource.service';
import { KeycloakUserResourceService } from './api/keycloakUserResource.service';
import { LogoImageResourceService } from './api/logoImageResource.service';
import { LogoutResourceService } from './api/logoutResource.service';
import { OrganisationResourceService } from './api/organisationResource.service';
import { QueryResourceService } from './api/queryResource.service';
import { QuerySortResourceService } from './api/querySortResource.service';
import { StampResourceService } from './api/stampResource.service';
import { TriggerConfigurationResourceService } from './api/triggerConfigurationResource.service';
import { UserGroupLinkResourceService } from './api/userGroupLinkResource.service';
import { UserProfileResourceService } from './api/userProfileResource.service';
import { UserResourceService } from './api/userResource.service';
import { UsersGroupResourceService } from './api/usersGroupResource.service';
import { VantageDailyUsageResourceService } from './api/vantageDailyUsageResource.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountResourceService,
    AppMigrationStepExecResourceService,
    AuthInfoResourceService,
    BatchJobDescriptionResourceService,
    BatchJobMonitoringResourceService,
    BatchJobStepMonitoringResourceService,
    BooleanExpressionResourceService,
    BooleanFunctionResourceService,
    BooleanFunctionSideResourceService,
    BooleanOperatorResourceService,
    ComplexDataTypeFieldResourceService,
    ComplexDataTypeResourceService,
    DataDefinitionModuleResourceService,
    DocumentClassMappingElementResourceService,
    DocumentClassResourceService,
    DocumentFieldMappingElementResourceService,
    DocumentFieldMappingResourceService,
    DocumentResourceService,
    DocumentShareResourceService,
    DocumentTreeBranchResourceService,
    DocumentsProcessingConfigurationResourceService,
    EmailServerPasswordResourceService,
    EmailServerResourceService,
    EnumDataTypeResourceService,
    EnumDataTypeValueResourceService,
    FieldValueAssignmentResourceService,
    KeycloakUserResourceService,
    LogoImageResourceService,
    LogoutResourceService,
    OrganisationResourceService,
    QueryResourceService,
    QuerySortResourceService,
    StampResourceService,
    TriggerConfigurationResourceService,
    UserGroupLinkResourceService,
    UserProfileResourceService,
    UserResourceService,
    UsersGroupResourceService,
    VantageDailyUsageResourceService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
