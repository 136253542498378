/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ComplexDataTypeDTO } from '../model/complexDataTypeDTO';
import { ComplexDataTypeFieldDTO } from '../model/complexDataTypeFieldDTO';
import { ComplexFieldWrapperDTO } from '../model/complexFieldWrapperDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ComplexDataTypeResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createComplexDataType
     * 
     * @param complexDataTypeDTO complexDataTypeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createComplexDataTypeUsingPOST(complexDataTypeDTO: ComplexDataTypeDTO, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeDTO>;
    public createComplexDataTypeUsingPOST(complexDataTypeDTO: ComplexDataTypeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeDTO>>;
    public createComplexDataTypeUsingPOST(complexDataTypeDTO: ComplexDataTypeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeDTO>>;
    public createComplexDataTypeUsingPOST(complexDataTypeDTO: ComplexDataTypeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (complexDataTypeDTO === null || complexDataTypeDTO === undefined) {
            throw new Error('Required parameter complexDataTypeDTO was null or undefined when calling createComplexDataTypeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ComplexDataTypeDTO>(`${this.basePath}/api/complex-data-types`,
            complexDataTypeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteComplexDataType
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteComplexDataTypeUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteComplexDataTypeUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteComplexDataTypeUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteComplexDataTypeUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteComplexDataTypeUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/complex-data-types/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getActiveComplexDataType
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveComplexDataTypeUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeDTO>;
    public getActiveComplexDataTypeUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeDTO>>;
    public getActiveComplexDataTypeUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeDTO>>;
    public getActiveComplexDataTypeUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getActiveComplexDataTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ComplexDataTypeDTO>(`${this.basePath}/api/complex-data-types/active/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllActiveAttributesOfComplexDataType
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActiveAttributesOfComplexDataTypeUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplexFieldWrapperDTO>>;
    public getAllActiveAttributesOfComplexDataTypeUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplexFieldWrapperDTO>>>;
    public getAllActiveAttributesOfComplexDataTypeUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplexFieldWrapperDTO>>>;
    public getAllActiveAttributesOfComplexDataTypeUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAllActiveAttributesOfComplexDataTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ComplexFieldWrapperDTO>>(`${this.basePath}/api/complex-data-types/active/${encodeURIComponent(String(id))}/attributes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllActiveComplexDataTypeFields
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActiveComplexDataTypeFieldsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplexDataTypeFieldDTO>>;
    public getAllActiveComplexDataTypeFieldsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplexDataTypeFieldDTO>>>;
    public getAllActiveComplexDataTypeFieldsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplexDataTypeFieldDTO>>>;
    public getAllActiveComplexDataTypeFieldsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAllActiveComplexDataTypeFieldsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ComplexDataTypeFieldDTO>>(`${this.basePath}/api/complex-data-types/active/${encodeURIComponent(String(id))}/fields`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllActiveTypesAsClassTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActiveTypesAsClassTypesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<ComplexDataTypeDTO>>;
    public getAllActiveTypesAsClassTypesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplexDataTypeDTO>>>;
    public getAllActiveTypesAsClassTypesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplexDataTypeDTO>>>;
    public getAllActiveTypesAsClassTypesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ComplexDataTypeDTO>>(`${this.basePath}/api/complex-data-types/active/class-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllComplexDataTypeFields
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllComplexDataTypeFieldsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplexDataTypeFieldDTO>>;
    public getAllComplexDataTypeFieldsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplexDataTypeFieldDTO>>>;
    public getAllComplexDataTypeFieldsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplexDataTypeFieldDTO>>>;
    public getAllComplexDataTypeFieldsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAllComplexDataTypeFieldsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ComplexDataTypeFieldDTO>>(`${this.basePath}/api/complex-data-types/${encodeURIComponent(String(id))}/fields`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllComplexDataTypesByModule
     * 
     * @param moduleId moduleId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllComplexDataTypesByModuleUsingGET(moduleId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ComplexDataTypeDTO>>;
    public getAllComplexDataTypesByModuleUsingGET(moduleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ComplexDataTypeDTO>>>;
    public getAllComplexDataTypesByModuleUsingGET(moduleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ComplexDataTypeDTO>>>;
    public getAllComplexDataTypesByModuleUsingGET(moduleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getAllComplexDataTypesByModuleUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ComplexDataTypeDTO>>(`${this.basePath}/api/complex-data-types/modules/${encodeURIComponent(String(moduleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getComplexDataType
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComplexDataTypeUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeDTO>;
    public getComplexDataTypeUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeDTO>>;
    public getComplexDataTypeUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeDTO>>;
    public getComplexDataTypeUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getComplexDataTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ComplexDataTypeDTO>(`${this.basePath}/api/complex-data-types/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getFirstLevelDocSystemFieldsType
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFirstLevelDocSystemFieldsTypeUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeDTO>;
    public getFirstLevelDocSystemFieldsTypeUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeDTO>>;
    public getFirstLevelDocSystemFieldsTypeUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeDTO>>;
    public getFirstLevelDocSystemFieldsTypeUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ComplexDataTypeDTO>(`${this.basePath}/api/complex-data-types/active/base-system-type`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateComplexDataType
     * 
     * @param complexDataTypeDTO complexDataTypeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateComplexDataTypeUsingPUT(complexDataTypeDTO: ComplexDataTypeDTO, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeDTO>;
    public updateComplexDataTypeUsingPUT(complexDataTypeDTO: ComplexDataTypeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeDTO>>;
    public updateComplexDataTypeUsingPUT(complexDataTypeDTO: ComplexDataTypeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeDTO>>;
    public updateComplexDataTypeUsingPUT(complexDataTypeDTO: ComplexDataTypeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (complexDataTypeDTO === null || complexDataTypeDTO === undefined) {
            throw new Error('Required parameter complexDataTypeDTO was null or undefined when calling updateComplexDataTypeUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ComplexDataTypeDTO>(`${this.basePath}/api/complex-data-types`,
            complexDataTypeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
