/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PathWrapper { 
    attributeName?: string;
    lastFieldDateGranularity?: PathWrapper.LastFieldDateGranularityEnum;
    lastFieldName?: string;
    lastFieldType?: PathWrapper.LastFieldTypeEnum;
    nestedPath?: string;
}
export namespace PathWrapper {
    export type LastFieldDateGranularityEnum = 'YEAR' | 'MONTH' | 'DAY' | 'HOUR' | 'MINUTE' | 'SECOND';
    export const LastFieldDateGranularityEnum = {
        YEAR: 'YEAR' as LastFieldDateGranularityEnum,
        MONTH: 'MONTH' as LastFieldDateGranularityEnum,
        DAY: 'DAY' as LastFieldDateGranularityEnum,
        HOUR: 'HOUR' as LastFieldDateGranularityEnum,
        MINUTE: 'MINUTE' as LastFieldDateGranularityEnum,
        SECOND: 'SECOND' as LastFieldDateGranularityEnum
    };
    export type LastFieldTypeEnum = 'STRING' | 'DATE' | 'INTEGER' | 'FLOAT' | 'BOOLEAN';
    export const LastFieldTypeEnum = {
        STRING: 'STRING' as LastFieldTypeEnum,
        DATE: 'DATE' as LastFieldTypeEnum,
        INTEGER: 'INTEGER' as LastFieldTypeEnum,
        FLOAT: 'FLOAT' as LastFieldTypeEnum,
        BOOLEAN: 'BOOLEAN' as LastFieldTypeEnum
    };
}
