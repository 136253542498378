import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './login.service';
import { ProgressBarListener } from 'src/app/shared/components/progress-bar/progress-bar.listener';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationHttpInterceptor implements HttpInterceptor {
  public static urlsToIgnore: ErrToIgnore[] = [
    { path: 'user-group-links/current', status: 404, method: 'GET' },
  ];

  public static toIgnore(
    err: HttpErrorResponse,
    request: HttpRequest<any>
  ): boolean {
    return (
      AuthenticationHttpInterceptor.urlsToIgnore.filter(
        (it) =>
          (err.error.path as string).includes(it.path) &&
          err.error.status === it.status &&
          it.method === request.method
      ).length > 0
    );
  }

  constructor(
    private loginService: LoginService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private progressBarListener: ProgressBarListener
  ) {}

  private tokenIssuerUrlPath: string = '/tokenIssuerUrl';

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.progressBarListener.increase();
    return from(
      this.addToken(req).then((requestWithToken) =>
        this.callHttp(requestWithToken, next)
      )
    ).pipe(
      finalize(() => {
        this.progressBarListener.decrease();
      })
    );
  }

  addToken(req: HttpRequest<any>): Promise<HttpRequest<any>> {
    if (
      req.url.includes(this.tokenIssuerUrlPath) ||
      !req.url.includes('/api')
    ) {
      return of(req).toPromise();
    }
    return this.loginService.getToken().then((token) => {
      this.translate.use(this.getLocaleFromToken(token));
      return req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
    });
  }

  callHttp(
    requestWithToken: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    return next
      .handle(requestWithToken)
      .pipe(
        tap({
          error: (err: any) => {
            this.handleError(err, requestWithToken);
          },
        })
      )
      .toPromise();
  }

  handleError(error: any, request: HttpRequest<any>) {
    if (error instanceof HttpErrorResponse) {
      if (AuthenticationHttpInterceptor.toIgnore(error, request)) {
        return throwError(error);
      }
      const message = error.error.title + '. ' + error.error.message;
      if (error.status >= 400 && error.status < 500) {
        this.checkClientErrors(error, request, message);
      } else if (error.status >= 500 && error.status < 600) {
        this.openSnackBar(this.translateErrorCode(error), 'OK', 5000);
      } else {
        console.log(message);
      }
    } else {
      this.openSnackBar('Something went wrong!', 'OK', 6000);
    }
    return throwError(error);
  }

  private checkClientErrors(
    error: any,
    request: HttpRequest<any>,
    message: string
  ) {
    if (error.status === 401) {
      console.log('You are unauthorized to this resource!');
    }
    if (!request.url.includes(this.tokenIssuerUrlPath)) {
      this.openSnackBar(this.translateErrorCode(error), 'OK');
    }
  }

  openSnackBar(message: string, action: string, duration?: number) {
    if (!duration) {
      duration = 3000;
    }
    this.snackBar.open(message, action, {
      duration,
    });
  }

  private getLocaleFromToken(token: string) {
    var tokenAsString = atob(token.split('.')[1]);
    var tokenObj = JSON.parse(tokenAsString);
    return tokenObj.locale;
  }

  private translateErrorCode(error: any) {
    return (
      this.translate.instant(error.error.title, error.error.parameters) +
      '. ' +
      this.translate.instant(error.error.message, error.error.parameters)
    );
  }
}

export interface ErrToIgnore {
  path: string;
  status: number;
  method: string;
}
