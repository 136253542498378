/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DocumentFieldMappingDTO { 
    fieldToMapId?: number;
    fieldToMapName?: string;
    id?: number;
    noMatchingMappingStrategy?: DocumentFieldMappingDTO.NoMatchingMappingStrategyEnum;
    organisationId?: number;
    organisationName?: string;
}
export namespace DocumentFieldMappingDTO {
    export type NoMatchingMappingStrategyEnum = 'KEEP_INPUT_VALUE' | 'RESET_VALUE' | 'REMOVE_FIELD' | 'THROW_ERROR';
    export const NoMatchingMappingStrategyEnum = {
        KEEPINPUTVALUE: 'KEEP_INPUT_VALUE' as NoMatchingMappingStrategyEnum,
        RESETVALUE: 'RESET_VALUE' as NoMatchingMappingStrategyEnum,
        REMOVEFIELD: 'REMOVE_FIELD' as NoMatchingMappingStrategyEnum,
        THROWERROR: 'THROW_ERROR' as NoMatchingMappingStrategyEnum
    };
}
