/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { QuerySearchDTO } from './querySearchDTO';
import { TreeBranchNamedValueDTO } from './treeBranchNamedValueDTO';


export interface UserDefinedConditionsDTO { 
    attributeBranches?: Array<TreeBranchNamedValueDTO>;
    branchId?: number;
    conditionMode?: UserDefinedConditionsDTO.ConditionModeEnum;
    id?: number;
    querySearch?: QuerySearchDTO;
    simpleSearchPhrase?: string;
}
export namespace UserDefinedConditionsDTO {
    export type ConditionModeEnum = 'SIMPLE' | 'ADVANCED';
    export const ConditionModeEnum = {
        SIMPLE: 'SIMPLE' as ConditionModeEnum,
        ADVANCED: 'ADVANCED' as ConditionModeEnum
    };
}
