import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { DateFormatService } from '../../services/date-format.service';

@Component({
  selector: 'evo-text-with-label',
  templateUrl: './text-with-label.component.html',
  styleUrls: ['./text-with-label.component.scss'],
})
export class TextWithLabelComponent {
  private currentLocale: string = '';

  constructor(
    private dateFormatService: DateFormatService,
    private decimalPipe: DecimalPipe,
    private loginService: LoginService
  ) {
    this.loginService
      .getCurrentLocale()
      .subscribe((res) => (this.currentLocale = res));
  }

  private _type: string;

  public get type(): string {
    return this._type;
  }

  @Input('type')
  public set type(value: string) {
    this._type = value;
    this.normaliseValue();
  }

  private _text: any;

  public get text(): any {
    return this._text;
  }

  @Input('text')
  public set text(value: any) {
    this._text = value;
    this.normaliseValue();
  }

  private normaliseValue() {
    if (this._text && this._type == 'DATE') {
      this._text = this.dateFormatService.formatDateFromEpochSeconds(
        this._text
      );
    }
    if (this._text && this._type == 'DOUBLE') {
      this._text = this.decimalPipe.transform(
        this._text,
        '1.0-50',
        this.currentLocale
      );
    }
  }

  @Input('labels')
  public labels: string[] = [];
}
