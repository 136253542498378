/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BatchJobDescriptionDTO } from './batchJobDescriptionDTO';


export interface BatchJobDescriptionWithTriggerTypesDTO { 
    batchJobDescription?: BatchJobDescriptionDTO;
    id?: number;
    organisationId?: number;
    triggerTypes?: Array<BatchJobDescriptionWithTriggerTypesDTO.TriggerTypesEnum>;
}
export namespace BatchJobDescriptionWithTriggerTypesDTO {
    export type TriggerTypesEnum = 'SCHEDULE' | 'BEFORE_IMPORT' | 'AFTER_IMPORT' | 'AFTER_UPDATE';
    export const TriggerTypesEnum = {
        SCHEDULE: 'SCHEDULE' as TriggerTypesEnum,
        BEFOREIMPORT: 'BEFORE_IMPORT' as TriggerTypesEnum,
        AFTERIMPORT: 'AFTER_IMPORT' as TriggerTypesEnum,
        AFTERUPDATE: 'AFTER_UPDATE' as TriggerTypesEnum
    };
}
