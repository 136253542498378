import { Injectable } from '@angular/core';
import { AbstractConditionAccess } from '@misc/angular-components';
import {
  BooleanFunction,
  ConditionElement,
  ConditionRoot,
} from '@misc/angular-components/lib/condition-editor/model/data';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  BooleanFunctionSideDTO,
  BooleanOperatorResourceService,
  ConditionDTO,
  ConditionElementDTO,
  ConditionFunctionDTO,
  ConditionToSaveDTO,
} from 'src/app/dmssdk';

@Injectable({
  providedIn: 'root',
})
export class ConditionAccessDbService extends AbstractConditionAccess {

  constructor(private booleanOperatorService: BooleanOperatorResourceService) {
    super();
  }

  loadData(root: ConditionRoot): Observable<ConditionElement[]> {
    if (root.id) {
      return this.booleanOperatorService
        .getBooleanOperatorAsConditionUsingGET(root.id)
        .pipe(
          map((res) =>
            res.elements
              .sort((a, b) => a.id - b.id)
              .map((el) => this.mapToConditionElement(el))
          )
        );
    }
    return of([]);
  }

  save(): Observable<ConditionRoot> {
    var conditionDto: ConditionDTO = this.createCondition(this.root);
    var conditionToSave: ConditionToSaveDTO = {
      conditionDTO: conditionDto,
      elementsToRemove: this.deletedElements,
    };
    return this.booleanOperatorService
      .saveWholeConditionUsingPOST(conditionToSave)
      .pipe(
        tap((result) => {
          this.clearState();
        })
      );
  }

  private mapToConditionElement(value: ConditionElementDTO): ConditionElement {
    let conditionElement: ConditionElement = {
      id: value.root.id,
      booleanOperatorChild: value?.condition?.root,
      booleanFunction: this.mapToBooleanFunction(value.functionDTO),
    };
    if (conditionElement.booleanOperatorChild) {
      this.setElementsOfCondition(
        conditionElement.booleanOperatorChild.id,
        value.condition.elements
          .sort((a, b) => a.id - b.id)
          .map((el) => this.mapToConditionElement(el))
      );
    }
    return conditionElement;
  }

  private mapToBooleanFunction(value: ConditionFunctionDTO): BooleanFunction {
    if (!value) {
      return undefined;
    }
    let booleanFunction: BooleanFunction = {
      id: value.root.id,
      leftSide: {
        ...{ fieldChosen: false /*, type: value.leftSide.attributeType*/ },
        ...value.leftSide,
      },
      rightSide: {
        ...{ fieldChosen: false, /*type: value.rightSide?.attributeType*/ },
        ...value.rightSide,
      },
      functionName: value.functionName,
    };
    return booleanFunction;
  }

  /*GUI to DB*/
  private createCondition(conditionRoot: ConditionRoot): ConditionDTO {
    if (!conditionRoot) {
      return undefined;
    }
    var result: ConditionDTO = {};
    result.root = conditionRoot;
    var elements: ConditionElement[] =
      this.rootsMap[conditionRoot.id ?? conditionRoot.internalId] ?? this.rootsMap[conditionRoot.internalId];
    //if (elements) {
    result.elements = elements.map((elem) =>
      this.mapToConditionElementBack(elem)
    );
    //} else {
    //  result.elements = [];
    //}
    return result;
  }

  private mapToConditionElementBack(
    conditionElement: ConditionElement
  ): ConditionElementDTO {
    let result: ConditionElementDTO = {
      id: conditionElement.id,
      root: { id: conditionElement.id },
      functionDTO: conditionElement.booleanFunction,
      condition: this.createCondition(conditionElement.booleanOperatorChild),
    };
    return result;
  }
}
