/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface OrganisationDTO { 
    adminEmail?: string;
    defaultLanguage?: OrganisationDTO.DefaultLanguageEnum;
    displayName?: string;
    id?: number;
    indexName?: string;
    isEnabled?: boolean;
    logoImageId?: number;
    name?: string;
    rootGroupId?: number;
    tokenIssuerUrl?: string;
    uid?: string;
}
export namespace OrganisationDTO {
    export type DefaultLanguageEnum = 'EN' | 'PL' | 'DE';
    export const DefaultLanguageEnum = {
        EN: 'EN' as DefaultLanguageEnum,
        PL: 'PL' as DefaultLanguageEnum,
        DE: 'DE' as DefaultLanguageEnum
    };
}
