/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface QuerySortDTO { 
    applyingOrder?: number;
    complexDataTypeFieldIds?: Array<number>;
    complexDataTypeId?: number;
    complexDataTypeName?: string;
    id?: number;
    organisationId?: number;
    queryId?: number;
    queryName?: string;
    sortingOrder?: QuerySortDTO.SortingOrderEnum;
}
export namespace QuerySortDTO {
    export type SortingOrderEnum = 'DESC' | 'ASC';
    export const SortingOrderEnum = {
        DESC: 'DESC' as SortingOrderEnum,
        ASC: 'ASC' as SortingOrderEnum
    };
}
