/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TriggerConfigurationDTO { 
    batchJobDescriptionId?: number;
    batchJobDescriptionName?: string;
    conditionId?: number;
    cronExpression?: string;
    id?: number;
    organisationId?: number;
    triggerType?: TriggerConfigurationDTO.TriggerTypeEnum;
}
export namespace TriggerConfigurationDTO {
    export type TriggerTypeEnum = 'SCHEDULE' | 'BEFORE_IMPORT' | 'AFTER_IMPORT' | 'AFTER_UPDATE';
    export const TriggerTypeEnum = {
        SCHEDULE: 'SCHEDULE' as TriggerTypeEnum,
        BEFOREIMPORT: 'BEFORE_IMPORT' as TriggerTypeEnum,
        AFTERIMPORT: 'AFTER_IMPORT' as TriggerTypeEnum,
        AFTERUPDATE: 'AFTER_UPDATE' as TriggerTypeEnum
    };
}
