/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BooleanFunctionDTO } from './booleanFunctionDTO';
import { BooleanFunctionSideDTO } from './booleanFunctionSideDTO';
import { ComplexDataTypeDTO } from './complexDataTypeDTO';


export interface ConditionFunctionDTO { 
    complexType?: ComplexDataTypeDTO;
    functionName?: ConditionFunctionDTO.FunctionNameEnum;
    id?: number;
    leftSide?: BooleanFunctionSideDTO;
    rightSide?: BooleanFunctionSideDTO;
    root?: BooleanFunctionDTO;
}
export namespace ConditionFunctionDTO {
    export type FunctionNameEnum = 'EQUALS' | 'ISEMPTY' | 'BEGINS' | 'ENDS' | 'CONTAINS' | 'ISBIGGER' | 'ISSMALLER' | 'ISBIGGERE' | 'ISSMALLERE' | 'FUZZY' | 'CONTAINSSIMILAR' | 'CONTAINSREGEXP' | 'MATCHPHRASE' | 'EXISTS' | 'WILDCARD';
    export const FunctionNameEnum = {
        EQUALS: 'EQUALS' as FunctionNameEnum,
        ISEMPTY: 'ISEMPTY' as FunctionNameEnum,
        BEGINS: 'BEGINS' as FunctionNameEnum,
        ENDS: 'ENDS' as FunctionNameEnum,
        CONTAINS: 'CONTAINS' as FunctionNameEnum,
        ISBIGGER: 'ISBIGGER' as FunctionNameEnum,
        ISSMALLER: 'ISSMALLER' as FunctionNameEnum,
        ISBIGGERE: 'ISBIGGERE' as FunctionNameEnum,
        ISSMALLERE: 'ISSMALLERE' as FunctionNameEnum,
        FUZZY: 'FUZZY' as FunctionNameEnum,
        CONTAINSSIMILAR: 'CONTAINSSIMILAR' as FunctionNameEnum,
        CONTAINSREGEXP: 'CONTAINSREGEXP' as FunctionNameEnum,
        MATCHPHRASE: 'MATCHPHRASE' as FunctionNameEnum,
        EXISTS: 'EXISTS' as FunctionNameEnum,
        WILDCARD: 'WILDCARD' as FunctionNameEnum
    };
}
