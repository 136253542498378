/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ComplexDataTypeFieldDTO } from '../model/complexDataTypeFieldDTO';
import { EnumValuesResponse } from '../model/enumValuesResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ComplexDataTypeFieldResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createComplexDataTypeField
     * 
     * @param complexDataTypeFieldDTO complexDataTypeFieldDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createComplexDataTypeFieldUsingPOST(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeFieldDTO>;
    public createComplexDataTypeFieldUsingPOST(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeFieldDTO>>;
    public createComplexDataTypeFieldUsingPOST(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeFieldDTO>>;
    public createComplexDataTypeFieldUsingPOST(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (complexDataTypeFieldDTO === null || complexDataTypeFieldDTO === undefined) {
            throw new Error('Required parameter complexDataTypeFieldDTO was null or undefined when calling createComplexDataTypeFieldUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ComplexDataTypeFieldDTO>(`${this.basePath}/api/complex-data-type-fields`,
            complexDataTypeFieldDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteComplexDataTypeField
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteComplexDataTypeFieldUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteComplexDataTypeFieldUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteComplexDataTypeFieldUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteComplexDataTypeFieldUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteComplexDataTypeFieldUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/complex-data-type-fields/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchEnumValuesForGroupConditionMaker
     * 
     * @param fieldsPath fieldsPath
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchEnumValuesForGroupConditionMakerUsingPOST(fieldsPath: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<EnumValuesResponse>;
    public fetchEnumValuesForGroupConditionMakerUsingPOST(fieldsPath: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnumValuesResponse>>;
    public fetchEnumValuesForGroupConditionMakerUsingPOST(fieldsPath: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnumValuesResponse>>;
    public fetchEnumValuesForGroupConditionMakerUsingPOST(fieldsPath: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fieldsPath === null || fieldsPath === undefined) {
            throw new Error('Required parameter fieldsPath was null or undefined when calling fetchEnumValuesForGroupConditionMakerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EnumValuesResponse>(`${this.basePath}/api/complex-data-type-fields/group-condition-maker-enum-values`,
            fieldsPath,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchEnumValues
     * 
     * @param fieldsPath fieldsPath
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchEnumValuesUsingPOST(fieldsPath: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<EnumValuesResponse>;
    public fetchEnumValuesUsingPOST(fieldsPath: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnumValuesResponse>>;
    public fetchEnumValuesUsingPOST(fieldsPath: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnumValuesResponse>>;
    public fetchEnumValuesUsingPOST(fieldsPath: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fieldsPath === null || fieldsPath === undefined) {
            throw new Error('Required parameter fieldsPath was null or undefined when calling fetchEnumValuesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EnumValuesResponse>(`${this.basePath}/api/complex-data-type-fields/enum-values`,
            fieldsPath,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getComplexDataTypeField
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComplexDataTypeFieldUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeFieldDTO>;
    public getComplexDataTypeFieldUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeFieldDTO>>;
    public getComplexDataTypeFieldUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeFieldDTO>>;
    public getComplexDataTypeFieldUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getComplexDataTypeFieldUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ComplexDataTypeFieldDTO>(`${this.basePath}/api/complex-data-type-fields/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateComplexDataTypeField
     * 
     * @param complexDataTypeFieldDTO complexDataTypeFieldDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateComplexDataTypeFieldUsingPUT(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe?: 'body', reportProgress?: boolean): Observable<ComplexDataTypeFieldDTO>;
    public updateComplexDataTypeFieldUsingPUT(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ComplexDataTypeFieldDTO>>;
    public updateComplexDataTypeFieldUsingPUT(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ComplexDataTypeFieldDTO>>;
    public updateComplexDataTypeFieldUsingPUT(complexDataTypeFieldDTO: ComplexDataTypeFieldDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (complexDataTypeFieldDTO === null || complexDataTypeFieldDTO === undefined) {
            throw new Error('Required parameter complexDataTypeFieldDTO was null or undefined when calling updateComplexDataTypeFieldUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ComplexDataTypeFieldDTO>(`${this.basePath}/api/complex-data-type-fields`,
            complexDataTypeFieldDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
