import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'conditionformat',
})
export class ConditionFormattingPipe implements PipeTransform {
  private colors: string[] = ['red', 'green', 'blue', 'yellow', 'magenta'];

  constructor(private sanitizer: DomSanitizer) {}

  transform(toTransform: string): SafeHtml {
    if (toTransform) {
      toTransform += ' ';
      let found = true;
      let currentSubstring = toTransform;
      let currentIndex = 0;
      let result = '';
      while (found) {
        if (currentSubstring) {
          let openBracketPos = currentSubstring.search('\\(');
          let closingBracketPos = currentSubstring.search('\\)');
          if (openBracketPos == closingBracketPos) {
            result = result + currentSubstring;
            found = false;
          } else if (
            openBracketPos != -1 &&
            openBracketPos < closingBracketPos
          ) {
            let splitted = currentSubstring.split(/\((.+)/);
            result =
              result +
              splitted[0] +
              '<span style="color:' +
              this.colors[currentIndex % this.colors.length] +
              '">(</span>';
            currentSubstring = splitted[1];
            currentIndex++;
          } else {
            currentIndex--;
            let splitted = currentSubstring.split(/\)(.+)/);
            result =
              result +
              splitted[0] +
              '<span style="color:' +
              this.colors[currentIndex % this.colors.length] +
              '">)</span>';
            currentSubstring = splitted[1];
          }
        } else {
          found = false;
        }
      }

      result = '<b>' + result.trim() + '</b>';
      return this.sanitizer.bypassSecurityTrustHtml(result);
    }
    return toTransform;
  }
}
