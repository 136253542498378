/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DataDefinitionModuleDTO { 
    active?: boolean;
    customId: string;
    editable?: boolean;
    id?: number;
    name?: string;
    organisationId?: number;
    organisationName?: string;
}
