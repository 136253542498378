import { Injectable } from '@angular/core';
import { SearchPanelState } from '../../components/query-editor/query-editor/query-editor.component';
import { DocumentsCollectionState } from './model/documents-collection-state';
import { AdvancedFilteringPanelState } from './model/advanced-filtering-panel-state';
import { UserDefinedConditionsDTO } from 'src/app/dmssdk';
import { SimplePanelState } from './model/simple-panel-state';
import { UserSearchStateService } from 'src/app/services/user-search-state/user-search-state.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private documentsCollectionState: DocumentsCollectionState;
  private advancedPanelState: AdvancedFilteringPanelState;
  private simplePanelState: SimplePanelState;
  private documentsTreeState: any;
  private searchPanelState: SearchPanelState;

  constructor(private userSearchStateService: UserSearchStateService) {}

  getDocumentCollectionState() {
    return this.documentsCollectionState;
  }

  setDocumentsCollectionState(
    documentsCollectionState: DocumentsCollectionState
  ): void {
    this.documentsCollectionState = documentsCollectionState;
  }

  getDocumentTreeState(): any {
    return this.documentsTreeState;
  }

  setDocumentsTreeState(documentsTreeState: any): void {
    this.documentsTreeState = documentsTreeState;
  }

  getAdvancedFilteringState(): AdvancedFilteringPanelState {
    return this.advancedPanelState;
  }

  setAdvancedFilteringState(panelState: AdvancedFilteringPanelState): void {
    this.advancedPanelState = panelState;
    let cond: UserDefinedConditionsDTO = {
      querySearch: panelState.querySearch,
      conditionMode: UserDefinedConditionsDTO.ConditionModeEnum.ADVANCED,
    };
    this.userSearchStateService.updateEditorContext(
      cond,
      panelState.querySearchValid
    );
    this.userSearchStateService.emitConditionAndValiditySubject();
  }

  getSimplePanelState(): SimplePanelState {
    return this.simplePanelState;
  }

  setSimplePanelState(panelState: SimplePanelState): void {
    this.simplePanelState = panelState;
    this.userSearchStateService.updateEditorContext(
      {
        ...panelState,
        conditionMode: UserDefinedConditionsDTO.ConditionModeEnum.SIMPLE,
      },
      true
    );
    this.userSearchStateService.emitConditionAndValiditySubject();
  }

  getSearchPanelState(): SearchPanelState {
    return this.searchPanelState;
  }

  setSearchPanelState(searchPanelState: SearchPanelState): void {
    this.searchPanelState = searchPanelState;
  }
}
