import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'evo-stamp-position-selector',
  templateUrl: './stamp-position-selector.component.html',
  styleUrls: ['./stamp-position-selector.component.scss']
})
export class StampPositionSelectorComponent implements OnInit {

  @Input('rows')
  rows: number;

  @Input('columns')
  columns: number;

  @Input('currentSelectedId')
  currentSelectedId: number;

  @Output()
  cellSelected = new EventEmitter();

  rowIds: number[] = [];
  columnIds: number[] = [];

  ngOnInit(): void {
    this.rowIds = this.getNumArrayFromInput(this.rows);
    this.columnIds = this.getNumArrayFromInput(this.columns);
  }

  getCellId(rowId: number, columnId: number): number {
    return rowId === 0 ? columnId + 1 : this.columns * rowId + columnId + 1;
  }

  getNumArrayFromInput(input: number): number[] {
    let result: number[] = [];
    for (let i = 0; i < input; i++) {
      result.push(i);
    }
    return result;
  }

  selectCell(rowId: number, columnId: number) {
    let cellId: number = this.getCellId(rowId, columnId);
    this.currentSelectedId = cellId;
    this.cellSelected.emit(this.currentSelectedId);
  }
}
