/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BooleanOperatorDTO } from '../model/booleanOperatorDTO';
import { CurrentUserGroupsDTO } from '../model/currentUserGroupsDTO';
import { UserProfileDTO } from '../model/userProfileDTO';
import { UsersGroupDTO } from '../model/usersGroupDTO';
import { UsersGroupSelectorDetailsDTO } from '../model/usersGroupSelectorDetailsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersGroupResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createUsersGroup
     * 
     * @param usersGroupDTO usersGroupDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUsersGroupUsingPOST(usersGroupDTO: UsersGroupDTO, observe?: 'body', reportProgress?: boolean): Observable<UsersGroupDTO>;
    public createUsersGroupUsingPOST(usersGroupDTO: UsersGroupDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersGroupDTO>>;
    public createUsersGroupUsingPOST(usersGroupDTO: UsersGroupDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersGroupDTO>>;
    public createUsersGroupUsingPOST(usersGroupDTO: UsersGroupDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (usersGroupDTO === null || usersGroupDTO === undefined) {
            throw new Error('Required parameter usersGroupDTO was null or undefined when calling createUsersGroupUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UsersGroupDTO>(`${this.basePath}/api/users-groups`,
            usersGroupDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteUsersGroup
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsersGroupUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUsersGroupUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUsersGroupUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUsersGroupUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUsersGroupUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/users-groups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllCurrentGroupUserProfiles
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCurrentGroupUserProfilesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<UserProfileDTO>>;
    public getAllCurrentGroupUserProfilesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserProfileDTO>>>;
    public getAllCurrentGroupUserProfilesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserProfileDTO>>>;
    public getAllCurrentGroupUserProfilesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserProfileDTO>>(`${this.basePath}/api/users-groups/current/profiles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllEnabledGroupsOfCurrentUserAndDefaultGroup
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllEnabledGroupsOfCurrentUserAndDefaultGroupUsingGET(observe?: 'body', reportProgress?: boolean): Observable<CurrentUserGroupsDTO>;
    public getAllEnabledGroupsOfCurrentUserAndDefaultGroupUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserGroupsDTO>>;
    public getAllEnabledGroupsOfCurrentUserAndDefaultGroupUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserGroupsDTO>>;
    public getAllEnabledGroupsOfCurrentUserAndDefaultGroupUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CurrentUserGroupsDTO>(`${this.basePath}/api/users-groups/current`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllSubgroups
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSubgroupsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<UsersGroupDTO>>;
    public getAllSubgroupsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UsersGroupDTO>>>;
    public getAllSubgroupsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UsersGroupDTO>>>;
    public getAllSubgroupsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UsersGroupDTO>>(`${this.basePath}/api/users-groups/subgroups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllUsersGroupsSelectorDetails
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUsersGroupsSelectorDetailsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<UsersGroupSelectorDetailsDTO>>;
    public getAllUsersGroupsSelectorDetailsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UsersGroupSelectorDetailsDTO>>>;
    public getAllUsersGroupsSelectorDetailsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UsersGroupSelectorDetailsDTO>>>;
    public getAllUsersGroupsSelectorDetailsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UsersGroupSelectorDetailsDTO>>(`${this.basePath}/api/users-groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCurrentGroupRootBooleanOperator
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentGroupRootBooleanOperatorUsingGET(observe?: 'body', reportProgress?: boolean): Observable<BooleanOperatorDTO>;
    public getCurrentGroupRootBooleanOperatorUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanOperatorDTO>>;
    public getCurrentGroupRootBooleanOperatorUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanOperatorDTO>>;
    public getCurrentGroupRootBooleanOperatorUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BooleanOperatorDTO>(`${this.basePath}/api/users-groups/current/boolean-operator`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getUsersGroup
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersGroupUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<UsersGroupDTO>;
    public getUsersGroupUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersGroupDTO>>;
    public getUsersGroupUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersGroupDTO>>;
    public getUsersGroupUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUsersGroupUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UsersGroupDTO>(`${this.basePath}/api/users-groups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateCurrentUserGroup
     * 
     * @param userGroupsDTO userGroupsDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCurrentUserGroupUsingPATCH(userGroupsDTO: UsersGroupDTO, observe?: 'body', reportProgress?: boolean): Observable<UsersGroupDTO>;
    public updateCurrentUserGroupUsingPATCH(userGroupsDTO: UsersGroupDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersGroupDTO>>;
    public updateCurrentUserGroupUsingPATCH(userGroupsDTO: UsersGroupDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersGroupDTO>>;
    public updateCurrentUserGroupUsingPATCH(userGroupsDTO: UsersGroupDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userGroupsDTO === null || userGroupsDTO === undefined) {
            throw new Error('Required parameter userGroupsDTO was null or undefined when calling updateCurrentUserGroupUsingPATCH.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<UsersGroupDTO>(`${this.basePath}/api/users-groups/current`,
            userGroupsDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateUsersGroup
     * 
     * @param usersGroupDTO usersGroupDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUsersGroupUsingPUT(usersGroupDTO: UsersGroupDTO, observe?: 'body', reportProgress?: boolean): Observable<UsersGroupDTO>;
    public updateUsersGroupUsingPUT(usersGroupDTO: UsersGroupDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersGroupDTO>>;
    public updateUsersGroupUsingPUT(usersGroupDTO: UsersGroupDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersGroupDTO>>;
    public updateUsersGroupUsingPUT(usersGroupDTO: UsersGroupDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (usersGroupDTO === null || usersGroupDTO === undefined) {
            throw new Error('Required parameter usersGroupDTO was null or undefined when calling updateUsersGroupUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UsersGroupDTO>(`${this.basePath}/api/users-groups`,
            usersGroupDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
