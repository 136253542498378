import { EvoMatPaginatorIntl } from './providers/evo-mat-paginator-intl';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressBarListener } from './shared/components/progress-bar/progress-bar.listener';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import { AuthenticationHttpInterceptor } from './services/login/authentication-http-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material';
import { ApiModule, Configuration } from './dmssdk';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { GroupSelectorPageComponent } from './pages/group-selector-page/group-selector-page.component';
import { NoGroupErrorComponent } from './pages/no-group-error/no-group-error.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import { AppSettingsModule } from './app-settings/app-settings.module';


export function getAPIConfiguration() {
  return new Configuration({ basePath: environment.api_url });
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    GroupSelectorPageComponent,
    NoGroupErrorComponent,
  ],
  imports: [
    ApiModule.forRoot(getAPIConfiguration),
    AppSettingsModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FlexLayoutModule,
    SharedModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MonacoEditorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    })
  ],
  providers: [
    ProgressBarListener,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationHttpInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: EvoMatPaginatorIntl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.installationDirectory + '/assets/i18n/'
  );
}

registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localePl);
