/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface OrganisationAdminSettingsDTO { 
    displayName?: string;
    language?: OrganisationAdminSettingsDTO.LanguageEnum;
}
export namespace OrganisationAdminSettingsDTO {
    export type LanguageEnum = 'EN' | 'PL' | 'DE';
    export const LanguageEnum = {
        EN: 'EN' as LanguageEnum,
        PL: 'PL' as LanguageEnum,
        DE: 'DE' as LanguageEnum
    };
}
