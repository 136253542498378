import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ComplexDataTypeFieldResourceService,
  ComplexDataTypeResourceService,
  QuerySearchDTO,
  UserDefinedConditionsDTO,
} from 'src/app/dmssdk';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StateService } from 'src/app/shared/services/search-state/search-state.service';
import { AdvancedFilteringPanelState } from 'src/app/shared/services/search-state/model/advanced-filtering-panel-state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortAccessAdhocService } from 'src/app/shared/services/sort-access-service/sort-access-adhoc.service';
import { ConditionAccessAdhocService } from 'src/app/shared/services/condition-access-service/condition-access-adhoc.service';
import { SortMapperService } from 'src/app/shared/services/sort-access-service/sort-mapper.service';
import { ConditionRoot, PseudocodeService, SortValidator } from '@misc/angular-components';
import { v4 as uuidv4 } from 'uuid';
import { SortSchemaAccessService } from 'src/app/shared/services/schema-access/sort-schema-access.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'evo-advanced-filtering-panel',
  templateUrl: './advanced-filtering-panel.component.html',
  styleUrls: ['./advanced-filtering-panel.component.scss'],
})
export class AdvancedFilteringPanelComponent implements OnDestroy {
  public conditionAccess: ConditionAccessAdhocService;
  public sortAccess: SortAccessAdhocService;
  public sortSchemaAccess: SortSchemaAccessService;

  form: FormGroup;
  querySearch: QuerySearchDTO = {
    ensurePageConsistency: false,
    pageSize: null,
    pagingKey: null,
    querySort: [],
    searchAfter: null,
    conditionDTO: null,
    includeHistory: false,
  };
  private _showCode: boolean = false;
  public pseudocode: Observable<string> = of('');

  constructor(
    private searchStateService: StateService,
    private formBuilder: FormBuilder,
    private sortMapperService: SortMapperService,
    private pseudocodeService: PseudocodeService,
    private complexTypeService: ComplexDataTypeResourceService,
    private complexTypeFieldService: ComplexDataTypeFieldResourceService
  ) {
    this.conditionAccess = new ConditionAccessAdhocService();
    this.sortAccess = new SortAccessAdhocService();
    this.sortSchemaAccess = new SortSchemaAccessService(complexTypeService, complexTypeFieldService);
    let state = this.searchStateService.getAdvancedFilteringState();
    if (!state) {
      let rootInternalId = uuidv4();
      this.form = this.formBuilder.group({
        condition: [
          {
            booleanOperatorChild: {
              operatorName: ConditionRoot.BooleanOperatorNameEnum.AND,
              internalId: rootInternalId,
            },
          },
          [],
        ],
        sort: [[]],
      });
    }
    else {
      this.restoreFromState(state);
    }
  }

  ngOnDestroy() {
    this.saveCurrentState();
  }

  @Input()
  public set pageSize(value: number) {
    if (value) {
      this.querySearch.pageSize = value;
    }
  }

  @Input()
  public set showCode(showCode: boolean) {
    this._showCode = showCode;
    if (showCode) {
      this.generatePseudocode();
    }
  }

  public get showCode(): boolean {
    return this._showCode;
  }

  @Output('searchButtonClicked')
  public searchButtonClicked: EventEmitter<UserDefinedConditionsDTO> = new EventEmitter();

  searchClicked(event: Event) {
    event.stopPropagation();
    if (this.form.valid == true) {
      this.assignSortsAndCondition();
      this.searchButtonClicked.emit({
        querySearch: this.querySearch,
        conditionMode: UserDefinedConditionsDTO.ConditionModeEnum.ADVANCED,
      });
    }
  }

  assignSortsAndCondition() {
    let querySorts = this.sortMapperService.mapToQuerySorts(
      this.sortAccess.elements
    );
    this.querySearch.querySort = querySorts;
    this.querySearch.conditionDTO = this.conditionAccess.getWholeCondition();
  }

  public includeHistoryChange(event: MatSlideToggleChange): void {
    this.querySearch.includeHistory = event.checked;
  }

  public generatePseudocode() {
    let root: ConditionRoot = this.conditionAccess.root;
    let rootsMap = this.conditionAccess.rootsMap;
    this.pseudocode = this.pseudocodeService.toPseudocode(root, rootsMap);
  }

  private restoreFromState(state: AdvancedFilteringPanelState) {
    this.querySearch = state.querySearch;
    this.conditionAccess = state.conditionAccess;
    this.sortAccess = state.sortAccess;
    this.showCode = state.showCode;

    let operator: ConditionRoot = {
      internalId: this.conditionAccess.root.internalId,
      operatorName: this.conditionAccess.root.operatorName,
    };
    this.form = this.formBuilder.group({
      condition: [{ booleanOperatorChild: operator }, []],
      sort: [this.sortAccess.elements, SortValidator]
    });
  }

  private saveCurrentState() {
    this.assignSortsAndCondition();
    var state: AdvancedFilteringPanelState = {
      showCode: this.showCode,
      querySearch: this.querySearch,
      querySearchValid: this.form.valid,
      conditionAccess: this.conditionAccess,
      sortAccess: this.sortAccess,
    };
    this.searchStateService.setAdvancedFilteringState(state);
  }
}
