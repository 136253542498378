import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'evo-color-sample',
  templateUrl: './color-sample.component.html',
  styleUrls: ['./color-sample.component.scss'],
})
export class ColorSampleComponent implements OnInit {
  _bgColor: string;

  @Input()
  set bgColor(value: string) {
    this._bgColor = value;
  }

  get bgColor(): string {
    return this._bgColor;
  }

  @Input() pointerCursor = true;

  constructor() { }

  ngOnInit(): void { }
}
