import { Observable, ReplaySubject } from 'rxjs';

export class ProgressBarListener {
  private counter: number = 0;
  private subject: ReplaySubject<number>;
  private obervable: Observable<number>;

  public constructor() {
    this.subject = new ReplaySubject<number>(1);
    this.obervable = this.subject.asObservable();
    this.subject.next(this.counter);
  }

  public increase() {
    this.counter++;
    this.subject.next(this.counter);
  }
  public decrease() {
    this.counter--;
    this.subject.next(this.counter);
  }

  public counterChanged(): Observable<number> {
    return this.obervable;
  }
}
