import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageSource } from 'src/app/pages/user/document/document.component';

@Component({
  selector: 'evo-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  private _slides: ImageSource[];
  @Input() set slides(value) {
    this._slides = value;
    this.currentSlide =
      this.currentSlide + this.slidesToMoveWhenAvailable < 0
        ? this.slides.length - 1
        : this.currentSlide + this.slidesToMoveWhenAvailable;
  }

  get slides() {
    return this._slides;
  }

  private slidesToMoveWhenAvailable = 0;
  private currentSlide = 0;
  @Output() slideChanged = new EventEmitter();

  onPreviousClick(event) {
    event.stopPropagation();
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    if (this.slides.length == 1) {
      this.slidesToMoveWhenAvailable = -1;
    }
    this.slideChanged.emit();
  }

  onNextClick(event) {
    event.stopPropagation();
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    if (this.slides.length == 1) {
      this.slidesToMoveWhenAvailable = 1;
    }
    this.slideChanged.emit();
  }
}
