import { Injectable } from '@angular/core';
import {
  DocumentTreeBranchDTO,
  DocumentTreeBranchResourceService,
} from 'src/app/dmssdk';

export interface TreeBranchWrapper {
  id: number;
  name: string;
  treeBranch: DocumentTreeBranchDTO;
  hasChildren: boolean;
  children?: TreeBranchWrapper[];
  parentId: number;
}

@Injectable({
  providedIn: 'root',
})
export class TreeEditorServiceService {
  constructor(private treeBranchService: DocumentTreeBranchResourceService) { }

  public onMoveNode($event) {
    var movedNode: TreeBranchWrapper = $event.node;
    var nodeMovedTo: TreeBranchWrapper = $event.to.parent;
    if (
      movedNode &&
      nodeMovedTo &&
      movedNode.treeBranch &&
      nodeMovedTo.treeBranch
    ) {
      var movedTreeBranch: DocumentTreeBranchDTO = movedNode.treeBranch;
      var treeBranchMovedTo: DocumentTreeBranchDTO = nodeMovedTo.treeBranch;
      var newIndex = $event.to.index;
      var newOrder = this.findNewOrder(newIndex, nodeMovedTo.children);
      movedTreeBranch.parentId = treeBranchMovedTo.id;
      movedTreeBranch.order = newOrder;
      this.treeBranchService
        .updateDocumentTreeBranchUsingPUT(movedTreeBranch)
        .subscribe();
    }
  }

  private findNewOrder(
    currentIndex: number,
    branches: TreeBranchWrapper[]
  ): number {
    if (branches.length === 1) {
      return 0;
    }
    if (currentIndex === 0) {
      return (branches[1].treeBranch.order ?? 0) - 100;
    }
    if (currentIndex === branches.length - 1) {
      return (branches[branches.length - 2].treeBranch.order ?? 0) + 100;
    }
    return (
      ((branches[currentIndex - 1].treeBranch.order ?? 0) +
        (branches[currentIndex + 1].treeBranch.order ?? 0)) /
      2
    );
  }
}
