<mat-form-field class="navbar-fit">
  <mat-select [(value)]="selectedGroup.id" (selectionChange)="onGroupSelect()">
    <mat-select-trigger>
      <span
        ><div
          *ngIf="selectedGroup"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <!--div class="thin-frame logo-mini" *ngIf="!selectedGroup.logo"></div-->
          <img
            class="logo-mini"
            [src]="selectedGroup.logo | base64Image"
            *ngIf="selectedGroup.logo"
            alt="subgroup logo"
          />
          {{ selectedGroup.name }}
        </div>
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let group of groups" [value]="group.id">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="logo-mini" *ngIf="!group.logo"></div>
        <img
          class="logo-mini"
          [src]="group.logo | base64Image"
          *ngIf="group.logo"
          alt="subgroup logo"
        />
        {{ group.name }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
