import { Injectable } from '@angular/core';
import { AbstractSortAccess, Sort } from '@misc/angular-components';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryResourceService, QuerySortDTO, QuerySortResourceService } from 'src/app/dmssdk';
import { SortMapperService } from './sort-mapper.service';

@Injectable({
  providedIn: 'root'
})
export class SortAccessDbService extends AbstractSortAccess {
  constructor(private queryService: QueryResourceService, private querySortService: QuerySortResourceService,
    private sortMapperService: SortMapperService) {
    super();
  }

  loadData(): Observable<Sort[]> {
    if (!this.queryId) {
      return of([]);
    }
    return this.queryService.getQuerySortsUsingGET(this.queryId).pipe(map(result => this.sortMapperService.mapToSorts(result)));
  }

  save() {
    var sorts: Sort[] = this.elements;
    var sortDTOs = this.sortMapperService.mapToQuerySorts(sorts);
    for (let index = 0; index < sortDTOs.length; index++) {
      this.saveSort(sortDTOs[index], index);

    }
    for (let index = 0; index < this.deletedElements.length; index++) {
      this.querySortService.deleteQuerySortUsingDELETE(this.deletedElements[index]).subscribe();
    }
    this.deletedElements = [];
  }

  private saveSort(toSave: QuerySortDTO, index: number) {
    toSave.queryId = this.queryId;
    if (toSave.id) {
      this.querySortService.updateQuerySortUsingPUT(toSave)
        .subscribe(result => this.onSortEdited(this.sortMapperService.mapToSort(result), index));
    } else {
      this.querySortService.createQuerySortUsingPOST(toSave)
        .subscribe(result => this.onSortEdited(this.sortMapperService.mapToSort(result), index));
    }
  }
}
