/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DocumentTreeBranchDTO { 
    attributeId?: number;
    attributeValuesOrder?: DocumentTreeBranchDTO.AttributeValuesOrderEnum;
    countEnabled?: boolean;
    id?: number;
    mode?: DocumentTreeBranchDTO.ModeEnum;
    name?: string;
    order?: number;
    organisationId?: number;
    parentId?: number;
    parentName?: string;
    queryId?: number;
    queryName?: string;
}
export namespace DocumentTreeBranchDTO {
    export type AttributeValuesOrderEnum = 'DESC' | 'ASC';
    export const AttributeValuesOrderEnum = {
        DESC: 'DESC' as AttributeValuesOrderEnum,
        ASC: 'ASC' as AttributeValuesOrderEnum
    };
    export type ModeEnum = 'NONE' | 'CONDITION' | 'ATTRIBUTE';
    export const ModeEnum = {
        NONE: 'NONE' as ModeEnum,
        CONDITION: 'CONDITION' as ModeEnum,
        ATTRIBUTE: 'ATTRIBUTE' as ModeEnum
    };
}
