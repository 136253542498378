import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressBarListener } from './progress-bar.listener';

@Component({
  selector: 'evo-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  public active: boolean = false;
  private subscription: Subscription;

  public constructor(private listener: ProgressBarListener) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.listener.counterChanged().subscribe((count) => {
      this.active = count > 0;
    });
  }
}
