/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Evaluates to boolean value The entity is a wrapper for BooleanFunction or BooleanOperator
 */
export interface BooleanExpressionDTO { 
    booleanFunctionId?: number;
    booleanFunctionName?: string;
    booleanOperatorChildId?: number;
    booleanOperatorChildName?: string;
    booleanOperatorParentId?: number;
    booleanOperatorParentName?: string;
    falseValueExpressionTemplate?: string;
    id?: number;
    name?: string;
    organisationId?: number;
}
