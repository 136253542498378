/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MapOfstringAndobject } from './mapOfstringAndobject';


export interface RawDocumentDTO { 
    body?: any;
    classConfident?: boolean;
    clazz?: string;
    content?: string;
    creationDate?: number;
    documentType?: RawDocumentDTO.DocumentTypeEnum;
    editionDate?: number;
    editorUsername?: string;
    id?: string;
    mainDocId?: string;
    name?: string;
    originalFileChecksum?: string;
    originalFileCreationDate?: number;
    originalFileExtension?: string;
    originalFilename?: string;
    pagesNumber?: number;
    processedFileChecksum?: string;
    processedFileCreationDate?: number;
    processedFilename?: string;
    stamps?: Array<MapOfstringAndobject>;
    status?: string;
    valid?: boolean;
}
export namespace RawDocumentDTO {
    export type DocumentTypeEnum = 'EXTERNAL' | 'INTERNAL';
    export const DocumentTypeEnum = {
        EXTERNAL: 'EXTERNAL' as DocumentTypeEnum,
        INTERNAL: 'INTERNAL' as DocumentTypeEnum
    };
}
