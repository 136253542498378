/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentsProcessingConfigurationDTO } from '../model/documentsProcessingConfigurationDTO';
import { TriggerConfigurationDTO } from '../model/triggerConfigurationDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TriggerConfigurationResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addCondition
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addConditionUsingPOST(id: number, observe?: 'body', reportProgress?: boolean): Observable<TriggerConfigurationDTO>;
    public addConditionUsingPOST(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TriggerConfigurationDTO>>;
    public addConditionUsingPOST(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TriggerConfigurationDTO>>;
    public addConditionUsingPOST(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addConditionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<TriggerConfigurationDTO>(`${this.basePath}/api/trigger-configurations/${encodeURIComponent(String(id))}/conditions`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTriggerConfiguration
     * 
     * @param triggerConfigurationDTO triggerConfigurationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTriggerConfigurationUsingPOST(triggerConfigurationDTO: TriggerConfigurationDTO, observe?: 'body', reportProgress?: boolean): Observable<TriggerConfigurationDTO>;
    public createTriggerConfigurationUsingPOST(triggerConfigurationDTO: TriggerConfigurationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TriggerConfigurationDTO>>;
    public createTriggerConfigurationUsingPOST(triggerConfigurationDTO: TriggerConfigurationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TriggerConfigurationDTO>>;
    public createTriggerConfigurationUsingPOST(triggerConfigurationDTO: TriggerConfigurationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (triggerConfigurationDTO === null || triggerConfigurationDTO === undefined) {
            throw new Error('Required parameter triggerConfigurationDTO was null or undefined when calling createTriggerConfigurationUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TriggerConfigurationDTO>(`${this.basePath}/api/trigger-configurations`,
            triggerConfigurationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteTriggerConfiguration
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTriggerConfigurationUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTriggerConfigurationUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTriggerConfigurationUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTriggerConfigurationUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTriggerConfigurationUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/trigger-configurations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllTriggerConfigurations
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTriggerConfigurationsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<TriggerConfigurationDTO>>;
    public getAllTriggerConfigurationsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TriggerConfigurationDTO>>>;
    public getAllTriggerConfigurationsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TriggerConfigurationDTO>>>;
    public getAllTriggerConfigurationsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TriggerConfigurationDTO>>(`${this.basePath}/api/trigger-configurations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTriggerConfigurationDocumentsProcessingConfigurations
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriggerConfigurationDocumentsProcessingConfigurationsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentsProcessingConfigurationDTO>>;
    public getTriggerConfigurationDocumentsProcessingConfigurationsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentsProcessingConfigurationDTO>>>;
    public getTriggerConfigurationDocumentsProcessingConfigurationsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentsProcessingConfigurationDTO>>>;
    public getTriggerConfigurationDocumentsProcessingConfigurationsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTriggerConfigurationDocumentsProcessingConfigurationsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DocumentsProcessingConfigurationDTO>>(`${this.basePath}/api/trigger-configurations/${encodeURIComponent(String(id))}/documents-processing-configurations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTriggerConfiguration
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriggerConfigurationUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<TriggerConfigurationDTO>;
    public getTriggerConfigurationUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TriggerConfigurationDTO>>;
    public getTriggerConfigurationUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TriggerConfigurationDTO>>;
    public getTriggerConfigurationUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTriggerConfigurationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TriggerConfigurationDTO>(`${this.basePath}/api/trigger-configurations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTriggerConfiguration
     * 
     * @param triggerConfigurationDTO triggerConfigurationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTriggerConfigurationUsingPUT(triggerConfigurationDTO: TriggerConfigurationDTO, observe?: 'body', reportProgress?: boolean): Observable<TriggerConfigurationDTO>;
    public updateTriggerConfigurationUsingPUT(triggerConfigurationDTO: TriggerConfigurationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TriggerConfigurationDTO>>;
    public updateTriggerConfigurationUsingPUT(triggerConfigurationDTO: TriggerConfigurationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TriggerConfigurationDTO>>;
    public updateTriggerConfigurationUsingPUT(triggerConfigurationDTO: TriggerConfigurationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (triggerConfigurationDTO === null || triggerConfigurationDTO === undefined) {
            throw new Error('Required parameter triggerConfigurationDTO was null or undefined when calling updateTriggerConfigurationUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<TriggerConfigurationDTO>(`${this.basePath}/api/trigger-configurations`,
            triggerConfigurationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
