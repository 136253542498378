<div
  fxFlex="100%"
  fxLayout="row"
  fxLayoutAlign="start stretch"
  fxLayoutGap="8px"
  class="scrollable"
>
  <div fxFlex="100%" fxLayout="row" fxLayoutAlign="start center">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="0px"
      class="margin-around"
      fxFlex="grow"
    >
      <mat-form-field class="full-width">
        <mat-label>{{ "filtering-panel.search-label" | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="searchValue"
          (keydown.enter)="searchClicked($event)"
        />
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="start center" class="buttons">
        <button
          class="search-button"
          mat-icon-button
          color="primary"
          (click)="searchClicked($event)"
          matTooltip="{{ 'filtering-panel.search-tooltip' | translate }}"
        >
          <mat-icon color="accent">search</mat-icon>
        </button>
        <mat-slide-toggle
          class="incl-hist"
          [checked]="querySearch.includeHistory === true"
          (change)="includeHistoryChange($event)"
          >{{ "filtering-panel.include-history" | translate }}
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
