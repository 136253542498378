/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentClassDTO } from '../model/documentClassDTO';
import { DocumentClassMappingElementDTO } from '../model/documentClassMappingElementDTO';
import { PageOfDocumentClassDTO } from '../model/pageOfDocumentClassDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentClassResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createDocumentClass
     * 
     * @param documentClassDTO documentClassDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocumentClassUsingPOST(documentClassDTO: DocumentClassDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentClassDTO>;
    public createDocumentClassUsingPOST(documentClassDTO: DocumentClassDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentClassDTO>>;
    public createDocumentClassUsingPOST(documentClassDTO: DocumentClassDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentClassDTO>>;
    public createDocumentClassUsingPOST(documentClassDTO: DocumentClassDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentClassDTO === null || documentClassDTO === undefined) {
            throw new Error('Required parameter documentClassDTO was null or undefined when calling createDocumentClassUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DocumentClassDTO>(`${this.basePath}/api/document-classes`,
            documentClassDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteDocumentClass
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocumentClassUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDocumentClassUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDocumentClassUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDocumentClassUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDocumentClassUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/document-classes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllDocumentClassesByModule
     * 
     * @param moduleId moduleId
     * @param pageIndex 
     * @param pageSize 
     * @param sortField 
     * @param sortingOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDocumentClassesByModuleUsingGET(moduleId: number, pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe?: 'body', reportProgress?: boolean): Observable<PageOfDocumentClassDTO>;
    public getAllDocumentClassesByModuleUsingGET(moduleId: number, pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfDocumentClassDTO>>;
    public getAllDocumentClassesByModuleUsingGET(moduleId: number, pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfDocumentClassDTO>>;
    public getAllDocumentClassesByModuleUsingGET(moduleId: number, pageIndex?: number, pageSize?: number, sortField?: string, sortingOrder?: 'DESC' | 'ASC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleId === null || moduleId === undefined) {
            throw new Error('Required parameter moduleId was null or undefined when calling getAllDocumentClassesByModuleUsingGET.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('pageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (sortField !== undefined && sortField !== null) {
            queryParameters = queryParameters.set('sortField', <any>sortField);
        }
        if (sortingOrder !== undefined && sortingOrder !== null) {
            queryParameters = queryParameters.set('sortingOrder', <any>sortingOrder);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageOfDocumentClassDTO>(`${this.basePath}/api/document-classes/modules/${encodeURIComponent(String(moduleId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllDocumentClasses
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDocumentClassesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentClassDTO>>;
    public getAllDocumentClassesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentClassDTO>>>;
    public getAllDocumentClassesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentClassDTO>>>;
    public getAllDocumentClassesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DocumentClassDTO>>(`${this.basePath}/api/document-classes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentClassByFullClassName
     * 
     * @param moduleClassName moduleClassName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentClassByFullClassNameUsingGET(moduleClassName: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentClassDTO>;
    public getDocumentClassByFullClassNameUsingGET(moduleClassName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentClassDTO>>;
    public getDocumentClassByFullClassNameUsingGET(moduleClassName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentClassDTO>>;
    public getDocumentClassByFullClassNameUsingGET(moduleClassName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (moduleClassName === null || moduleClassName === undefined) {
            throw new Error('Required parameter moduleClassName was null or undefined when calling getDocumentClassByFullClassNameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentClassDTO>(`${this.basePath}/api/document-classes/${encodeURIComponent(String(moduleClassName))}/class`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentClassMappingElements
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentClassMappingElementsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentClassMappingElementDTO>>;
    public getDocumentClassMappingElementsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentClassMappingElementDTO>>>;
    public getDocumentClassMappingElementsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentClassMappingElementDTO>>>;
    public getDocumentClassMappingElementsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentClassMappingElementsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DocumentClassMappingElementDTO>>(`${this.basePath}/api/document-classes/${encodeURIComponent(String(id))}/mapping-elements`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentClass
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentClassUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentClassDTO>;
    public getDocumentClassUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentClassDTO>>;
    public getDocumentClassUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentClassDTO>>;
    public getDocumentClassUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentClassUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentClassDTO>(`${this.basePath}/api/document-classes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateDocumentClass
     * 
     * @param documentClassDTO documentClassDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocumentClassUsingPUT(documentClassDTO: DocumentClassDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentClassDTO>;
    public updateDocumentClassUsingPUT(documentClassDTO: DocumentClassDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentClassDTO>>;
    public updateDocumentClassUsingPUT(documentClassDTO: DocumentClassDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentClassDTO>>;
    public updateDocumentClassUsingPUT(documentClassDTO: DocumentClassDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentClassDTO === null || documentClassDTO === undefined) {
            throw new Error('Required parameter documentClassDTO was null or undefined when calling updateDocumentClassUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DocumentClassDTO>(`${this.basePath}/api/document-classes`,
            documentClassDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
