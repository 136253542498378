/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface EnumDataTypeDTO { 
    id?: number;
    moduleId?: number;
    moduleName?: string;
    name?: string;
    organisationId?: number;
    systemType?: boolean;
    type?: EnumDataTypeDTO.TypeEnum;
    valuesSource?: EnumDataTypeDTO.ValuesSourceEnum;
}
export namespace EnumDataTypeDTO {
    export type TypeEnum = 'STRING' | 'DATE' | 'INTEGER' | 'FLOAT' | 'BOOLEAN';
    export const TypeEnum = {
        STRING: 'STRING' as TypeEnum,
        DATE: 'DATE' as TypeEnum,
        INTEGER: 'INTEGER' as TypeEnum,
        FLOAT: 'FLOAT' as TypeEnum,
        BOOLEAN: 'BOOLEAN' as TypeEnum
    };
    export type ValuesSourceEnum = 'USER_DEFINED' | 'ES_DISTINCT_VALUES' | 'APPLICATION_USERS' | 'STAMPS';
    export const ValuesSourceEnum = {
        USERDEFINED: 'USER_DEFINED' as ValuesSourceEnum,
        ESDISTINCTVALUES: 'ES_DISTINCT_VALUES' as ValuesSourceEnum,
        APPLICATIONUSERS: 'APPLICATION_USERS' as ValuesSourceEnum,
        STAMPS: 'STAMPS' as ValuesSourceEnum
    };
}
