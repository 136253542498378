<div fxLayout="row" fxLayoutAlign="start stretch" fxFlex="100%">
  <evo-filtering-panel
    *ngIf="panelState == State.SIMPLE"
    (searchButtonClicked)="search($event)"
    [pageSize]="pageSize"
    class="filtering"
    fxFlex
  ></evo-filtering-panel>
  <evo-advanced-filtering-panel
    *ngIf="panelState != State.SIMPLE"
    [pageSize]="pageSize"
    [showCode]="panelState == State.ADVANCED_CODE"
    (searchButtonClicked)="search($event)"
    class="filtering"
    fxFlex
  ></evo-advanced-filtering-panel>
  <div fxLayout="column" fxLayoutAlign="space-between end">
    <div
      *ngIf="panelState == State.SIMPLE"
      fxLayout="row"
      fxLayoutAlign="start end"
    >
      <button
        mat-icon-button
        [disableRipple]="true"
        matTooltip="{{ 'filtering-panel.info' | translate }}"
      >
        <mat-icon style="color: gray">info</mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        (click)="changeMode()"
        matTooltip="{{ 'filtering-panel.open-advanced' | translate }}"
      >
        <mat-icon style="color: gray">open_in_new</mat-icon>
      </button>
    </div>

    <button
      *ngIf="panelState != State.SIMPLE"
      mat-icon-button
      (click)="changeMode()"
      matTooltip="{{ 'filtering-panel.open-simple' | translate }}"
    >
      <mat-icon style="color: gray">open_in_new_off</mat-icon>
    </button>

    <button
      type="button"
      *ngIf="panelState == State.ADVANCED_QUERY"
      mat-icon-button
      (click)="changeAdvancedMode()"
      matTooltip="{{ 'filtering-panel.open-advanced' | translate }}"
    >
      <mat-icon style="color: gray">expand_more</mat-icon>
    </button>
    <button
      type="button"
      *ngIf="panelState == State.ADVANCED_CODE"
      mat-icon-button
      (click)="changeAdvancedMode()"
      matTooltip="{{ 'filtering-panel.collapse-advanced' | translate }}"
    >
      <mat-icon style="color: gray">expand_less</mat-icon>
    </button>
  </div>
</div>
