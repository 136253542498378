import { Injectable } from '@angular/core';
import { ComplexFieldWrapper, DataTypeDesc } from '@misc/angular-components';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComplexDataTypeFieldResourceService, ComplexDataTypeResourceService, ComplexFieldWrapperDTO } from 'src/app/dmssdk';
import { SchemaAccessService } from './schema-access.service';

@Injectable({
  providedIn: 'root'
})
export class SortSchemaAccessService extends SchemaAccessService {

  public unsortableFields: string[] = [
    'content',
    '_id',
    'stamps',
    '@timestamp',
  ];

  constructor(public complexTypeService: ComplexDataTypeResourceService,
    public complexTypeFieldService: ComplexDataTypeFieldResourceService) {
    super(complexTypeService, complexTypeFieldService);
  }

  getTypeDescOfField(
    typeId: number,
    fieldIds: number[]
  ): Observable<DataTypeDesc> {
    return this.getFieldsOfTypeWithFiltering(typeId, false)
      .pipe(map(fieldsOfType =>
        this.getTypeDescOfFieldInternal(fieldsOfType, fieldIds)
      ));
  }

  getFieldsByParams(typeId: number): Observable<ComplexFieldWrapper[]> {
    return this.getFieldsOfTypeWithFiltering(typeId, true);
  }


  private getFieldsOfTypeWithFiltering(typeId: number, filter: boolean): Observable<ComplexFieldWrapper[]> {
    let valueFromCache: ComplexFieldWrapper[] = this.fieldsOfType.get(typeId);
    let valuesObservable: Observable<ComplexFieldWrapper[]>;
    if (valueFromCache) {
      valuesObservable = of(valueFromCache);
    } else {
      valuesObservable = this.complexTypeService.getAllActiveAttributesOfComplexDataTypeUsingGET(typeId)
        .pipe(tap(values => this.fieldsOfType.set(typeId, values)));
    }
    if (!filter) {
      return valuesObservable;
    }
    return valuesObservable.pipe(
      map((result) => {
        return result.filter(el => !this.unsortableFields.includes(el.field.storageName));
      })
    );
  }

}
