/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BatchJobMonitoringDTO } from '../model/batchJobMonitoringDTO';
import { BatchJobMonitoringSearchRequestDTO } from '../model/batchJobMonitoringSearchRequestDTO';
import { BatchJobStepMonitoringDTO } from '../model/batchJobStepMonitoringDTO';
import { PageOfBatchJobMonitoringDTO } from '../model/pageOfBatchJobMonitoringDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BatchJobMonitoringResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * deleteBatchJobMonitoring
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBatchJobMonitoringUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBatchJobMonitoringUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBatchJobMonitoringUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBatchJobMonitoringUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBatchJobMonitoringUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/batch-job-monitorings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllBatchJobMonitoringsOfOrganisation
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBatchJobMonitoringsOfOrganisationUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJobMonitoringDTO>>;
    public getAllBatchJobMonitoringsOfOrganisationUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJobMonitoringDTO>>>;
    public getAllBatchJobMonitoringsOfOrganisationUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJobMonitoringDTO>>>;
    public getAllBatchJobMonitoringsOfOrganisationUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJobMonitoringDTO>>(`${this.basePath}/api/batch-job-monitorings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobDescriptionBatchJobMonitorings
     * 
     * @param descId descId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobDescriptionBatchJobMonitoringsUsingGET(descId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJobMonitoringDTO>>;
    public getBatchJobDescriptionBatchJobMonitoringsUsingGET(descId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJobMonitoringDTO>>>;
    public getBatchJobDescriptionBatchJobMonitoringsUsingGET(descId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJobMonitoringDTO>>>;
    public getBatchJobDescriptionBatchJobMonitoringsUsingGET(descId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (descId === null || descId === undefined) {
            throw new Error('Required parameter descId was null or undefined when calling getBatchJobDescriptionBatchJobMonitoringsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJobMonitoringDTO>>(`${this.basePath}/api/batch-job-monitorings/description/${encodeURIComponent(String(descId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobMonitoringBatchJobStepMonitorings
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobMonitoringBatchJobStepMonitoringsUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BatchJobStepMonitoringDTO>>;
    public getBatchJobMonitoringBatchJobStepMonitoringsUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BatchJobStepMonitoringDTO>>>;
    public getBatchJobMonitoringBatchJobStepMonitoringsUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BatchJobStepMonitoringDTO>>>;
    public getBatchJobMonitoringBatchJobStepMonitoringsUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBatchJobMonitoringBatchJobStepMonitoringsUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BatchJobStepMonitoringDTO>>(`${this.basePath}/api/batch-job-monitorings/${encodeURIComponent(String(id))}/steps`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBatchJobMonitoring
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBatchJobMonitoringUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BatchJobMonitoringDTO>;
    public getBatchJobMonitoringUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BatchJobMonitoringDTO>>;
    public getBatchJobMonitoringUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BatchJobMonitoringDTO>>;
    public getBatchJobMonitoringUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBatchJobMonitoringUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BatchJobMonitoringDTO>(`${this.basePath}/api/batch-job-monitorings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchBatchJobMonitoring
     * 
     * @param searchRequest searchRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBatchJobMonitoringUsingPOST(searchRequest: BatchJobMonitoringSearchRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<PageOfBatchJobMonitoringDTO>;
    public searchBatchJobMonitoringUsingPOST(searchRequest: BatchJobMonitoringSearchRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfBatchJobMonitoringDTO>>;
    public searchBatchJobMonitoringUsingPOST(searchRequest: BatchJobMonitoringSearchRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfBatchJobMonitoringDTO>>;
    public searchBatchJobMonitoringUsingPOST(searchRequest: BatchJobMonitoringSearchRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (searchRequest === null || searchRequest === undefined) {
            throw new Error('Required parameter searchRequest was null or undefined when calling searchBatchJobMonitoringUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PageOfBatchJobMonitoringDTO>(`${this.basePath}/api/batch-job-monitorings/search`,
            searchRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
