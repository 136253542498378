/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BooleanOperatorDTO } from '../model/booleanOperatorDTO';
import { ConditionDTO } from '../model/conditionDTO';
import { ConditionToSaveDTO } from '../model/conditionToSaveDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BooleanOperatorResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createBooleanOperator
     * 
     * @param booleanOperatorDTO booleanOperatorDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBooleanOperatorUsingPOST(booleanOperatorDTO: BooleanOperatorDTO, observe?: 'body', reportProgress?: boolean): Observable<BooleanOperatorDTO>;
    public createBooleanOperatorUsingPOST(booleanOperatorDTO: BooleanOperatorDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanOperatorDTO>>;
    public createBooleanOperatorUsingPOST(booleanOperatorDTO: BooleanOperatorDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanOperatorDTO>>;
    public createBooleanOperatorUsingPOST(booleanOperatorDTO: BooleanOperatorDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (booleanOperatorDTO === null || booleanOperatorDTO === undefined) {
            throw new Error('Required parameter booleanOperatorDTO was null or undefined when calling createBooleanOperatorUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BooleanOperatorDTO>(`${this.basePath}/api/boolean-operators`,
            booleanOperatorDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteBooleanOperator
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBooleanOperatorUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBooleanOperatorUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBooleanOperatorUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBooleanOperatorUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBooleanOperatorUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/boolean-operators/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBooleanOperatorAsCondition
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBooleanOperatorAsConditionUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ConditionDTO>;
    public getBooleanOperatorAsConditionUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConditionDTO>>;
    public getBooleanOperatorAsConditionUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConditionDTO>>;
    public getBooleanOperatorAsConditionUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBooleanOperatorAsConditionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ConditionDTO>(`${this.basePath}/api/boolean-operators/conditions/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBooleanOperator
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBooleanOperatorUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BooleanOperatorDTO>;
    public getBooleanOperatorUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanOperatorDTO>>;
    public getBooleanOperatorUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanOperatorDTO>>;
    public getBooleanOperatorUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBooleanOperatorUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BooleanOperatorDTO>(`${this.basePath}/api/boolean-operators/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveWholeCondition
     * 
     * @param conditionToSaveDTO conditionToSaveDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveWholeConditionUsingPOST(conditionToSaveDTO: ConditionToSaveDTO, observe?: 'body', reportProgress?: boolean): Observable<BooleanOperatorDTO>;
    public saveWholeConditionUsingPOST(conditionToSaveDTO: ConditionToSaveDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanOperatorDTO>>;
    public saveWholeConditionUsingPOST(conditionToSaveDTO: ConditionToSaveDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanOperatorDTO>>;
    public saveWholeConditionUsingPOST(conditionToSaveDTO: ConditionToSaveDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (conditionToSaveDTO === null || conditionToSaveDTO === undefined) {
            throw new Error('Required parameter conditionToSaveDTO was null or undefined when calling saveWholeConditionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BooleanOperatorDTO>(`${this.basePath}/api/boolean-operators/whole-condition`,
            conditionToSaveDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBooleanOperator
     * 
     * @param booleanOperatorDTO booleanOperatorDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBooleanOperatorUsingPUT(booleanOperatorDTO: BooleanOperatorDTO, observe?: 'body', reportProgress?: boolean): Observable<BooleanOperatorDTO>;
    public updateBooleanOperatorUsingPUT(booleanOperatorDTO: BooleanOperatorDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanOperatorDTO>>;
    public updateBooleanOperatorUsingPUT(booleanOperatorDTO: BooleanOperatorDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanOperatorDTO>>;
    public updateBooleanOperatorUsingPUT(booleanOperatorDTO: BooleanOperatorDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (booleanOperatorDTO === null || booleanOperatorDTO === undefined) {
            throw new Error('Required parameter booleanOperatorDTO was null or undefined when calling updateBooleanOperatorUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BooleanOperatorDTO>(`${this.basePath}/api/boolean-operators`,
            booleanOperatorDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
