/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConditionDTO } from './conditionDTO';
import { DocumentsProcessingConfigurationDTO } from './documentsProcessingConfigurationDTO';
import { FieldValueAssignmentDTO } from './fieldValueAssignmentDTO';


export interface FieldsToSetByConditionAndStatus { 
    condition?: ConditionDTO;
    documentsProcessingConfiguration?: DocumentsProcessingConfigurationDTO;
    fieldValueAssignments?: Array<FieldValueAssignmentDTO>;
}
