import { TextWithLabelComponent } from './components/text-with-label/text-with-label.component';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TreeEditorComponent } from './components/tree-editor/tree-editor/tree-editor.component';
import { Configuration } from '../dmssdk';
import { environment } from 'src/environments/environment';
import { EdiatbleLabelComponent } from './components/ediatble-label/ediatble-label.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { BranchEditorComponent } from './components/tree-editor/branch-editor/branch-editor.component';
import { FilteringPanelComponent } from './components/query-editor/filtering-panel/filtering-panel.component';
import { QueryEditorComponent } from './components/query-editor/query-editor/query-editor.component';
import { ConditionFormattingPipe } from './pipes/condition-formatting.pipe';
import { TreeModule } from '@circlon/angular-tree-component';
import { AdvancedFilteringPanelComponent } from './components/query-editor/advanced-filtering-panel/advanced-filtering-panel.component';
import { Base64ImagePipe } from './pipes/base64-image.pipe';
import { GroupSelectorComponent } from './components/group-selector/group-selector.component';
import { TextWithLabelEditableComponent } from './components/text-with-label-editable/text-with-label-editable.component';
import { InputComponent } from './components/input/input.component';
import { ColorSampleComponent } from './components/color-sample/color-sample.component';
import { StampPositionSelectorComponent } from './components/stamp-position-selector/stamp-position-selector.component';
import { WarningComponent } from './components/warning/warning.component';
import { AttributeCompareFilterPipe } from './pipes/attribute-compare-value.pipe';
import { DigitOnlyDirective } from './util/digit-only-directive';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { SchemaAccessService } from './services/schema-access/schema-access.service';
import { ConditionAccessDbService } from './services/condition-access-service/condition-access-db.service';
import { AngularComponentsModule, ConditionEditorModule, FunctionFormattingPipe } from '@misc/angular-components';
export function getAPIConfiguration() {
  return new Configuration({ basePath: environment.api_url });
}



@NgModule({
  declarations: [
    ProgressBarComponent,
    FilteringPanelComponent,
    QueryEditorComponent,
    TreeEditorComponent,
    EdiatbleLabelComponent,
    CarouselComponent,
    ConditionFormattingPipe,
    BranchEditorComponent,
    AdvancedFilteringPanelComponent,
    Base64ImagePipe,
    GroupSelectorComponent,
    CapitalizeFirstLetterPipe,
    AttributeCompareFilterPipe,
    TextWithLabelEditableComponent,
    InputComponent,
    TextWithLabelComponent,
    ColorSampleComponent,
    StampPositionSelectorComponent,
    WarningComponent,
    DigitOnlyDirective,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TreeModule,
    TranslateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    AngularComponentsModule.forRoot({ translation: TranslateModule }),
    ConditionEditorModule
  ],
  exports: [
    ProgressBarComponent,
    TreeEditorComponent,
    FilteringPanelComponent,
    QueryEditorComponent,
    CarouselComponent,
    AdvancedFilteringPanelComponent,
    Base64ImagePipe,
    GroupSelectorComponent,
    TranslateModule,
    CapitalizeFirstLetterPipe,
    TextWithLabelEditableComponent,
    TextWithLabelComponent,
    ColorSampleComponent,
    StampPositionSelectorComponent,
    WarningComponent,
    AttributeCompareFilterPipe,
    DigitOnlyDirective,
    ConditionEditorModule
  ],
  providers: [
    { provide: "TypesAccess", useClass: SchemaAccessService },
    { provide: "TypeFieldsAccess", useClass: SchemaAccessService },
    { provide: "SchemaAccess", useClass: SchemaAccessService },
    { provide: "ConditionAccess", useClass: ConditionAccessDbService },
    FunctionFormattingPipe,
  ],

})
export class SharedModule { }
