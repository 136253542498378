<div class="document">
  <table role="none" aria-describedby="Available stamps">
    <tr *ngFor="let row of rowIds; index as rowIdx">
      <td *ngFor="let col of columnIds; index as colIdx">
        <mat-card
          *ngIf="currentSelectedId === getCellId(rowIdx, colIdx)"
          class="mat-elevation-z0 selected"
        ></mat-card>
        <mat-card
          *ngIf="currentSelectedId !== getCellId(rowIdx, colIdx)"
          class="mat-elevation-z0"
          (click)="selectCell(rowIdx, colIdx)"
        ></mat-card>
      </td>
    </tr>
  </table>
</div>
