<div fxLayout="row" fxLayoutAlign="start center">
  <span *ngIf="!editable">{{text}}</span>
  <mat-form-field appearance="standard" *ngIf="editable" (focusout)="editable=false" #formtbe>
    <input id="myfancyinput" matInput placeholder="write name..." (change)="update()"
      [style.display]="editable ? 'block' : 'none'" [(ngModel)]="text" class="myfancyinput">
  </mat-form-field>
  <button mat-icon-button matTooltip="Edit" *ngIf="!editable && !readOnly" (click)="startEdit()">
    <mat-icon>edit</mat-icon>
  </button>
</div>
<div></div>
