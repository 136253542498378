/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ComplexDataTypeFieldDTO { 
    active?: boolean;
    complexDataTypeId?: string;
    containsSensitiveInfo?: boolean;
    defaultValue?: string;
    defaultValueFunction?: ComplexDataTypeFieldDTO.DefaultValueFunctionEnum;
    displayName?: string;
    documentSystemField?: boolean;
    enumDataTypeId?: string;
    enumDataTypeType?: ComplexDataTypeFieldDTO.EnumDataTypeTypeEnum;
    id?: number;
    name: string;
    nullable?: boolean;
    objectSystemField?: boolean;
    organisationId?: number;
    parentId?: string;
    repeatable?: boolean;
    showInList?: boolean;
    storageName?: string;
    stringInterpretation?: ComplexDataTypeFieldDTO.StringInterpretationEnum;
    type?: ComplexDataTypeFieldDTO.TypeEnum;
    /**
     * the name is not correct in this context. It describes if the field is writable (value true) or read only (value false)
     */
    visibleInGui?: boolean;
}
export namespace ComplexDataTypeFieldDTO {
    export type DefaultValueFunctionEnum = 'GUID';
    export const DefaultValueFunctionEnum = {
        GUID: 'GUID' as DefaultValueFunctionEnum
    };
    export type EnumDataTypeTypeEnum = 'STRING' | 'DATE' | 'INTEGER' | 'FLOAT' | 'BOOLEAN';
    export const EnumDataTypeTypeEnum = {
        STRING: 'STRING' as EnumDataTypeTypeEnum,
        DATE: 'DATE' as EnumDataTypeTypeEnum,
        INTEGER: 'INTEGER' as EnumDataTypeTypeEnum,
        FLOAT: 'FLOAT' as EnumDataTypeTypeEnum,
        BOOLEAN: 'BOOLEAN' as EnumDataTypeTypeEnum
    };
    export type StringInterpretationEnum = 'NORMAL' | 'LONG' | 'COLOR' | 'IBAN';
    export const StringInterpretationEnum = {
        NORMAL: 'NORMAL' as StringInterpretationEnum,
        LONG: 'LONG' as StringInterpretationEnum,
        COLOR: 'COLOR' as StringInterpretationEnum,
        IBAN: 'IBAN' as StringInterpretationEnum
    };
    export type TypeEnum = 'STRING' | 'DATE' | 'INTEGER' | 'FLOAT' | 'BOOLEAN';
    export const TypeEnum = {
        STRING: 'STRING' as TypeEnum,
        DATE: 'DATE' as TypeEnum,
        INTEGER: 'INTEGER' as TypeEnum,
        FLOAT: 'FLOAT' as TypeEnum,
        BOOLEAN: 'BOOLEAN' as TypeEnum
    };
}
