/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BatchJobMonitoringSearchRequestDTO { 
    batchJobDescriptionIds?: Array<number>;
    batchJobIdentifiers?: Array<string>;
    batchJobStatuses?: Array<BatchJobMonitoringSearchRequestDTO.BatchJobStatusesEnum>;
    pageIndex?: number;
    pageSize?: number;
    sortField?: string;
    sortingOrder?: BatchJobMonitoringSearchRequestDTO.SortingOrderEnum;
}
export namespace BatchJobMonitoringSearchRequestDTO {
    export type BatchJobStatusesEnum = 'IN_PROGRESS' | 'FINISHED' | 'FAILED';
    export const BatchJobStatusesEnum = {
        INPROGRESS: 'IN_PROGRESS' as BatchJobStatusesEnum,
        FINISHED: 'FINISHED' as BatchJobStatusesEnum,
        FAILED: 'FAILED' as BatchJobStatusesEnum
    };
    export type SortingOrderEnum = 'DESC' | 'ASC';
    export const SortingOrderEnum = {
        DESC: 'DESC' as SortingOrderEnum,
        ASC: 'ASC' as SortingOrderEnum
    };
}
