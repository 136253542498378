<div fxLayout="column" fxLayoutAlign="center center" class="column-height">
  <div fxFlex="10%"></div>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="10%"
    class="dms-title-div"
  >
    <h1 class="dms-title">{{ "titles.app" | translate }}</h1>
  </div>
  <div fxFlex="1px"></div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex="70%"
    class="selector-div"
  >
    <h1 class="selector-title">
      {{ "group-selecting.not-belongs-to-any-group" | translate }}
    </h1>
  </div>
  <div fxFlex="10%"></div>
</div>
