/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Specialisation of BooleanExpression Evaluates to boolean value Allows to bind one or more BooleanExpressions using boolean operators (AND, OR, NOT) Makes possible recursion - binds two boolean expressions and is itself boolean expression
 */
export interface BooleanOperatorDTO { 
    falseValueMessage?: string;
    id?: number;
    name?: string;
    operatorName?: BooleanOperatorDTO.OperatorNameEnum;
    organisationId?: number;
}
export namespace BooleanOperatorDTO {
    export type OperatorNameEnum = 'AND' | 'OR' | 'NOT' | 'XOR';
    export const OperatorNameEnum = {
        AND: 'AND' as OperatorNameEnum,
        OR: 'OR' as OperatorNameEnum,
        NOT: 'NOT' as OperatorNameEnum,
        XOR: 'XOR' as OperatorNameEnum
    };
}
