/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ChangedObject } from './changedObject';
import { NamedValueDTO } from './namedValueDTO';


export interface AttributesComparisonDTO { 
    added?: Array<NamedValueDTO>;
    changed?: Array<ChangedObject>;
    removed?: Array<NamedValueDTO>;
}
