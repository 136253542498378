/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FieldValueAssignmentDTO { 
    complexDataTypeFieldIds?: Array<number>;
    complexDataTypeId?: number;
    complexDataTypeName?: string;
    id?: number;
    organisationId?: number;
    ownerId?: number;
    status?: FieldValueAssignmentDTO.StatusEnum;
    value: string;
}
export namespace FieldValueAssignmentDTO {
    export type StatusEnum = 'OK' | 'FAILED' | 'IN_PROGRESS';
    export const StatusEnum = {
        OK: 'OK' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        INPROGRESS: 'IN_PROGRESS' as StatusEnum
    };
}
