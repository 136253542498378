/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BatchJobDescriptionDTO { 
    enabled?: boolean;
    id?: number;
    identifier: string;
    name?: string;
    organisationId?: number;
    organisationName?: string;
    parameters?: string;
    type?: BatchJobDescriptionDTO.TypeEnum;
}
export namespace BatchJobDescriptionDTO {
    export type TypeEnum = 'NORMAL' | 'PROTOTYPE' | 'PROTOTYPE_INSTANCE';
    export const TypeEnum = {
        NORMAL: 'NORMAL' as TypeEnum,
        PROTOTYPE: 'PROTOTYPE' as TypeEnum,
        PROTOTYPEINSTANCE: 'PROTOTYPE_INSTANCE' as TypeEnum
    };
}
