import { Injectable } from '@angular/core';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  private format: string = 'yyyy-MM-dd HH:mm:ss';
  private locale: string = 'de-DE';
  private timeZone: string = undefined;

  constructor() {}

  public setLocale(value: string) {
    this.locale = value;
  }

  public set timeZoneOffset(val: string) {
    this.timeZone = val;
  }

  public formatDateFromEpochMillis(millis: number): string {
    let momentInstance = moment(millis, 'x'); //see https://momentjs.com/docs/#/parsing/string-format/  Unix ms timestamp
    momentInstance.locale(this.locale);
    momentInstance.utcOffset(this.timeZone);
    return momentInstance.format('L LTS'); //https://momentjs.com/ Multiple Locale Support //
  }

  public formatDateFromEpochSeconds(seconds: number): string {
    let momentInstance = moment(seconds, 'X'); //see https://momentjs.com/docs/#/parsing/string-format/  Unix ms timestamp
    momentInstance.locale(this.locale);
    momentInstance.utcOffset(this.timeZone);
    return momentInstance.format('L LTS'); //https://momentjs.com/ Multiple Locale Support //
  }
}
