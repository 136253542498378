import { OrganisationResourceService } from 'src/app/dmssdk';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserGroupsDTO } from './dmssdk/model/currentUserGroupsDTO';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { LoginService, UserInfo } from './services/login/login.service';
import { UserGroupService } from './services/user-group/user-group.service';
import { UserRightsService } from './services/user-rights/user-rights.service';
import { DateFormatService } from './shared/services/date-format.service';
import { DateAdapter } from '@angular/material/core';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Moment } from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'dms-gui';
  configLabel = 'Config';
  configIcon = 'settings';
  public userInfo: UserInfo = {};
  isLogged: boolean;
  hasAdminRights: Observable<boolean>;

  public routingActive: boolean = false;
  public showGroupSelector: boolean = false;
  public showNoGroupError: boolean = false;

  public groupData: CurrentUserGroupsDTO = undefined;

  public logoBase64: string | undefined = undefined;
  public organisationDisplayName: string = '';
  public logoWidth = 10;

  constructor(
    private loginService: LoginService,
    private dateFormatService: DateFormatService,
    private rightsService: UserRightsService,
    private router: Router,
    private userGroupService: UserGroupService,
    private translate: TranslateService,
    private organisationService: OrganisationResourceService,
    private dateAdapter: NgxMatDateAdapter<Moment>,
  ) {
    this.translate.setDefaultLang(environment.translate.defaultLang);
  }

  ngOnInit(): void {
    this.loginService.getUserinfo().subscribe((userInfo) => {
      this.isLogged = true;
      this.userInfo = userInfo;
      this.translate.use(this.userInfo.locale);
      this.dateFormatService.setLocale(this.userInfo.locale);
      this.dateAdapter.setLocale(this.userInfo.locale);
    });
    this.hasAdminRights = this.rightsService.userHasAnyAdminRights();
    this.userGroupService.hasActiveGroup().subscribe(
      (result) => {
        this.routingActive = result;
        this.showGroupSelector = !result;
      },
      (error) => {
        if (!error) {
          this.routingActive = true;
        }
      }
    );
    this.router.events.subscribe(() => {
      this.setupNavigationIcon();
    });

    forkJoin([
      this.organisationService.getOrganisationLogoImageUsingGET(),
      this.organisationService.getOrganisationDisplayNameUsingGET(),
    ]).subscribe((result) => {
      const img = new Image();
      img.onload = (event) => {
        var image: any = event.currentTarget;
        this.logoWidth = Math.ceil(image.naturalWidth * 56 / image.naturalHeight);
      }
      img.src = 'data: image / jpeg; base64,' + result[0].contentBase64;
      this.logoBase64 = result[0].contentBase64;
      this.organisationDisplayName = result[1].value;

    });
  }

  navigateAdminOrUser() {
    if (this.router.url.startsWith('/user')) {
      this.router.navigate(['/admin']);
    }
    if (this.router.url.startsWith('/admin')) {
      this.router.navigate(['/user']);
    }
  }

  setupNavigationIcon() {
    if (this.router.url.startsWith('/admin')) {
      this.configLabel = 'main-panel';
      this.configIcon = 'cloud_queue';
    }
    if (this.router.url.startsWith('/user')) {
      this.configLabel = 'configuration';
      this.configIcon = 'settings';
    }
  }

  logout() {
    this.isLogged = false;
    this.loginService.logOut();
  }

  noGroupError() {
    this.routingActive = false;
    this.showGroupSelector = false;
    this.showNoGroupError = true;
  }

  groupSelected(data: CurrentUserGroupsDTO) {
    this.groupData = data;
    this.routingActive = true;
    this.showGroupSelector = false;
    this.showNoGroupError = false;
  }
}
