import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'evo-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  message: string = 'init';

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    if (this.organisationNotFound()) {
      this.message = this.loginService.notFoundMessage;
    } else {
      this.message = 'init';
    }
  }

  organisationNotFound(): boolean {
    return this.loginService.notFoundMessage != null;
  }
}
