import { UserGroupLinkResourceService, UsersGroupDTO } from 'src/app/dmssdk';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  addedSubgroup: UsersGroupDTO = {};
  private $addedSubgroup: BehaviorSubject<UsersGroupDTO> = new BehaviorSubject(
    this.addedSubgroup
  );
  addedSubgroupObservable = this.$addedSubgroup.asObservable();

  constructor(private linkService: UserGroupLinkResourceService) {}

  nextSubgroup(group: UsersGroupDTO) {
    this.$addedSubgroup.next(group);
  }

  public hasActiveGroup(): Observable<boolean> {
    return this.linkService.getActiveUserGroupLinkOfUserUsingGET().pipe(
      map((val) => {
        return true;
      }),
      catchError((err) => {
        if (err?.status === 404) {
          return of(false);
        } else {
          throw err;
        }
      })
    );
  }
}
