/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DocumentShareDTO { 
    authorName?: string;
    conditionId?: number;
    enabled?: boolean;
    id?: number;
    name?: string;
    organisationId?: number;
    ownerId?: number;
    ownerName?: string;
    readOnly?: boolean;
    receiverId?: number;
    receiverName?: string;
}
