/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DistinctValueDTO { 
    name?: string;
    type?: DistinctValueDTO.TypeEnum;
    value?: any;
    valuesCount?: number;
}
export namespace DistinctValueDTO {
    export type TypeEnum = 'STRING' | 'DATE' | 'INTEGER' | 'FLOAT' | 'BOOLEAN';
    export const TypeEnum = {
        STRING: 'STRING' as TypeEnum,
        DATE: 'DATE' as TypeEnum,
        INTEGER: 'INTEGER' as TypeEnum,
        FLOAT: 'FLOAT' as TypeEnum,
        BOOLEAN: 'BOOLEAN' as TypeEnum
    };
}
