/**
 * DocumentStorage API
 * DocumentStorage API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DeferredResultOfResponseEntityOfVoid } from '../model/deferredResultOfResponseEntityOfVoid';
import { DocumentBinaryDTO } from '../model/documentBinaryDTO';
import { DocumentPermissionsDTO } from '../model/documentPermissionsDTO';
import { DocumentPreviewBinaryDTO } from '../model/documentPreviewBinaryDTO';
import { DocumentSearchingResultDTO } from '../model/documentSearchingResultDTO';
import { DocumentTokenDTO } from '../model/documentTokenDTO';
import { DocumentVersionComparisonDTO } from '../model/documentVersionComparisonDTO';
import { MaterializedStampDTO } from '../model/materializedStampDTO';
import { RawDocumentDTO } from '../model/rawDocumentDTO';
import { SimpleDocumentDTO } from '../model/simpleDocumentDTO';
import { SseEmitter } from '../model/sseEmitter';
import { UserDefinedConditionsDTO } from '../model/userDefinedConditionsDTO';
import { ValueWrapperOfboolean } from '../model/valueWrapperOfboolean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * applyStamp
     * 
     * @param documentAccessToken document-access-token
     * @param stampId stampId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyStampUsingPATCH(documentAccessToken: string, stampId: number, observe?: 'body', reportProgress?: boolean): Observable<RawDocumentDTO>;
    public applyStampUsingPATCH(documentAccessToken: string, stampId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RawDocumentDTO>>;
    public applyStampUsingPATCH(documentAccessToken: string, stampId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RawDocumentDTO>>;
    public applyStampUsingPATCH(documentAccessToken: string, stampId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling applyStampUsingPATCH.');
        }

        if (stampId === null || stampId === undefined) {
            throw new Error('Required parameter stampId was null or undefined when calling applyStampUsingPATCH.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stampId !== undefined && stampId !== null) {
            queryParameters = queryParameters.set('stampId', <any>stampId);
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<RawDocumentDTO>(`${this.basePath}/api/documents/applied-stamps`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * compareChecksumOfOriginalFile
     * 
     * @param documentAccessToken document-access-token
     * @param originalFile original-file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public compareChecksumOfOriginalFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperOfboolean>;
    public compareChecksumOfOriginalFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperOfboolean>>;
    public compareChecksumOfOriginalFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperOfboolean>>;
    public compareChecksumOfOriginalFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling compareChecksumOfOriginalFileUsingPOST.');
        }

        if (originalFile === null || originalFile === undefined) {
            throw new Error('Required parameter originalFile was null or undefined when calling compareChecksumOfOriginalFileUsingPOST.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (originalFile !== undefined) {
            formParams = formParams.append('original-file', <any>originalFile) || formParams;
        }

        return this.httpClient.post<ValueWrapperOfboolean>(`${this.basePath}/api/documents/files/original/checksum`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * compareChecksumOfProcessedFile
     * 
     * @param documentAccessToken document-access-token
     * @param originalFile original-file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public compareChecksumOfProcessedFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe?: 'body', reportProgress?: boolean): Observable<ValueWrapperOfboolean>;
    public compareChecksumOfProcessedFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueWrapperOfboolean>>;
    public compareChecksumOfProcessedFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueWrapperOfboolean>>;
    public compareChecksumOfProcessedFileUsingPOST(documentAccessToken: string, originalFile: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling compareChecksumOfProcessedFileUsingPOST.');
        }

        if (originalFile === null || originalFile === undefined) {
            throw new Error('Required parameter originalFile was null or undefined when calling compareChecksumOfProcessedFileUsingPOST.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (originalFile !== undefined) {
            formParams = formParams.append('original-file', <any>originalFile) || formParams;
        }

        return this.httpClient.post<ValueWrapperOfboolean>(`${this.basePath}/api/documents/files/processed/checksum`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentDetails
     * 
     * @param documentAccessToken document-access-token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentDetailsUsingGET(documentAccessToken: string, observe?: 'body', reportProgress?: boolean): Observable<RawDocumentDTO>;
    public getDocumentDetailsUsingGET(documentAccessToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RawDocumentDTO>>;
    public getDocumentDetailsUsingGET(documentAccessToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RawDocumentDTO>>;
    public getDocumentDetailsUsingGET(documentAccessToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling getDocumentDetailsUsingGET.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RawDocumentDTO>(`${this.basePath}/api/documents/details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentOriginalFile
     * 
     * @param documentAccessToken document-access-token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentOriginalFileUsingGET(documentAccessToken: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentBinaryDTO>;
    public getDocumentOriginalFileUsingGET(documentAccessToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentBinaryDTO>>;
    public getDocumentOriginalFileUsingGET(documentAccessToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentBinaryDTO>>;
    public getDocumentOriginalFileUsingGET(documentAccessToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling getDocumentOriginalFileUsingGET.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentBinaryDTO>(`${this.basePath}/api/documents/files/original`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentPermissions
     * 
     * @param documentAccessToken document-access-token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentPermissionsUsingGET(documentAccessToken: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentPermissionsDTO>;
    public getDocumentPermissionsUsingGET(documentAccessToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentPermissionsDTO>>;
    public getDocumentPermissionsUsingGET(documentAccessToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentPermissionsDTO>>;
    public getDocumentPermissionsUsingGET(documentAccessToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling getDocumentPermissionsUsingGET.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentPermissionsDTO>(`${this.basePath}/api/documents/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentPreviewFirstPage
     * 
     * @param documentTokens documentTokens
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentPreviewFirstPageUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentPreviewBinaryDTO>>;
    public getDocumentPreviewFirstPageUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentPreviewBinaryDTO>>>;
    public getDocumentPreviewFirstPageUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentPreviewBinaryDTO>>>;
    public getDocumentPreviewFirstPageUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentTokens === null || documentTokens === undefined) {
            throw new Error('Required parameter documentTokens was null or undefined when calling getDocumentPreviewFirstPageUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<DocumentPreviewBinaryDTO>>(`${this.basePath}/api/documents/previews/first-page`,
            documentTokens,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentPreviewNextPages
     * 
     * @param documentTokens documentTokens
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentPreviewNextPagesUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentPreviewBinaryDTO>>;
    public getDocumentPreviewNextPagesUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentPreviewBinaryDTO>>>;
    public getDocumentPreviewNextPagesUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentPreviewBinaryDTO>>>;
    public getDocumentPreviewNextPagesUsingPOST(documentTokens: Array<DocumentTokenDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentTokens === null || documentTokens === undefined) {
            throw new Error('Required parameter documentTokens was null or undefined when calling getDocumentPreviewNextPagesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<DocumentPreviewBinaryDTO>>(`${this.basePath}/api/documents/previews/next-pages`,
            documentTokens,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentProcessedFile
     * 
     * @param documentAccessToken document-access-token
     * @param zoneId zone-id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentProcessedFileUsingGET(documentAccessToken: string, zoneId: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentBinaryDTO>;
    public getDocumentProcessedFileUsingGET(documentAccessToken: string, zoneId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentBinaryDTO>>;
    public getDocumentProcessedFileUsingGET(documentAccessToken: string, zoneId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentBinaryDTO>>;
    public getDocumentProcessedFileUsingGET(documentAccessToken: string, zoneId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling getDocumentProcessedFileUsingGET.');
        }

        if (zoneId === null || zoneId === undefined) {
            throw new Error('Required parameter zoneId was null or undefined when calling getDocumentProcessedFileUsingGET.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }
        if (zoneId !== undefined && zoneId !== null) {
            headers = headers.set('zone-id', String(zoneId));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentBinaryDTO>(`${this.basePath}/api/documents/files/processed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentUpdatesEmitter
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentUpdatesEmitterUsingGET(id: string, observe?: 'body', reportProgress?: boolean): Observable<SseEmitter>;
    public getDocumentUpdatesEmitterUsingGET(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SseEmitter>>;
    public getDocumentUpdatesEmitterUsingGET(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SseEmitter>>;
    public getDocumentUpdatesEmitterUsingGET(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentUpdatesEmitterUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SseEmitter>(`${this.basePath}/api/documents/${encodeURIComponent(String(id))}/details-emitter`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentVersions
     * 
     * @param documentAccessToken document-access-token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentVersionsUsingGET(documentAccessToken: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SimpleDocumentDTO>>;
    public getDocumentVersionsUsingGET(documentAccessToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SimpleDocumentDTO>>>;
    public getDocumentVersionsUsingGET(documentAccessToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SimpleDocumentDTO>>>;
    public getDocumentVersionsUsingGET(documentAccessToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling getDocumentVersionsUsingGET.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SimpleDocumentDTO>>(`${this.basePath}/api/documents/versions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDocumentsFromUserDefinedConditions
     * 
     * @param userDefinedConditions userDefinedConditions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentsFromUserDefinedConditionsUsingPOST(userDefinedConditions: UserDefinedConditionsDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentSearchingResultDTO>;
    public getDocumentsFromUserDefinedConditionsUsingPOST(userDefinedConditions: UserDefinedConditionsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSearchingResultDTO>>;
    public getDocumentsFromUserDefinedConditionsUsingPOST(userDefinedConditions: UserDefinedConditionsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSearchingResultDTO>>;
    public getDocumentsFromUserDefinedConditionsUsingPOST(userDefinedConditions: UserDefinedConditionsDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userDefinedConditions === null || userDefinedConditions === undefined) {
            throw new Error('Required parameter userDefinedConditions was null or undefined when calling getDocumentsFromUserDefinedConditionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DocumentSearchingResultDTO>(`${this.basePath}/api/documents/list`,
            userDefinedConditions,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getStampsImages
     * 
     * @param documentAccessToken document-access-token
     * @param zoneId zone-id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStampsImagesUsingGET(documentAccessToken: string, zoneId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MaterializedStampDTO>>;
    public getStampsImagesUsingGET(documentAccessToken: string, zoneId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MaterializedStampDTO>>>;
    public getStampsImagesUsingGET(documentAccessToken: string, zoneId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MaterializedStampDTO>>>;
    public getStampsImagesUsingGET(documentAccessToken: string, zoneId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling getStampsImagesUsingGET.');
        }

        if (zoneId === null || zoneId === undefined) {
            throw new Error('Required parameter zoneId was null or undefined when calling getStampsImagesUsingGET.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }
        if (zoneId !== undefined && zoneId !== null) {
            headers = headers.set('zone-id', String(zoneId));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MaterializedStampDTO>>(`${this.basePath}/api/documents/stamps/images`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getVersionsComparisonResult
     * 
     * @param newerDocAccessToken newer-doc-access-token
     * @param olderDocAccessToken older-doc-access-token
     * @param zoneId zone-id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVersionsComparisonResultUsingGET(newerDocAccessToken: string, olderDocAccessToken: string, zoneId: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentVersionComparisonDTO>;
    public getVersionsComparisonResultUsingGET(newerDocAccessToken: string, olderDocAccessToken: string, zoneId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentVersionComparisonDTO>>;
    public getVersionsComparisonResultUsingGET(newerDocAccessToken: string, olderDocAccessToken: string, zoneId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentVersionComparisonDTO>>;
    public getVersionsComparisonResultUsingGET(newerDocAccessToken: string, olderDocAccessToken: string, zoneId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (newerDocAccessToken === null || newerDocAccessToken === undefined) {
            throw new Error('Required parameter newerDocAccessToken was null or undefined when calling getVersionsComparisonResultUsingGET.');
        }

        if (olderDocAccessToken === null || olderDocAccessToken === undefined) {
            throw new Error('Required parameter olderDocAccessToken was null or undefined when calling getVersionsComparisonResultUsingGET.');
        }

        if (zoneId === null || zoneId === undefined) {
            throw new Error('Required parameter zoneId was null or undefined when calling getVersionsComparisonResultUsingGET.');
        }

        let headers = this.defaultHeaders;
        if (newerDocAccessToken !== undefined && newerDocAccessToken !== null) {
            headers = headers.set('newer-doc-access-token', String(newerDocAccessToken));
        }
        if (olderDocAccessToken !== undefined && olderDocAccessToken !== null) {
            headers = headers.set('older-doc-access-token', String(olderDocAccessToken));
        }
        if (zoneId !== undefined && zoneId !== null) {
            headers = headers.set('zone-id', String(zoneId));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentVersionComparisonDTO>(`${this.basePath}/api/documents/versions-comparison`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateDocument
     * 
     * @param document document
     * @param documentAccessToken document-access-token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocumentUsingPATCH(document: RawDocumentDTO, documentAccessToken: string, observe?: 'body', reportProgress?: boolean): Observable<RawDocumentDTO>;
    public updateDocumentUsingPATCH(document: RawDocumentDTO, documentAccessToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RawDocumentDTO>>;
    public updateDocumentUsingPATCH(document: RawDocumentDTO, documentAccessToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RawDocumentDTO>>;
    public updateDocumentUsingPATCH(document: RawDocumentDTO, documentAccessToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (document === null || document === undefined) {
            throw new Error('Required parameter document was null or undefined when calling updateDocumentUsingPATCH.');
        }

        if (documentAccessToken === null || documentAccessToken === undefined) {
            throw new Error('Required parameter documentAccessToken was null or undefined when calling updateDocumentUsingPATCH.');
        }

        let headers = this.defaultHeaders;
        if (documentAccessToken !== undefined && documentAccessToken !== null) {
            headers = headers.set('document-access-token', String(documentAccessToken));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<RawDocumentDTO>(`${this.basePath}/api/documents`,
            document,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload
     * 
     * @param documents documents
     * @param original original
     * @param processed processed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadUsingPOST(documents: any, original: Array<Blob>, processed: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<DeferredResultOfResponseEntityOfVoid>;
    public uploadUsingPOST(documents: any, original: Array<Blob>, processed: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeferredResultOfResponseEntityOfVoid>>;
    public uploadUsingPOST(documents: any, original: Array<Blob>, processed: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeferredResultOfResponseEntityOfVoid>>;
    public uploadUsingPOST(documents: any, original: Array<Blob>, processed: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documents === null || documents === undefined) {
            throw new Error('Required parameter documents was null or undefined when calling uploadUsingPOST.');
        }

        if (original === null || original === undefined) {
            throw new Error('Required parameter original was null or undefined when calling uploadUsingPOST.');
        }

        if (processed === null || processed === undefined) {
            throw new Error('Required parameter processed was null or undefined when calling uploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (documents !== undefined) {
            formParams = formParams.append('documents', <any>documents) || formParams;
        }
        if (original) {
            original.forEach((element) => {
                formParams = formParams.append('original', <any>element) || formParams;
            })
        }
        if (processed) {
            processed.forEach((element) => {
                formParams = formParams.append('processed', <any>element) || formParams;
            })
        }

        return this.httpClient.post<DeferredResultOfResponseEntityOfVoid>(`${this.basePath}/api/documents`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
