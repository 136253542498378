import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchConsistencyService {
  public searchConsistency: ReplaySubject<string> = new ReplaySubject<string>(
    1
  );

  public getObservable(): Observable<string> {
    return this.searchConsistency.asObservable();
  }

  clear() {
    this.searchConsistency.next('');
  }

  setWarning(message: string) {
    this.searchConsistency.next(message);
  }
}
